import { Skeleton, Tooltip } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

type Props = { files: File[] };
export const QueueList = ({ files }: Props) => {
    if (isEmpty(files)) {
        return null;
    }

    return (
        <>
            {files.map((file, i) => (
                <Tooltip
                    title={file.name}
                    key={`${file.name}/${i}`}
                    placement="top"
                >
                    <Skeleton width={48} variant="rounded" height={48} />
                </Tooltip>
            ))}
        </>
    );
};
