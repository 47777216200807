import client from './http';
import {
    Conversation,
    ScheduledMessage,
    UpdateScheduledMessageDto,
} from './types';

export function getScheduledConversations(
    timestamp?: number,
): Promise<Conversation[]> {
    const params = timestamp ? { timestamp } : undefined;
    return client()
        .get('/scheduled-messages/conversation', {
            params,
        })
        .then((res) => res.data);
}

export function getScheduledMessages(
    conversationId: string,
): Promise<ScheduledMessage[]> {
    return client()
        .get(`/scheduled-messages/conversation/${conversationId}`)
        .then((res) => res.data);
}

export function updateScheduledMessage(
    id: number,
    payload: UpdateScheduledMessageDto,
): Promise<ScheduledMessage> {
    return client()
        .put(`/scheduled-messages/${id}`, payload)
        .then((res) => res.data);
}

export function removeScheduledMessage(id: number): Promise<void> {
    return client()
        .delete(`/scheduled-messages/${id}`)
        .then((res) => res.data);
}
