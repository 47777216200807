import cc from 'classcat';
import { useRef, useState, CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styles from './ConversationsListFilter.module.scss';
import { Icon } from '../../icons/Icon';
import { ConversationsFilter, ConversationsFilterType } from '../../api/types';
import { useCohortsQuery } from '../../queries/cohorts';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ExtraFiltersPopup } from './ExtraFiltersPopup';
import { SelectedCohortsList } from './SelectedCohortsList';
import ButtonsGroup, { ButtonsGroupButton } from '../../elements/ButtonsGroup';

type PopperElem = HTMLElement | null;

interface Props {
    filter: ConversationsFilter;
    onChange: (filter: ConversationsFilter) => void;
}

export const ConversationsListFilter = (props: Props) => {
    const [showExtra, setShowExtra] = useState(false);

    const cohorts = useCohortsQuery();

    const btnWrapperRef = useRef<HTMLDivElement>(null);
    const filtersRef = useRef<HTMLDivElement>(null);
    useOnClickOutside([filtersRef, btnWrapperRef], () => setShowExtra(false));

    const [referenceElement, setReferenceElement] = useState<PopperElem>(null);
    const [popperElement, setPopperElement] = useState<PopperElem>(null);
    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        referenceElement,
        popperElement,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        },
    );

    return (
        <section className={styles['root']}>
            <div className={styles['root__controls']}>
                <ButtonsGroup className={styles['root__group']}>
                    <ButtonsGroupButton
                        active={
                            props.filter.type === ConversationsFilterType.Active
                        }
                        onClick={() =>
                            props.onChange({
                                type: ConversationsFilterType.Active,
                                cohortsList: [],
                            })
                        }
                    >
                        Active
                    </ButtonsGroupButton>
                    <ButtonsGroupButton
                        active={
                            props.filter.type ===
                            ConversationsFilterType.Archived
                        }
                        onClick={() =>
                            props.onChange({
                                type: ConversationsFilterType.Archived,
                                cohortsList: [],
                            })
                        }
                    >
                        Archived
                    </ButtonsGroupButton>
                </ButtonsGroup>

                <div ref={btnWrapperRef}>
                    <button
                        className={cc([
                            styles['root__extra-button'],
                            {
                                [styles['root__extra-button_active']]:
                                    props.filter.type !==
                                        ConversationsFilterType.Active &&
                                    props.filter.type !==
                                        ConversationsFilterType.Archived,
                            },
                        ])}
                        ref={setReferenceElement}
                        onClick={() => setShowExtra(!showExtra)}
                    >
                        <Icon name="filter" />
                    </button>
                </div>
            </div>

            {props.filter.cohortsList.length > 0 && (
                <SelectedCohortsList
                    filter={props.filter}
                    onChange={props.onChange}
                />
            )}

            {showExtra &&
                createPortal(
                    <div
                        className={styles['root__filters-container']}
                        ref={setPopperElement}
                        style={popperStyles.popper as CSSProperties}
                        {...popperAttributes.popper}
                    >
                        <div ref={filtersRef}>
                            <ExtraFiltersPopup
                                filter={props.filter}
                                cohorts={cohorts}
                                onChange={props.onChange}
                            />
                        </div>
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </section>
    );
};
