import { SxProps, Theme } from '@mui/material';

export const searchInputSx: SxProps<Theme> = {
    '&:before, &:after': {
        display: 'none',
    },
};

export const searchInput: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(1.5, 0),
    height: 20,
});

export const searchHeader: SxProps = {
    padding: 0,
    height: 36,

    '&:hover, &.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
};

export const checkbox: SxProps = { padding: 0, marginRight: 2 };

export const menuItemWithCheckbox: SxProps = {
    '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
};

export const icon: SxProps = { width: 20, height: 20 };
