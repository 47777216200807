import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { formatPhoneNumber } from '../../../../utils/phoneNumber';
import { CopyIcon } from '../../../../icons/common/CCopyIcon';
import { useState } from 'react';
import { Inbox } from '../../../../api/types';
import { SettingsRow } from '../../SettingsRow';

const DEFAULT_COPY_TEXT = 'Copy phone number';

export function PhoneNumberRow({ inbox }: { inbox: Inbox }) {
    const [copyText, setCopyText] = useState(DEFAULT_COPY_TEXT);
    return (
        <SettingsRow label="Phone number">
            <Stack spacing={2} direction="row" alignItems="center">
                <Typography variant="body3" color="custom.gray.super">
                    {formatPhoneNumber(inbox.phone)}
                </Typography>
                <Tooltip title={copyText} placement="top">
                    <IconButton
                        color="primary"
                        onClick={() => {
                            navigator.clipboard.writeText(inbox.phone);
                            setCopyText('Copied!');
                            setTimeout(
                                () => setCopyText(DEFAULT_COPY_TEXT),
                                1000,
                            );
                        }}
                    >
                        <CopyIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        </SettingsRow>
    );
}
