import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import styles from './TimeFrameStep.module.scss';
import { WorkflowCondition, WorkflowConditionTypes } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import Button from '../../../../elements/Buttons';
import Input from '../../../../elements/Inputs/Input';
import closeIcon from '../../../../assets/icons/icons-24/gray/close.svg';
import AdvancedSelect from '../../../../elements/AdvancedSelect';
import { HOURS, AMPM, TIMEZONES } from './utils';
import { Icon } from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';

interface TimeFrameStepProps {
    step: WorkflowCondition;
}

type AmPm = 'am' | 'pm';

interface TimeFrameFromTo {
    hours: number;
    dayPeriod: AmPm;
}

interface TimeFrame {
    from: TimeFrameFromTo;
    to: TimeFrameFromTo;
    timezone: string;
}

const getHoursTotal = (value: TimeFrameFromTo) => {
    const { hours, dayPeriod } = value;

    if (dayPeriod === 'am') {
        return hours === 12 ? hours - 12 : hours;
    } else {
        return hours < 12 ? hours + 12 : hours;
    }
};

const getTimeFrameInitValue = (
    conditional: WorkflowCondition,
): TimeFrame | undefined => {
    if (conditional.kind === WorkflowConditionTypes.TimeFrame) {
        const {
            start = 1,
            end = 1,
            timezone = '',
        } = conditional.timeFrameSettings || {};

        return {
            from: {
                hours: start > 11 ? start - 12 : start,
                dayPeriod: start > 11 ? 'pm' : 'am',
            },
            to: {
                hours: end > 11 ? end - 12 : end,
                dayPeriod: end > 11 ? 'pm' : 'am',
            },
            timezone,
        };
    }
};

export const TimeFrameStep = ({ step }: TimeFrameStepProps) => {
    const { dispatch } = useWorkflowContext();
    const [conditionalStep, setStep] = useState(
        cloneDeep(step as WorkflowCondition),
    );
    const [timeFrame, setTimeFrame] = useState<TimeFrame | undefined>({
        from: { hours: 1, dayPeriod: 'am' },
        to: { hours: 1, dayPeriod: 'am' },
        timezone: '',
    });

    useEffect(() => {
        const conditional = step as WorkflowCondition;
        setStep(cloneDeep(conditional));

        if (conditional.kind === WorkflowConditionTypes.TimeFrame) {
            setTimeFrame(getTimeFrameInitValue(conditional));
        }
    }, [step]);

    const onSaveHandler = () => {
        if (timeFrame) {
            dispatch({
                type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
                payload: {
                    ...conditionalStep,
                    kind: WorkflowConditionTypes.TimeFrame,
                    timeFrameSettings: {
                        start: getHoursTotal(timeFrame.from),
                        end: getHoursTotal(timeFrame.to),
                        timezone: timeFrame.timezone,
                    },
                },
            });
        }
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.kind]} />
                    <span>Time frame</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <img src={closeIcon} />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Branch name
                    </label>
                    <Input
                        placeholder="Enter name"
                        value={conditionalStep?.name || ''}
                        onChange={(name) =>
                            setStep({ ...conditionalStep, name })
                        }
                        uncontrolled
                        fullWidth
                    />
                </section>

                {timeFrame && (
                    <>
                        <section className={styles['aside__box']}>
                            <label className={styles['aside__box-label']}>
                                Time from
                            </label>
                            <div className={styles['aside__time-row']}>
                                <AdvancedSelect
                                    options={HOURS}
                                    value={timeFrame.from.hours.toString()}
                                    onChange={(value) => {
                                        setTimeFrame({
                                            ...timeFrame,
                                            from: {
                                                ...timeFrame.from,
                                                hours: Number.parseInt(
                                                    value,
                                                    10,
                                                ),
                                            },
                                        });
                                    }}
                                />
                                <AdvancedSelect
                                    options={AMPM}
                                    value={timeFrame.from.dayPeriod}
                                    onChange={(value) => {
                                        setTimeFrame({
                                            ...timeFrame,
                                            from: {
                                                ...timeFrame.from,
                                                dayPeriod: value as AmPm,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </section>

                        <section className={styles['aside__box']}>
                            <label className={styles['aside__box-label']}>
                                Time to
                            </label>
                            <div className={styles['aside__time-row']}>
                                <AdvancedSelect
                                    options={HOURS}
                                    value={timeFrame.to.hours.toString()}
                                    onChange={(value) => {
                                        setTimeFrame({
                                            ...timeFrame,
                                            to: {
                                                ...timeFrame.to,
                                                hours: Number.parseInt(
                                                    value,
                                                    10,
                                                ),
                                            },
                                        });
                                    }}
                                />
                                <AdvancedSelect
                                    options={AMPM}
                                    value={timeFrame.to.dayPeriod}
                                    onChange={(value) => {
                                        setTimeFrame({
                                            ...timeFrame,
                                            to: {
                                                ...timeFrame.to,
                                                dayPeriod: value as AmPm,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </section>

                        <section className={styles['aside__box']}>
                            <label className={styles['aside__box-label']}>
                                Timezone
                            </label>
                            <AdvancedSelect
                                options={TIMEZONES}
                                placeholder="Select timezone"
                                value={timeFrame.timezone}
                                onChange={(value) => {
                                    setTimeFrame({
                                        ...timeFrame,
                                        timezone: value,
                                    });
                                }}
                                searchable
                            />
                        </section>
                    </>
                )}
            </div>

            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};
