import cc from 'classcat';
import {
    WorkflowAction,
    WorkflowActionTypes,
    WorkflowCondition,
    WorkflowStep,
    WorkflowStepType,
} from '../types';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import { STEP_ICON_MAP, STEP_LABEL_MAP } from '../utils';
import styles from './WorkflowStep.module.scss';
import { Icon } from '../../../icons/Icon';

interface WorkflowActionStepProps {
    step: WorkflowAction;
}

export const WorkflowActionStep = ({ step }: WorkflowActionStepProps) => {
    const {
        state: { selectedStepId },
        dispatch,
    } = useWorkflowContext();

    const clssses = {
        [styles['workflow-step__assigned-inbox']]: Object.values(
            WorkflowActionTypes,
        ).includes(step.action),
        [styles['workflow-step__time']]: false,
    };

    return (
        <div
            className={cc([
                styles['workflow-step'],
                {
                    [styles['workflow-step_selected-action']]:
                        step.id === selectedStepId,
                },
            ])}
            onClick={(e) => {
                e.stopPropagation();

                dispatch({
                    type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                    payload: step.id,
                });
            }}
        >
            <div className={cc([styles['workflow-step__marker'], clssses])} />
            <p className={styles['workflow-step__title']}>
                <Icon
                    className={styles['workflow-step__icon']}
                    size="16px"
                    name={STEP_ICON_MAP[step.action]}
                />
                <span>{step.name || STEP_LABEL_MAP[step.action]}</span>
            </p>
            <p className={styles['workflow-step__description']}>
                You can edit this item
            </p>
        </div>
    );
};

interface WorkflowConditionStepProps {
    step: WorkflowCondition;
}

export const WorkflowConditionStep = ({ step }: WorkflowConditionStepProps) => {
    const {
        state: { selectedStepId },
        dispatch,
    } = useWorkflowContext();

    return (
        <div
            className={cc([
                styles['workflow-step'],
                {
                    [styles['workflow-step_selected-conditional']]:
                        step.id === selectedStepId,
                },
            ])}
            onClick={(e) => {
                e.stopPropagation();
                dispatch({
                    type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                    payload: step.id,
                });
            }}
        >
            <div
                className={cc([
                    styles['workflow-step__marker'],
                    styles['workflow-step__flow-control'],
                ])}
            />
            <p className={styles['workflow-step__title']}>
                <Icon
                    className={styles['workflow-step__icon']}
                    size="16px"
                    name={STEP_ICON_MAP[step.kind]}
                />
                <span>{step.name || STEP_LABEL_MAP[step.kind]}</span>
            </p>
            <p className={styles['workflow-step__description']}>
                You can edit this item
            </p>
        </div>
    );
};

export const renderStep = (step: WorkflowStep) => {
    if (step.type === WorkflowStepType.Action) {
        const actionStep = step as WorkflowAction;
        return <WorkflowActionStep step={actionStep} />;
    } else {
        const conditionStep = step as WorkflowCondition;
        return <WorkflowConditionStep step={conditionStep} />;
    }
};
