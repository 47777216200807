import styles from './CreateEditCohort.module.scss';
import { Icon } from '../../icons/Icon';
import Button from '../../elements/Buttons';
import { useModalContext } from '../modal/reducer';

type ModalHeaderProps = {
    text: string;
};
export const ModalHeader = (props: ModalHeaderProps) => {
    const { dispatch } = useModalContext();
    return (
        <div className={styles.header}>
            <h3>{props.text}</h3>
            <Button
                iconCenter={true}
                type="icon"
                icon={<Icon name="close" className={styles.icon} />}
                onClick={() => dispatch({ type: 'REMOVE', payload: {} })}
            />
        </div>
    );
};
