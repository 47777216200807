import { colors } from '../../../theme/palette';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Banner } from '../../../components/Banner/Banner';

export function TenDLCBanner() {
    return (
        <Banner color={colors.red[50]}>
            <Typography variant="body4" color="primary.dark">
                🚨 Your company is required to register for 10DLC.
                <Link
                    to="/settings/compliance"
                    component={RouterLink}
                    sx={{ ml: 1 }}
                >
                    Learn more{' ->'}
                </Link>
            </Typography>
        </Banner>
    );
}
