import { Column } from '../main/contacts-import/CsvImportWizard/utils';
import { client } from './http';
import {
    IntegrationSettings,
    IntegrationSource,
    TeamsPhoneResponse,
    TeamsUser,
    WebexPhoneResponse,
} from './types';

export const syncIntegration = ({ id }: { id: string }) =>
    client.get(`contacts-sync/sync/${id}`);

export const updateIntegration = ({
    id,
    isPublic,
    settings,
}: {
    id: string;
    isPublic?: boolean;
    settings?: IntegrationSettings;
}) =>
    client
        .put(`integrations/${id}`, { public: isPublic, settings })
        .then((res) => res.data);

export type ConnectIntegrationRequest = {
    source: string;
    code: string;
};

export type ConnectEmailIntegrationRequest = {
    email: string;
    source: IntegrationSource.Purview | IntegrationSource.Smarsh;
};

export const getIntegrationRemoteSchema = ({ id }: { id: string }) =>
    client
        .get<Column[]>(`integrations/${id}/remote-schema`)
        .then((res) => res.data);

export const getMicrosoftTeamsPhones = () =>
    client
        .get<TeamsPhoneResponse>('microsoft-teams/phones')
        .then((res) => res.data);

export const getMicrosoftTeamsPhoneV2 = () =>
    client
        .get<TeamsPhoneResponse>('v2/microsoft-teams/phones')
        .then((res) => res.data);

export const getWebexPhones = () =>
    client.get<WebexPhoneResponse>('webex/phones').then((res) => res.data);

export const requestMsTeamsAdminAccess = (email: string) =>
    client.post('microsoft-teams/request-admin-access', { email });

export const getMicrosoftTeamsUsers = () =>
    client.get<TeamsUser[]>('microsoft-teams/users').then((res) => res.data);
