import { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Checkbox,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { useInviteTeamMembers } from '../../../queries/user';
import { GenericDialog } from '../GenericDialog/GenericDialog';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';
import { checkbox, hideOnMobile, itemButton } from './styles';
import { Virtuoso } from 'react-virtuoso';
import { useAvailableUsersList } from './useAvailableUsersList';
import { RoleTypes } from '../../../api/types';
import { InviteIcon } from './InviteIcon';
import MultiItemsTextField from '../../MultiItemsTextField/MultiItemsTextField';
import { string } from 'yup';

interface InviteUserModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const ALL_ITEMS = 'All items selected';

export default function InviteMsTeamsUserModal({
    open,
    onClose,
    onSuccess,
}: InviteUserModalProps) {
    const { mutateAsync: invite, isSuccess } = useInviteTeamMembers();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [emails, setEmails] = useState<string[]>([]);
    const [error, setError] = useState<boolean>(false);
    const teamsUsers = useAvailableUsersList();
    const availableUsers = useMemo(
        () => [
            ...teamsUsers,
            ...emails
                .filter(
                    (email) => !teamsUsers.some((user) => user.email === email),
                )
                .map((email) => ({
                    email,
                    displayName: email,
                    microsoftTenantId: null,
                })),
        ],
        [teamsUsers, emails],
    );

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess, onSuccess]);

    const onConfirm = async () => {
        try {
            await invite({ emails, inboxIds: [], role: RoleTypes.Member });
            onClose();
        } catch (error) {
            setError(true);
        }
    };

    const filteredUsers = useMemo(() => {
        if (!searchTerm.length) {
            return availableUsers;
        }

        return availableUsers.filter(({ email }) =>
            email.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }, [availableUsers, searchTerm]);

    const searchItems = useMemo(
        () =>
            teamsUsers.length > 0 && emails.length === availableUsers.length
                ? [ALL_ITEMS]
                : emails,
        [emails, availableUsers, teamsUsers.length],
    );

    return (
        <GenericDialog
            open={open}
            onClose={onClose}
            IconComponent={InviteIcon}
            title="Invite Teammates"
            description="Select from the list below or type the email to add a colleague."
            onConfirm={onConfirm}
            confirmLabel="Grow your team"
            confirmButtonProps={{
                sx: {
                    width: { xs: 1, sm: 400 },
                },
            }}
            dismissButtonProps={{
                startIcon: <ArrowLeftIcon />,
                sx: {
                    minWidth: 84,
                },
            }}
            dismissLabel="Back"
            sx={{
                textAlign: 'center',
                width: { xs: 'auto', sm: 560 },
            }}
        >
            <Stack spacing={6}>
                <MultiItemsTextField
                    validator={(str) => string().email().isValidSync(str)}
                    error={error}
                    helperText={
                        error
                            ? 'Something went wrong, please try again later.'
                            : ''
                    }
                    items={searchItems}
                    onChange={(emails) => {
                        setError(false);
                        setEmails(emails);
                    }}
                    onType={setSearchTerm}
                    placeholder={
                        !searchTerm && !searchItems.length
                            ? 'Enter email addresses separated by comma'
                            : ''
                    }
                    label="Search or add a member"
                />
            </Stack>

            {!!teamsUsers.length && (
                <Virtuoso
                    data={filteredUsers}
                    style={{ height: 240 }}
                    components={{
                        Header: () => (
                            <Stack
                                direction="row"
                                spacing={6}
                                mb={2}
                                alignItems="center"
                            >
                                <Checkbox
                                    size="small"
                                    color="info"
                                    sx={checkbox}
                                    checked={emails.length > 0}
                                    indeterminate={
                                        emails.length > 0 &&
                                        emails.length < availableUsers.length
                                    }
                                    onClick={() => {
                                        setEmails((emails) =>
                                            emails.length
                                                ? []
                                                : availableUsers.map(
                                                      ({ email }) => email,
                                                  ),
                                        );
                                    }}
                                />
                                <Typography
                                    variant="body3"
                                    fontWeight={500}
                                    color="custom.gray.super"
                                >
                                    {emails.length > 0
                                        ? `${emails.length} selected`
                                        : 'Contacts from your MS Teams Account'}
                                </Typography>
                            </Stack>
                        ),
                    }}
                    itemContent={(_, user) => {
                        return (
                            <ListItem key={user.email} disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        setEmails((emails) =>
                                            emails.includes(user.email)
                                                ? emails.filter(
                                                      (email) =>
                                                          email !== user.email,
                                                  )
                                                : [...emails, user.email],
                                        );
                                    }}
                                    sx={{ ...itemButton, height: 48 }}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        flex={1}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={6}
                                            alignItems="center"
                                            flex={1}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    size="small"
                                                    color="info"
                                                    checked={emails.includes(
                                                        user.email,
                                                    )}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            user.email,
                                                    }}
                                                    sx={checkbox}
                                                />
                                            </ListItemIcon>
                                            <Box flex={1} sx={hideOnMobile}>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        variant: 'body3',
                                                        color: 'primary.dark',
                                                    }}
                                                >
                                                    {user.displayName ?? ''}
                                                </ListItemText>
                                            </Box>
                                            <Box flex={1}>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        variant: 'body3',
                                                        color: 'custom.gray.super',
                                                    }}
                                                >
                                                    {user.email}
                                                </ListItemText>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </ListItemButton>
                            </ListItem>
                        );
                    }}
                />
            )}
        </GenericDialog>
    );
}
