import { Message } from '../../../../api/types';
import { useMeQueryData } from '../../../../queries/user';
import logo from '../../../../elements/IconComponents/clerk-logo-header.svg';
import { useByPhone } from '../../../../components/Contacts/use-contact-by-phone';
import { isAssistant } from '../../../../components/ConversationRow/Assistant/check-is-until-review';

export const useMessageAvatarSrc = (message: Message) => {
    const me = useMeQueryData();
    const contact = useByPhone(message.sender);

    if (message.inbound) {
        return contact.data?.avatarURL;
    }
    if (isAssistant(message.senderType)) {
        return logo;
    }
    if (
        (message.userId === me?.id || message.user?.id === me?.id) &&
        me?.avatar?.url
    ) {
        return me?.avatar?.url;
    }
    return message.user?.avatar?.url;
};
