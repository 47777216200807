import client from './http';
import { Campaign } from './campaign.types';

export function renameCampaign(campaignId: number, name: string) {
    return client()
        .put<Campaign>(`/campaigns/rename/${campaignId}`, { name })
        .then(({ data }) => data);
}

export function removeCampaign(campaignId: number): Promise<void> {
    return client()
        .delete(`/campaigns/${campaignId}`)
        .then((res) => res.data);
}
