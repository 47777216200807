import { SxProps } from '@mui/material';

export const history: SxProps = {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    p: [2, 4, 6],
    maxHeight: 1,
    height: 1,
};
