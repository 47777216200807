import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const TIME_STEP_MINUTES = 5;

const formatDateTimeLabel = (
    dateTime: Dayjs,
    [closeLabel, farLabel]: string[],
) => {
    if (dateTime.isToday()) {
        return `${closeLabel} today at ${dateTime.format('hh:mm A')}`;
    }
    if (dateTime.isTomorrow()) {
        return `${closeLabel} tomorrow at ${dateTime.format('hh:mm A')}`;
    }
    return `${farLabel} ${dateTime.format('MMM D')} at ${dateTime.format(
        'hh:mm A',
    )}`;
};

export const getScheduleButtonLabel = (dateTime: Dayjs) =>
    formatDateTimeLabel(dateTime, ['Schedule for', 'Schedule for']);

export const getNearestAlignedCurrentDate = () => {
    const date = dayjs();
    const minutes = date.minute();
    const aligned =
        Math.floor((minutes + TIME_STEP_MINUTES) / TIME_STEP_MINUTES) *
        TIME_STEP_MINUTES;

    return date.minute(aligned).second(0).millisecond(0);
};
