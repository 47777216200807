import { UUID } from '../types/uuid';
import { client } from './http';
import {
    Classification,
    Conversation,
    ConversationsFilter,
    ConversationsFilterType,
    ConversationStatus,
} from './types';
import { ConversationPage } from '../main/campaign/v3/use-campaign-messages';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

export function getConversation(conversationId: string) {
    return client
        .get<Conversation>(`/conversations/${conversationId}`)
        .then(({ data }) => data);
}

type FindConversationDto = {
    inboxId: UUID;
    contactIds: (UUID | string)[];
};

export function findConversation(dto: FindConversationDto) {
    return client
        .post<Conversation>('/conversations/find', dto)
        .then((r) => r.data);
}

export function getOrCreateConversation(d: Partial<Conversation>) {
    return client
        .post(`/conversations/getOrCreate`, d)
        .then<Conversation>((r) => {
            return r.data;
        });
}

export function deleteConversation(id: string) {
    return client
        .delete(`/conversations/${id}`)
        .then<Conversation>((r) => r.data);
}

export const CONVERSATIONS_DEFAULT_FILTER: ConversationsFilter = {
    type: ConversationsFilterType.Active,
};

export function getConversationsPaged(
    inboxId: string,
    filter = CONVERSATIONS_DEFAULT_FILTER,
    timestamp?: number,
) {
    const params = new URLSearchParams();
    if (typeof timestamp === 'number' && timestamp > 0) {
        params.append('timestamp', timestamp.toString());
    }
    params.append('type', filter.type);

    if (filter.type === ConversationsFilterType.ByCohorts) {
        filter.cohortsList.forEach(({ id }) =>
            params.append('cohortsList', id.toString()),
        );
    }

    return client
        .get<Conversation[]>(`conversations/inbox/${inboxId}`, {
            params,
        })
        .then(({ data }) => data);
}

export function getCampaignConversations(
    campaignId: number,
    timestamp?: number,
): Promise<Conversation[]> {
    return client
        .get<Conversation[]>(`campaigns/${campaignId}/conversations`, {
            params: { timestamp },
        })
        .then((r) => r.data);
}

export function markConversationAsRead(conversationId: string): Promise<void> {
    return client
        .post(`/conversations/${conversationId}/read`)
        .then((r) => r.data);
}

export function markConversationAsUnRead(
    conversationId: string,
): Promise<void> {
    return client
        .post(`/conversations/${conversationId}/unread`)
        .then((r) => r.data);
}

export function assignUsersToConversation(
    conversationId: string,
    userIds: string[] = [],
): Promise<Conversation> {
    return client
        .post(`/conversations/${conversationId}/assign`, { userIds })
        .then((r) => r.data);
}
export function summarizeConversation(conversationId: string): Promise<string> {
    return client
        .get<string>(`/ai/conversation-summary/${conversationId}`)
        .then((r) => r.data);
}

export function getConversationClassifications(
    conversationId: string,
): Promise<Classification[]> {
    return client
        .get<Classification[]>(`/conversation-classifier/${conversationId}`)
        .then((r) => r.data);
}

export function classifyConversation(conversationId: string): Promise<void> {
    return client
        .post<void>(`/conversation-classifier/${conversationId}/classify`)
        .then((r) => r.data);
}

export type FindParams = {
    query?: string;
    status: ConversationStatus.Active | ConversationStatus.Archived | undefined;
    inboxId?: UUID;
    page?: number;
};

export const getConversations = (filter: FindParams, signal?: AbortSignal) =>
    client
        .get<ConversationPage>('/v2/conversations', {
            signal,
            params: omitBy({ ...filter }, isUndefined),
        })
        .then(({ data }) => data);

export const getConversationLogs = (conversationId: UUID) =>
    client
        .post(`/conversations/${conversationId}/export`, null, {
            responseType: 'blob',
        })
        .then(({ data }) => data);
