import cc from 'classcat';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    DropzoneInputProps,
    DropzoneOptions,
    useDropzone,
} from 'react-dropzone';
import Backdrop from '../../../elements/Backdrop';
import styles from './DragndropStep.module.scss';
import { File, TrashSimple, UploadSimple, X } from 'phosphor-react';
import Button from '../../../elements/Buttons';

const MAX_FILE_SIZE = 50242880; // 50 MB

interface DragndropStepProps {
    onDone: (file: File) => void;
}

export function DragndropStep({ onDone }: DragndropStepProps) {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length === 1) {
            const file = acceptedFiles[0];

            setFile(file);
            if (
                file.type !== 'text/csv' &&
                file.type !== 'application/vnd.ms-excel'
            ) {
                setError(true);
            }
        }
    }, []);
    const options: Partial<DropzoneOptions> = useMemo(
        () => ({
            onDrop,
            maxFiles: 1,
            multiple: false,
            maxSize: MAX_FILE_SIZE,
            noClick: true,
            noKeyboard: true,
        }),
        [onDrop],
    );

    const { isDragActive, getRootProps, getInputProps, open } = useDropzone(
        options as DropzoneOptions,
    );

    const inputProps = getInputProps() as Omit<DropzoneInputProps, 'refKey'>;
    return (
        <Backdrop>
            <div className={styles['drop-zone']}>
                <button
                    className={cc([
                        styles['drop-zone__close'],
                        styles['drop-zone__btn'],
                    ])}
                    onClick={() => navigate('/contacts/book')}
                >
                    <X size={24} weight="bold" />
                </button>

                <h6 className={styles['drop-zone__heading']}>
                    Import CSV file
                </h6>

                {!file && (
                    <div
                        {...getRootProps({
                            className: cc([
                                styles['drop-zone__area'],
                                {
                                    [styles['drop-zone__area_active']]:
                                        isDragActive,
                                },
                            ]),
                        })}
                    >
                        <input {...inputProps} />
                        <UploadSimple
                            className={styles['drop-zone__upload-icon']}
                            size={32}
                            weight="bold"
                        />
                        <p className={styles['drop-zone__info-message']}>
                            Drag and drop <i>.csv</i> file or &nbsp;
                            <button
                                className={cc([
                                    styles['drop-zone__select-files'],
                                    styles['drop-zone__btn'],
                                ])}
                                onClick={open}
                            >
                                select here
                            </button>
                        </p>
                    </div>
                )}
                {file && (
                    <div className={styles['drop-zone__files']}>
                        <ul className={styles['drop-zone__files-list']}>
                            <li className={styles['drop-zone__file']}>
                                <File size={32} weight="bold" />
                                <div
                                    className={
                                        styles['drop-zone__file-name-container']
                                    }
                                >
                                    <p
                                        className={
                                            styles['drop-zone__file-name']
                                        }
                                    >
                                        {file.name}
                                    </p>
                                    {error && (
                                        <p
                                            className={
                                                styles['drop-zone__error']
                                            }
                                        >
                                            File-type should be a CSV and size
                                            up to 5MB
                                        </p>
                                    )}
                                </div>

                                <button
                                    className={cc([
                                        styles['drop-zone__select-files'],
                                        styles['drop-zone__btn'],
                                    ])}
                                    onClick={() => {
                                        setFile(null);
                                        setError(false);
                                    }}
                                >
                                    <TrashSimple size={24} weight="bold" />
                                </button>
                            </li>
                        </ul>
                        <Button disabled={error} onClick={() => onDone(file)}>
                            Continue
                        </Button>
                    </div>
                )}
            </div>
        </Backdrop>
    );
}
