import {
    useCallback,
    useMemo,
    useRef,
    useState,
    ForwardedRef,
    forwardRef,
    Ref,
} from 'react';
import { createPortal } from 'react-dom';
import cc from 'classcat';
import { usePopper } from 'react-popper';
import styles from './AdvancedSelect.module.scss';
import { DropdownContainer, DropdownItem, DropdownList } from '../Dropdown';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Search from '../Inputs/Search';
import {
    defaultMultiselectRenderrer,
    defaultSimpleRenderrer,
    defaultValueRenderrer,
} from './renderrers';
import { OptionBase, OptionId } from './types';
import { Typography } from '@mui/material';

interface AdvancedSelectProps<T extends OptionBase> {
    options: T[];
    placeholder?: string;
    value?: OptionId | OptionId[];
    open?: boolean;
    keepOpen?: boolean;
    multiselect?: boolean;
    searchable?: boolean;
    small?: boolean;
    className?: string;
    optionsListClassName?: string;
    disabled?: boolean;
    error?: string | boolean;
    footer?: JSX.Element;
    onChange: (option: OptionId) => void;
    renderValue?: (
        options: T[],
        value?: T['id'],
        placeholder?: string,
    ) => JSX.Element;
    renderOption?: (
        option: T,
        selected: boolean,
        collapse: () => void,
    ) => JSX.Element;
}

// @deprecated - use EnhancedSelect instead
const AdvancedSelectBase = <T extends OptionBase>(
    props: AdvancedSelectProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        options,
        placeholder,
        value,
        open,
        keepOpen,
        multiselect,
        searchable,
        small,
        className,
        optionsListClassName,
        disabled,
        error,
        footer,
        onChange,
        renderValue = defaultValueRenderrer,
        renderOption = props.multiselect
            ? defaultMultiselectRenderrer
            : defaultSimpleRenderrer,
    } = props;

    /* State */
    const [isOpen, setIsOpen] = useState<boolean | undefined>(open);
    const [searchTerm, setSearchTerm] = useState('');

    /* Refs */
    const containerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    /* Hooks */
    const collapse = useCallback(() => {
        setIsOpen(false);
        setSearchTerm('');
    }, []);

    useOnClickOutside([containerRef, innerRef], collapse);

    const [refEl, setRefEl] = useState<HTMLDivElement | null>(null);
    const [popEl, setPopEl] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes } = usePopper(refEl, popEl, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    const filteredOptions = useMemo(
        () =>
            searchTerm
                ? options.filter(({ label }) =>
                      label.toLowerCase().includes(searchTerm.toLowerCase()),
                  )
                : options,
        [options, searchTerm],
    );

    return (
        <div
            className={cc([styles['advanced-select'], className])}
            onClick={() => setIsOpen(!isOpen)}
            ref={containerRef}
        >
            <div ref={ref}>
                <Typography
                    variant="body3"
                    className={cc([
                        styles['advanced-select__value'],
                        { [styles['advanced-select__value_small']]: small },
                        {
                            [styles['advanced-select__value_disabled']]:
                                disabled,
                            [styles['advanced-select__value_error']]: error,
                        },
                    ])}
                    ref={setRefEl}
                >
                    {renderValue(options, value, placeholder)}
                    <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles['advanced-select__value-svg']}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.78409 0.325883L7.66969 3.21148C8.37159 3.91338 7.88137 5.11664 6.88979 5.11664H1.1186C0.127024 5.11664 -0.374334 3.91338 0.327568 3.21148L3.21316 0.325883C3.64768 -0.108628 4.34958 -0.108628 4.78409 0.325883ZM3.21591 13.4334L0.330314 10.5478C-0.371588 9.84588 0.118629 8.64262 1.11021 8.64262H6.8814C7.87298 8.64262 8.37433 9.84588 7.67243 10.5478L4.78684 13.4334C4.35232 13.8679 3.65042 13.8679 3.21591 13.4334Z"
                            fill={disabled ? '#969696' : '#8689A4'}
                        />
                    </svg>
                </Typography>
            </div>

            {isOpen &&
                !disabled &&
                createPortal(
                    <div
                        ref={setPopEl}
                        style={{
                            ...popperStyles.popper,
                            zIndex: 5500,
                            width: containerRef.current?.getBoundingClientRect()
                                .width,
                        }}
                        {...attributes.popper}
                    >
                        <div ref={innerRef}>
                            <DropdownContainer
                                className={
                                    styles[
                                        'advanced-select__dropdown-container'
                                    ]
                                }
                            >
                                {searchable && (
                                    <div
                                        className={
                                            styles[
                                                'advanced-select__search-wrapper'
                                            ]
                                        }
                                    >
                                        <Search
                                            className={
                                                styles[
                                                    'advanced-select__search-input'
                                                ]
                                            }
                                            onClick={(
                                                e: React.MouseEvent<HTMLButtonElement>,
                                            ) => e.stopPropagation()}
                                            onChange={setSearchTerm}
                                            fullWidth
                                        />
                                    </div>
                                )}
                                <DropdownList
                                    className={cc([
                                        styles[
                                            'advanced-select__dropdown-list'
                                        ],
                                        optionsListClassName,
                                    ])}
                                >
                                    {filteredOptions.map((option) => (
                                        <DropdownItem
                                            key={option.id}
                                            disabled={option.disabled}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onChange(option.id);
                                                if (
                                                    !(keepOpen || multiselect)
                                                ) {
                                                    collapse();
                                                }
                                            }}
                                        >
                                            {renderOption?.(
                                                option,
                                                multiselect
                                                    ? (value as string[]).some(
                                                          (v) =>
                                                              v === option.id,
                                                      )
                                                    : option.id ===
                                                          (value as string),
                                                collapse,
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownList>

                                {footer}
                            </DropdownContainer>
                        </div>
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
};

// @deprecated - use EnhancedSelect instead
export const AdvancedSelect = forwardRef(AdvancedSelectBase) as <
    T extends OptionBase,
>(
    props: AdvancedSelectProps<T> & { ref?: Ref<HTMLElement> },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => JSX.Element;
