import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../../../types/clerk-icon.props';

export const UserIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0001 3.125C8.50431 3.125 7.29175 4.33756 7.29175 5.83333C7.29175 7.3291 8.50431 8.54167 10.0001 8.54167C11.4959 8.54167 12.7084 7.3291 12.7084 5.83333C12.7084 4.33756 11.4959 3.125 10.0001 3.125ZM6.04175 5.83333C6.04175 3.64721 7.81395 1.875 10.0001 1.875C12.1862 1.875 13.9584 3.64721 13.9584 5.83333C13.9584 8.01946 12.1862 9.79167 10.0001 9.79167C7.81395 9.79167 6.04175 8.01946 6.04175 5.83333ZM10.0001 12.2917C7.1236 12.2917 4.79175 14.6235 4.79175 17.5C4.79175 17.8452 4.51193 18.125 4.16675 18.125C3.82157 18.125 3.54175 17.8452 3.54175 17.5C3.54175 13.9332 6.43324 11.0417 10.0001 11.0417C13.5669 11.0417 16.4584 13.9332 16.4584 17.5C16.4584 17.8452 16.1786 18.125 15.8334 18.125C15.4882 18.125 15.2084 17.8452 15.2084 17.5C15.2084 14.6235 12.8766 12.2917 10.0001 12.2917Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
