import { SxProps } from '@mui/material';

export const paymentElement: SxProps = {
    marginBottom: '24px',
    position: 'relative',
    zIndex: 0,
};

export const confirm: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    m: '0 0 16px 0',
    p: 0,
    width: '280px',
    height: '40px',
    backgroundColor: '#3f5df4',
    border: 'none',
    borderRadius: 1,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#ffffff',
    cursor: 'pointer',

    '& svg': {
        width: '24px',
        height: '24px',
    },
};
