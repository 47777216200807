import { SxProps, Theme } from '@mui/material';

const value: SxProps<Theme> = {
    flexGrow: 1,
    maxWidth: 1,
    paddingY: 1.5,
    cursor: 'pointer',

    '&:hover .MuiTypography-root': {
        color: 'info.main',
    },
};

export const limitedValue: SxProps<Theme> = {
    ...value,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
};

export const readOnly: SxProps<Theme> = {
    ...value,
    cursor: 'default',

    '&:hover .MuiTypography-root': {
        color: 'inherit',
    },
};

export const emptyValue: SxProps<Theme> = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 2,
    color: 'custom.gray.super',

    '&:hover .MuiTypography-root': {
        color: 'primary.dark',
    },
};
