import cc from 'classcat';
import styles from './ButtonsGroup.module.scss';

interface ButtonsGroupProps {
    className?: string;
    children: React.ReactNode;
}

export const ButtonsGroup = (props: ButtonsGroupProps) => (
    <div className={cc([styles['root'], props.className])}>
        {props.children}
    </div>
);
