import { EnhancedSelect } from '../../../components/EnhancedSelect/EnhancedSelect';
import { FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { getTimeList } from '../../../components/workflows/WorkflowStepConfigurator/ScheduleStep/utils';

const timeList = getTimeList();

type Props = {
    to: string;
    from: string;
    onChangeFrom: (value: string) => void;
    onChangeTo: (value: string) => void;
    disabled?: boolean;
    error?: string;
};

export function DayForm({
    disabled = false,
    onChangeFrom,
    onChangeTo,
    to,
    from,
    error = '',
}: Props) {
    return (
        <>
            <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                sx={{
                    width: {
                        xs: 1,
                        md: 320,
                    },
                }}
            >
                <FormControl error={!!error} fullWidth>
                    <EnhancedSelect
                        value={from}
                        onChange={onChangeFrom}
                        size="small"
                        options={timeList}
                        disabled={disabled}
                    />
                </FormControl>
                <Typography variant="body4" color="custom.gray.disabledText">
                    To
                </Typography>
                <FormControl error={!!error} fullWidth>
                    <EnhancedSelect
                        value={to}
                        onChange={onChangeTo}
                        size="small"
                        options={timeList}
                        disabled={disabled}
                    />
                </FormControl>
            </Stack>
            <FormHelperText error={!!error}>{error}</FormHelperText>
        </>
    );
}
