import { useTeams } from 'msteams-react-base-component';
import Loading from '../../components/Loading/Loading';
import useUnmutedSortedInboxes from '../../hooks/useUnmutedSortedInboxes';
import { useMeQueryData } from '../../queries/user';
import { Redirect } from '../../utils/redirect';
import { ClerkPermission, InboxStatus } from '../../api/types';
import { usePermission } from '../WithPermission/WithPermission';
import { useMemo } from 'react';

export const InboxLoader = () => {
    const currentUser = useMeQueryData();
    const { unmutedSortedInboxes, isPending } = useUnmutedSortedInboxes();

    const [{ inTeams }] = useTeams();

    const canSetupInboxes = usePermission(ClerkPermission.AddInbox);
    const canViewMessages = usePermission(ClerkPermission.ViewInboxMessages);

    const activeInboxes = useMemo(() => {
        return unmutedSortedInboxes.filter(
            (inbox) => inbox.status === InboxStatus.ACTIVE,
        );
    }, [unmutedSortedInboxes]);

    if (isPending) {
        return <Loading />;
    }

    if (currentUser) {
        if (activeInboxes.length === 0 && canSetupInboxes) {
            if (inTeams) {
                return <Redirect to="/setup-inbox/ms-teams/auth" />;
            }
            return <Redirect to="/setup-inbox" />;
        }
        if (activeInboxes.length === 0 && !canSetupInboxes) {
            return <Redirect to="/contacts/book" />;
        }

        if (activeInboxes.length > 0 && canViewMessages) {
            return <Redirect to={`/inbox/${activeInboxes[0].id}`} />;
        }

        return <Redirect to="/contacts/book" />;
    }

    return <Redirect to="/login" />;
};
