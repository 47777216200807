import cloneDeep from 'lodash/cloneDeep';
import {
    Workflow,
    WorkflowSchedule,
    WorkflowStep,
    WorkflowTrigger,
} from '../types';
import { deleteNode, insertStep, updateStep } from '../utils';

// ACTIONS

export enum WorkflowReducerActionTypes {
    SET_WORKFLOW = 'SET_WORKFLOW',
    SET_SHOW_DROP_SPOTS = 'SET_SHOW_DROP_SPOTS',
    SET_TRIGGER_STEP = 'SET_TRIGGER_STEP',
    SET_SELECTED_STEP = 'SET_SELECTED_STEP',
    INSERT_WORKFLOW_STEP = 'INSERT_WORKFLOW_STEP',
    UPDATE_WORKFLOW_STEP = 'UPDATE_WORKFLOW_STEP',
    UPDATE_WORKFLOW_TRIGGER = 'UPDATE_WORKFLOW_TRIGGER',
    UPDATE_WORKFLOW_TRIGGER_SETTINGS = 'UPDATE_WORKFLOW_TRIGGER_SETTINGS',
    UPDATE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULES = 'UPDATE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULES',
    DELETE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULE = 'DELETE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULE',
    DELETE_WORKFLOW_STEP = 'DELETE_WORKFLOW_STEP',
    TOGGLE_SELECTED_WORKFLOW = 'TOGGLE_SELECTED_WORKFLOW',
    SELECT_MULTIPLE_WORKFLOWS = 'SELECT_MULTIPLE_WORKFLOWS',
    RESET = 'RESET',
}

interface InsertWorkflowStepPayload {
    step: WorkflowStep;
    parentId: string;
    branch?: 'left' | 'right';
}

export interface WorkflowReducerAction {
    type: WorkflowReducerActionTypes;
    payload?:
        | WorkflowSchedule
        | TriggerSettingsPayload
        | WorkflowTrigger
        | Workflow
        | WorkflowStep
        | InsertWorkflowStepPayload
        | number
        | number[]
        | boolean
        | string
        | null;
}

// STATE

type ScheduleTriggerSettings = {
    timezone: string;
    schedules: WorkflowSchedule[];
};

type TriggerSettingsPayload = Partial<ScheduleTriggerSettings>;

export interface WorkflowReducerState {
    workflow: Workflow | null;
    showDropSpots: boolean;
    selectedStepId: string | null;
    selectedWorkflows: number[];
    triggerSettings?: ScheduleTriggerSettings;
}

export const initialState: WorkflowReducerState = {
    workflow: null,
    showDropSpots: false,
    selectedStepId: null,
    selectedWorkflows: [],
};

// REDUCER

export const reducer = (
    state: WorkflowReducerState,
    { type, payload }: WorkflowReducerAction,
) => {
    switch (type) {
        case WorkflowReducerActionTypes.SET_WORKFLOW:
            return {
                ...initialState,
                workflow: payload as Workflow | null,
            };

        case WorkflowReducerActionTypes.SET_SHOW_DROP_SPOTS:
            return {
                ...state,
                showDropSpots: payload as boolean,
            };

        case WorkflowReducerActionTypes.SET_TRIGGER_STEP:
            return {
                ...state,
                selectedStepId: 'TRIGGER',
            };

        case WorkflowReducerActionTypes.SET_SELECTED_STEP:
            return {
                ...state,
                selectedStepId:
                    (payload as string | null) !== state.selectedStepId
                        ? (payload as string | null)
                        : null,
            };

        case WorkflowReducerActionTypes.INSERT_WORKFLOW_STEP: {
            const target = payload as InsertWorkflowStepPayload;

            if (state.workflow) {
                return {
                    ...state,
                    workflow: insertStep(
                        state.workflow,
                        target.step,
                        target.parentId,
                        target.branch,
                    ),
                };
            } else {
                return state;
            }
        }

        case WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP: {
            const step = payload as WorkflowStep;

            if (state.workflow) {
                const updatedWorkflow = updateStep(state.workflow, step);

                return {
                    ...state,
                    selectedStepId: null,
                    workflow: updatedWorkflow,
                };
            } else {
                return state;
            }
        }

        case WorkflowReducerActionTypes.DELETE_WORKFLOW_STEP: {
            if (state.workflow && state.selectedStepId) {
                return {
                    ...state,
                    selectedStepId: null,
                    workflow: deleteNode(state.workflow, state.selectedStepId),
                };
            } else {
                return state;
            }
        }

        case WorkflowReducerActionTypes.TOGGLE_SELECTED_WORKFLOW: {
            const workflowId = payload as number;

            if (state.selectedWorkflows.includes(workflowId)) {
                return {
                    ...state,
                    selectedWorkflows: state.selectedWorkflows.filter(
                        (id) => id !== workflowId,
                    ),
                };
            } else {
                return {
                    ...state,
                    selectedWorkflows:
                        state.selectedWorkflows.concat(workflowId),
                };
            }
        }

        case WorkflowReducerActionTypes.SELECT_MULTIPLE_WORKFLOWS: {
            const workflowIds = payload as number[];

            return {
                ...state,
                selectedWorkflows: workflowIds,
            };
        }

        case WorkflowReducerActionTypes.UPDATE_WORKFLOW_TRIGGER: {
            return {
                ...state,
                workflow: {
                    ...state.workflow,
                    trigger: payload,
                },
            };
        }

        case WorkflowReducerActionTypes.UPDATE_WORKFLOW_TRIGGER_SETTINGS: {
            return {
                ...state,
                triggerSettings: {
                    ...state.triggerSettings,
                    ...(payload as TriggerSettingsPayload),
                },
            };
        }

        case WorkflowReducerActionTypes.UPDATE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULES: {
            const schedule = payload as WorkflowSchedule;
            const schedules = cloneDeep(state?.workflow?.schedules) || [];
            const index = schedules.findIndex((s) => s.id === schedule.id);
            if (index > -1) {
                schedules[index] = schedule;
            } else {
                schedules.push(schedule);
            }
            return {
                ...state,
                workflow: {
                    ...state.workflow,
                    schedules,
                },
            };
        }

        case WorkflowReducerActionTypes.DELETE_WORKFLOW_TRIGGER_SETTINGS_SCHEDULE: {
            const schedule = payload as WorkflowSchedule;
            const schedules = cloneDeep(state?.workflow?.schedules) || [];
            const index = schedules.findIndex((s) => s.id === schedule.id);

            if (index > -1) {
                schedules.splice(index, 1);
            }
            return {
                ...state,
                workflow: {
                    ...state.workflow,
                    schedules,
                },
            };
        }

        case WorkflowReducerActionTypes.RESET:
            return initialState;

        default:
            return state;
    }
};
