import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../../../types/clerk-icon.props';

export const LogsIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.69647 1.95365C9.88524 1.84878 10.1148 1.84878 10.3035 1.95365L17.8035 6.12032C18.0019 6.23055 18.125 6.43969 18.125 6.66667C18.125 6.89365 18.0019 7.10278 17.8035 7.21302L15.787 8.33333L17.8035 9.45365C18.0019 9.56388 18.125 9.77302 18.125 10C18.125 10.227 18.0019 10.4361 17.8035 10.5463L15.787 11.6667L17.8035 12.787C18.0019 12.8972 18.125 13.1064 18.125 13.3333C18.125 13.5603 18.0019 13.7695 17.8035 13.8797L10.3035 18.0463C10.1148 18.1512 9.88524 18.1512 9.69647 18.0463L2.19647 13.8797C1.99806 13.7695 1.875 13.5603 1.875 13.3333C1.875 13.1064 1.99806 12.8972 2.19647 12.787L4.21305 11.6667L2.19647 10.5463C1.99806 10.4361 1.875 10.227 1.875 10C1.875 9.77302 1.99806 9.56388 2.19647 9.45365L4.21305 8.33333L2.19647 7.21302C1.99806 7.10278 1.875 6.89365 1.875 6.66667C1.875 6.43969 1.99806 6.23055 2.19647 6.12032L9.69647 1.95365ZM5.5 9.04831L3.78695 10L10 13.4517L16.213 10L14.5 9.04831L10.3035 11.3797C10.1148 11.4846 9.88524 11.4846 9.69647 11.3797L5.5 9.04831ZM5.5 12.3816L3.78695 13.3333L10 16.785L16.213 13.3333L14.5 12.3816L10.3035 14.713C10.1148 14.8179 9.88524 14.8179 9.69647 14.713L5.5 12.3816ZM3.78695 6.66667L10 10.1184L16.213 6.66667L10 3.21497L3.78695 6.66667Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
