import { createContext, useContext, useEffect, useReducer } from 'react';
import { useBetweenMdLg } from '../hooks/useBetweenMdLg';
import { useIsDownMd } from '../hooks/useIsDownMd';

type SidebarState = {
    isCollapsed: boolean;
    isOpened: boolean;
    isUserToggled: boolean;
};

type SidebarContextType = {
    state: SidebarState;
    dispatch: (action: SidebarActions) => void;
};

type SidebarActions =
    | {
          type: 'COLLAPSE';
      }
    | {
          type: 'EXPAND';
      }
    | {
          type: 'CLOSE';
      }
    | {
          type: 'OPEN';
      }
    | {
          type: 'TOGGLE_COLLAPSE';
      }
    | {
          type: 'TOGGLE_OPEN';
      };

const SidebarContext = createContext<SidebarContextType>({
    state: {
        isUserToggled: false,
        isCollapsed: false,
        isOpened: false,
    },
    dispatch: () => {},
});

const reducer = (state: SidebarState, action: SidebarActions) => {
    switch (action.type) {
        case 'COLLAPSE':
            return { ...state, isCollapsed: true };
        case 'EXPAND':
            return { ...state, isCollapsed: false };
        case 'CLOSE':
            return { ...state, isOpened: false };
        case 'OPEN':
            return { ...state, isOpened: true };
        case 'TOGGLE_COLLAPSE':
            return {
                ...state,
                isCollapsed: !state.isCollapsed,
                isUserToggled: true,
            };
        case 'TOGGLE_OPEN':
            return { ...state, isOpened: !state.isOpened };
        default:
            return state;
    }
};

export const SidebarProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const isNarrowScreen = useBetweenMdLg();
    const isMobile = useIsDownMd();
    const [state, dispatch] = useReducer(reducer, {
        isOpened: false,
        isUserToggled: false,
        isCollapsed: isNarrowScreen,
    });

    useEffect(() => {
        if (!state.isUserToggled) {
            dispatch({ type: isNarrowScreen ? 'COLLAPSE' : 'EXPAND' });
        }
    }, [isNarrowScreen, state.isUserToggled]);

    useEffect(() => {
        dispatch({ type: isMobile ? 'CLOSE' : 'OPEN' });
    }, [isMobile]);

    return (
        <SidebarContext.Provider value={{ state, dispatch }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const { state, dispatch } = useContext(SidebarContext);

    return {
        isCollapsed: state.isCollapsed,
        isOpened: state.isOpened,
        toggleCollapse: () => dispatch({ type: 'TOGGLE_COLLAPSE' }),
        toggleOpen: () => dispatch({ type: 'TOGGLE_OPEN' }),
    };
};
