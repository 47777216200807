import { Inbox } from '../../../api/types';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useArchiveAllForInbox } from '../../../queries/inboxes';
import { Header } from '../Header';
import { ToastActions, useToastContext } from '../../toast/reducer';

export function ArchiveInbox({
    inbox,
    onClose,
}: {
    inbox: Inbox;
    onClose: () => void;
}) {
    const { mutateAsync: archiveAll, isPending } = useArchiveAllForInbox();
    const { dispatch: toastDispatch } = useToastContext();

    return (
        <>
            <Header title="About Phone" description="General information" />
            <Divider />
            <Stack spacing={6} alignItems="center" flex={1} pt={10}>
                <Stack spacing={4} alignItems="center" maxWidth={420}>
                    <Typography fontSize={40} lineHeight="40px">
                        🗃️
                    </Typography>
                    <Stack spacing={2} alignItems="center" textAlign="center">
                        <Typography variant="h2" color="primary.dark">
                            Archive phone
                        </Typography>
                        <Typography variant="body3" color="primary.dark">
                            Are you sure you want to archive all the
                            conversations?
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    useFlexGap
                    direction={{
                        xs: 'column',
                        md: 'row',
                    }}
                    spacing={4}
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: 148 }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        size="large"
                        disabled={isPending}
                        sx={{ width: 148 }}
                        onClick={async () => {
                            try {
                                await archiveAll({ inboxId: inbox.id });

                                toastDispatch({
                                    type: ToastActions.ADD,
                                    payload: {
                                        severity: 'success',
                                        title: 'Phone settings',
                                        description:
                                            'All conversations are archived',
                                    },
                                });
                            } catch (_) {
                                toastDispatch({
                                    type: ToastActions.ADD,
                                    payload: {
                                        severity: 'error',
                                        title: 'Phone settings',
                                        description:
                                            'Failed to archive conversations',
                                    },
                                });
                            }
                            onClose();
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            </Stack>
        </>
    );
}
