import { SxProps, Theme } from '@mui/material';
import { getSquare } from '../../../theme/style.helpers';

export const ITEM_HEIGHT = 36;
export const DIVIDER_HEIGHT = 5;

export const alightLeftButton: SxProps = {
    justifyContent: 'flex-start',
};

export const emptyDivider: SxProps = { height: '1px' };

export const divider: SxProps = { my: 1 };

export const list: SxProps = { px: 1, py: 0 };

export const selectedItemAvatarWrapper: SxProps = { position: 'relative' };

export const selectedItemAvatarCheckmark: SxProps<Theme> = {
    ...getSquare(12),
    position: 'absolute',
    bottom: -1,
    right: -4,
};

export const item: SxProps<Theme> = (theme) => ({
    height: ITEM_HEIGHT,
    borderRadius: 1,
    padding: theme.spacing(1.5, 2),

    '& .MuiStack-root': {
        height: 24,
    },

    '&.Mui-selected': {
        backgroundColor: 'transparent',

        '& .MuiIconButton-root': {
            display: 'none',
        },
    },

    '&:hover, &.Mui-selected:hover': {
        backgroundColor: theme.palette.custom.gray.active,

        '&.Mui-selected .MuiIconButton-root': {
            display: 'block',
        },
    },
});

export const itemWithCheckbox: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(2, 3),
    height: ITEM_HEIGHT,
    borderRadius: 1,
    backgroundColor: 'transparent',

    '& .MuiCheckbox-root': {
        padding: 0,
    },

    '&.Mui-selected, & .MuiCheckbox-root:hover': {
        backgroundColor: 'transparent',
    },
});

export const searchInput: SxProps = {
    '& .MuiInputBase-inputAdornedStart': {
        py: 1.5,
    },
};

export const addCohortInput: SxProps<Theme> = (theme) => ({
    borderBottom: `1px solid ${theme.palette.info.main}`,
    height: 20,

    '& .MuiInputBase-input': {
        p: 0,
    },
});
