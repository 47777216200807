import { checkIsFreeTeam } from '../queries/user';
import { Children, PropsWithChildren } from 'react';

export const ShowForPaidTeam = ({ children }: PropsWithChildren) => {
    if (checkIsFreeTeam()) {
        return null;
    }

    return Children.only(children);
};
