import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ShiftFilled = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 22" fill="none">
            <g filter="url(#filter0_d_28609_5062)">
                <rect x="1" width="32" height="20" rx="4" fill="white" />
                <rect
                    x="1.5"
                    y="0.5"
                    width="31"
                    height="19"
                    rx="3.5"
                    stroke="#E0E4EB"
                />
                <path
                    d="M28.8895 8.40034V9.18953H25.7486V8.40034H28.8895ZM26.664 6.94824H27.5953V12.7251C27.5953 12.9881 27.6334 13.1854 27.7097 13.317C27.7886 13.4459 27.8886 13.5327 28.0096 13.5774C28.1332 13.6195 28.2634 13.6405 28.4002 13.6405C28.5028 13.6405 28.587 13.6353 28.6528 13.6247C28.7185 13.6116 28.7711 13.6011 28.8106 13.5932L29 14.4297C28.9369 14.4534 28.8487 14.4771 28.7356 14.5007C28.6225 14.527 28.4791 14.5402 28.3055 14.5402C28.0425 14.5402 27.7847 14.4836 27.5321 14.3705C27.2822 14.2574 27.0744 14.0851 26.9087 13.8536C26.7456 13.6221 26.664 13.3301 26.664 12.9776V6.94824Z"
                    fill="#2E365E"
                />
                <path
                    d="M24.7633 8.39912V9.18831H21.4961V8.39912H24.7633ZM22.4747 14.4601V7.56259C22.4747 7.21534 22.5562 6.92598 22.7193 6.69448C22.8824 6.46299 23.0942 6.28937 23.3546 6.17362C23.615 6.05787 23.8899 6 24.1793 6C24.4082 6 24.5949 6.01841 24.7396 6.05524C24.8843 6.09207 24.9921 6.12627 25.0632 6.15784L24.7949 6.96281C24.7475 6.94702 24.6817 6.92729 24.5976 6.90362C24.516 6.87994 24.4082 6.8681 24.274 6.8681C23.9662 6.8681 23.7439 6.94571 23.6071 7.10091C23.473 7.25612 23.4059 7.48367 23.4059 7.78356V14.4601H22.4747Z"
                    fill="#2E365E"
                />
                <path
                    d="M19.2423 14.4605V8.39956H20.1735V14.4605H19.2423ZM19.7158 7.3894C19.5343 7.3894 19.3777 7.32758 19.2462 7.20395C19.1173 7.08031 19.0529 6.93168 19.0529 6.75806C19.0529 6.58444 19.1173 6.43581 19.2462 6.31217C19.3777 6.18853 19.5343 6.12671 19.7158 6.12671C19.8973 6.12671 20.0525 6.18853 20.1814 6.31217C20.3129 6.43581 20.3787 6.58444 20.3787 6.75806C20.3787 6.93168 20.3129 7.08031 20.1814 7.20395C20.0525 7.32758 19.8973 7.3894 19.7158 7.3894Z"
                    fill="#2E365E"
                />
                <path
                    d="M13.4233 10.8144V14.4604H12.4921V6.37915H13.4233V9.34648H13.5022C13.6443 9.03344 13.8574 8.78485 14.1415 8.6007C14.4282 8.41393 14.8096 8.32054 15.2858 8.32054C15.6988 8.32054 16.0605 8.40341 16.3709 8.56914C16.6813 8.73224 16.922 8.98346 17.093 9.32281C17.2666 9.65953 17.3534 10.0883 17.3534 10.6092V14.4604H16.4222V10.6723C16.4222 10.1909 16.2972 9.81868 16.0473 9.55562C15.8001 9.28993 15.4568 9.15708 15.0175 9.15708C14.7123 9.15708 14.4387 9.22153 14.1967 9.35043C13.9573 9.47933 13.7679 9.66742 13.6285 9.9147C13.4917 10.162 13.4233 10.4619 13.4233 10.8144Z"
                    fill="#2E365E"
                />
                <path
                    d="M9.76667 8.39935C9.71932 7.9995 9.52729 7.68909 9.19057 7.46811C8.85385 7.24714 8.44084 7.13666 7.95155 7.13666C7.59379 7.13666 7.28074 7.19453 7.01242 7.31028C6.74673 7.42603 6.53891 7.58518 6.38896 7.78773C6.24165 7.99029 6.16799 8.22047 6.16799 8.47827C6.16799 8.69398 6.21929 8.87944 6.32188 9.03465C6.42711 9.18722 6.56127 9.31481 6.72437 9.4174C6.88747 9.51736 7.05846 9.60023 7.23734 9.66599C7.41622 9.72913 7.58063 9.78042 7.73058 9.81988L8.55133 10.0409C8.76178 10.0961 8.9959 10.1724 9.2537 10.2697C9.51413 10.3671 9.76273 10.4999 9.99948 10.6683C10.2389 10.834 10.4362 11.0471 10.5914 11.3075C10.7466 11.5679 10.8242 11.8875 10.8242 12.2664C10.8242 12.703 10.7097 13.0976 10.4809 13.4501C10.2547 13.8026 9.9232 14.0828 9.48651 14.2906C9.05246 14.4984 8.52502 14.6023 7.9042 14.6023C7.32546 14.6023 6.82433 14.509 6.4008 14.3222C5.9799 14.1354 5.64845 13.875 5.40643 13.5409C5.16704 13.2068 5.03157 12.8188 5 12.3768H6.01016C6.03646 12.682 6.13906 12.9345 6.31794 13.1345C6.49945 13.3318 6.72831 13.4791 7.00453 13.5764C7.28337 13.6711 7.58326 13.7185 7.9042 13.7185C8.27775 13.7185 8.61315 13.658 8.91041 13.5369C9.20767 13.4133 9.44311 13.2423 9.61673 13.024C9.79035 12.803 9.87716 12.5452 9.87716 12.2506C9.87716 11.9822 9.80219 11.7639 9.65224 11.5955C9.5023 11.4272 9.305 11.2904 9.06035 11.1852C8.81571 11.0799 8.55133 10.9879 8.26722 10.909L7.27285 10.6249C6.6415 10.4433 6.14169 10.1842 5.7734 9.84751C5.40511 9.51079 5.22097 9.07016 5.22097 8.52562C5.22097 8.07316 5.3433 7.67856 5.58794 7.34185C5.83522 7.0025 6.16668 6.73943 6.58231 6.55266C7.00058 6.36326 7.46752 6.26855 7.98312 6.26855C8.50398 6.26855 8.96697 6.36194 9.37208 6.54872C9.7772 6.73286 10.0981 6.9854 10.3349 7.30633C10.5743 7.62727 10.7005 7.99161 10.7137 8.39935H9.76667Z"
                    fill="#2E365E"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_28609_5062"
                    x="0"
                    y="0"
                    width="34"
                    height="22"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.211765 0 0 0 0.16 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_28609_5062"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_28609_5062"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    </SvgIcon>
);
