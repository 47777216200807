import { MiddlewareParams } from '@segment/analytics-next/dist/types/plugins/middleware';
import merge from 'lodash/merge';
import { analytics } from '../services/analytics';

export class UtmMiddleware {
    utmParams: Record<string, string | null>;

    constructor() {
        const urlParams = new URLSearchParams(window.location.search);
        this.utmParams = {
            utm_source: urlParams.get('utm_source'),
            utm_medium: urlParams.get('utm_medium'),
            utm_campaign: urlParams.get('utm_campaign'),
            utm_term: urlParams.get('utm_term'),
            utm_content: urlParams.get('utm_content'),
        };
        this.saveToStorage();
    }

    saveToStorage() {
        Object.entries(this.utmParams).forEach(([key, value]) => {
            if (value) {
                sessionStorage.setItem(key, value);
            }
        });
    }

    getFromStorage() {
        return Object.entries(this.utmParams).reduce<Record<string, string>>(
            (acc, [key]) => {
                const value = sessionStorage.getItem(key);
                if (value) {
                    acc[key] = value;
                }
                return acc;
            },
            {},
        );
    }

    private createMiddleware() {
        return (ctx: MiddlewareParams) => {
            const type = ctx.payload.type();
            if (type === 'track' || type === 'page') {
                merge(ctx.payload.obj.properties, this.getFromStorage());
            }
            ctx.next(ctx.payload);
        };
    }

    bootstrap() {
        return analytics.addSourceMiddleware(this.createMiddleware());
    }
}
