import * as styles from '../../styles';
import { Chip, Divider, Stack, Tooltip } from '@mui/material';
import { FileUploaderButton } from './FileUploader/FileUploaderButton';
import { EmojiButton } from './Emoji/EmojiButton';
import { TemplateButton } from './Template/TemplateButton';
import { WithFeature } from '../../../../containers/Feature/WithFeature';
import { Conversation, Feature, MessageTemplate } from '../../../../api/types';
import { SnippetButton } from './Snippet/SnippetButton';
import { SnippetIcon } from './Snippet/SnippetIcon';
import { AssistantMenu } from './AiAssistant/AssistantMenu';
import { SignatureButton } from './Signature/SignatureButton';
import { CancelButton } from './CancelButton/CancelButton';
import { ScheduledButton } from './Scheduled/ScheduledButton';
import { SendButton } from './SendButton/SendButton';
import React, { useRef } from 'react';
import { useSlate } from 'slate-react';
import { useIsDenseMode } from '../../useIsDenseMode';

type Props = {
    withFileUploader?: boolean;
    handleUpload: (file: File) => void;
    disabled: boolean;
    externalTemplateSelected: boolean;
    toolbar: string[];
    propertiesRegEx: RegExp;
    template?: MessageTemplate;
    unselectTemplate: () => void;
    handleSnippetSelected: (template: MessageTemplate) => void;
    withAiAssistant: boolean;
    aiAssistantEnabledForInbox: boolean;
    aiPipelineEnabledForInbox: boolean;
    conversation?: Conversation;
    containerRef: React.RefObject<HTMLDivElement>;
    edit: boolean;
    onCancel: () => void;
    sendAt?: string;
    onScheduledSubmit: (dateTime: Date) => Promise<void>;
    onSubmit: () => void;
    canSend: boolean;
    withSendButton: boolean;
    isTransparent?: boolean;
};

export function Toolbar({
    withFileUploader,
    handleUpload,
    disabled,
    externalTemplateSelected,
    toolbar,
    propertiesRegEx,
    template,
    unselectTemplate,
    handleSnippetSelected,
    withAiAssistant,
    aiAssistantEnabledForInbox,
    aiPipelineEnabledForInbox,
    conversation,
    containerRef,
    edit,
    onCancel,
    sendAt,
    onScheduledSubmit,
    onSubmit,
    canSend,
    withSendButton,
    isTransparent = false,
}: Props) {
    const uploadRef = useRef<HTMLInputElement>(null);
    const editor = useSlate();
    const isDenseMode = useIsDenseMode();

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={isTransparent ? styles.transparentToolbar : styles.toolbar}
            spacing={2}
        >
            <Stack
                direction="row"
                spacing={2}
                flex={1}
                alignItems="center"
                divider={
                    !isDenseMode && (
                        <Divider orientation="vertical" sx={styles.divider} />
                    )
                }
            >
                {withFileUploader && (
                    <FileUploaderButton
                        uploadFile={handleUpload}
                        uploadRef={uploadRef}
                        disabled={disabled || externalTemplateSelected}
                        editor={editor}
                    />
                )}

                <Stack direction="row" spacing={0.5} alignItems="center">
                    {toolbar?.includes('emojiPicker') && (
                        <EmojiButton
                            disabled={disabled || externalTemplateSelected}
                            editor={editor}
                        />
                    )}

                    {toolbar?.includes('templates') && (
                        <TemplateButton
                            disabled={disabled || externalTemplateSelected}
                            editor={editor}
                        />
                    )}

                    {!externalTemplateSelected &&
                        toolbar?.includes('snippets') && (
                            <WithFeature feature={Feature.MessageTemplates}>
                                <SnippetButton
                                    withProperties={toolbar.includes(
                                        'templates',
                                    )}
                                    propertiesRegEx={propertiesRegEx}
                                    editor={editor}
                                    containerRef={containerRef}
                                    disabled={disabled}
                                    onSelect={handleSnippetSelected}
                                />
                            </WithFeature>
                        )}

                    {externalTemplateSelected && (
                        <Tooltip title={`WhatsApp Template: ${template?.name}`}>
                            <Chip
                                sx={{ 'max-width': '300px;' }}
                                icon={<SnippetIcon color="warning" />}
                                onClick={unselectTemplate}
                                label={template?.name}
                                variant="outlined"
                                size="small"
                            />
                        </Tooltip>
                    )}
                </Stack>

                {withAiAssistant && conversation?.id && (
                    <WithFeature feature={Feature.ConversationSummary}>
                        <AssistantMenu
                            aiAssistantEnabledForInbox={
                                aiAssistantEnabledForInbox
                            }
                            aiPipelineEnabledForInbox={
                                aiPipelineEnabledForInbox
                            }
                            aiDisabledForConversation={
                                !!conversation?.assistantDisabled
                            }
                            editor={editor}
                            disabled={disabled || externalTemplateSelected}
                            conversationId={conversation.id}
                        />
                    </WithFeature>
                )}

                {toolbar?.includes('signature') && (
                    <SignatureButton
                        disabled={disabled || externalTemplateSelected}
                    />
                )}
            </Stack>

            <Stack direction="row" spacing={3} alignItems="center">
                {edit ? (
                    <CancelButton handleCancel={onCancel} />
                ) : (
                    toolbar?.includes('schedule') && (
                        <ScheduledButton
                            disabled={
                                disabled || !canSend || externalTemplateSelected
                            }
                            sendAt={sendAt ? new Date(sendAt) : null}
                            onScheduledSubmit={onScheduledSubmit}
                        />
                    )
                )}

                {withSendButton && (
                    <SendButton
                        aiEnabled={
                            withAiAssistant &&
                            (aiAssistantEnabledForInbox ||
                                aiPipelineEnabledForInbox) &&
                            !conversation?.assistantDisabled
                        }
                        canSend={canSend}
                        disabled={disabled}
                        handleSubmit={onSubmit}
                        title={
                            externalTemplateSelected ? 'Send Template' : 'Send'
                        }
                        edit={edit}
                    />
                )}
            </Stack>
        </Stack>
    );
}
