import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../../../types/clerk-icon.props';

export const NotificationsIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.591 4.40901C18.7123 3.53033 17.2877 3.53033 16.409 4.40901C15.5303 5.28769 15.5303 6.71231 16.409 7.59099C17.2877 8.46967 18.7123 8.46967 19.591 7.59099C20.4697 6.71231 20.4697 5.28769 19.591 4.40901ZM15.3483 3.34835C16.8128 1.88388 19.1872 1.88388 20.6517 3.34835C22.1161 4.81282 22.1161 7.18718 20.6517 8.65165C19.1872 10.1161 16.8128 10.1161 15.3483 8.65165C13.8839 7.18718 13.8839 4.81282 15.3483 3.34835ZM12.0039 3.75C7.44755 3.75 3.75391 7.44365 3.75391 12C3.75391 13.3187 4.06277 14.5632 4.61142 15.6669C4.86955 16.1862 4.86342 16.843 4.80239 17.3941C4.73744 17.9805 4.58885 18.6112 4.42745 19.1769C4.31654 19.5657 4.19661 19.9339 4.08694 20.25H12.0039C16.5603 20.25 20.2539 16.5563 20.2539 12C20.2539 11.5858 20.5897 11.25 21.0039 11.25C21.4181 11.25 21.7539 11.5858 21.7539 12C21.7539 17.3848 17.3887 21.75 12.0039 21.75H3.00463C2.75389 21.75 2.51973 21.6247 2.38063 21.4161C2.24155 21.2075 2.21588 20.9432 2.31222 20.7118C2.31222 20.7118 2.31222 20.7118 2.31223 20.7118L2.31226 20.7117L2.31227 20.7117L2.31281 20.7104L2.31552 20.7038L2.32717 20.6753C2.33758 20.6497 2.35311 20.6111 2.37288 20.5611C2.41242 20.461 2.46876 20.3154 2.53468 20.1363C2.66692 19.7771 2.83578 19.2884 2.985 18.7654C3.1354 18.2382 3.2596 17.6976 3.3115 17.229C3.36732 16.725 3.32209 16.443 3.26821 16.3346C2.61875 15.0281 2.25391 13.5556 2.25391 12C2.25391 6.61522 6.61913 2.25 12.0039 2.25C12.4181 2.25 12.7539 2.58579 12.7539 3C12.7539 3.41421 12.4181 3.75 12.0039 3.75Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
