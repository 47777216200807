import cc from 'classcat';
//style
import styles from './PropertyIconEditor.module.scss';
//icons
import { ICONS_SET } from './iconsSet';
import { useState } from 'react';
import useOnKeyDown from '../../../hooks/useOnKeyDown';
import { PropertyIcon } from '../../../icons/iconsMap';
import { Icon } from '../../../icons/Icon';

const COLS = 7;

interface PropertyIconEditorProps {
    currentIcon?: PropertyIcon;
    onSelect?: (icon: PropertyIcon) => void;
}

export const PropertyIconEditor = ({
    currentIcon,
    onSelect,
}: PropertyIconEditorProps) => {
    const [activeIdx, setActiveIdx] = useState<number>(() =>
        ICONS_SET.findIndex((icon) => icon === currentIcon),
    );

    useOnKeyDown(null, 'ArrowUp', () => {
        if (activeIdx === -1) {
            setActiveIdx(0);
        } else if (activeIdx - COLS >= 0) {
            setActiveIdx(activeIdx - COLS);
        }
    });

    useOnKeyDown(null, 'ArrowDown', () => {
        if (activeIdx === -1) {
            setActiveIdx(0);
        } else if (activeIdx + COLS < ICONS_SET.length) {
            setActiveIdx(activeIdx + COLS);
        }
    });

    useOnKeyDown(null, 'ArrowLeft', () => {
        if (activeIdx === -1) {
            setActiveIdx(0);
        } else if (
            Math.floor(activeIdx / COLS) === Math.floor((activeIdx - 1) / COLS)
        ) {
            setActiveIdx(activeIdx - 1);
        }
    });

    useOnKeyDown(null, 'ArrowRight', () => {
        if (activeIdx === -1) {
            setActiveIdx(0);
        } else if (
            Math.floor(activeIdx / COLS) === Math.floor((activeIdx + 1) / COLS)
        ) {
            setActiveIdx(activeIdx + 1);
        }
    });

    useOnKeyDown(null, 'Enter', () => {
        if (activeIdx !== -1) {
            onSelect?.(ICONS_SET[activeIdx]);
        }
    });

    return (
        <div className={styles['root']}>
            <p className={styles['root__title']}>Set up a custom icon</p>
            <div className={styles['root__icons']}>
                {ICONS_SET.map((icon, idx) => (
                    <button
                        key={icon}
                        className={cc([
                            styles['root__icon-btn'],
                            {
                                [styles['root__icon-btn_selected']]:
                                    currentIcon === icon,
                                [styles['root__icon-btn_active']]:
                                    idx === activeIdx,
                            },
                        ])}
                        onClick={() => onSelect?.(icon)}
                    >
                        <Icon name={icon} />
                    </button>
                ))}
            </div>
        </div>
    );
};
