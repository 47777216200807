import { createContext, ReactNode, useContext, useState } from 'react';
import { InboxSettings } from '../../containers/InboxSettings/InboxSettings';

type InboxSettingsContextType = {
    inboxId: string | null;
    isDeleteView: boolean;
    openInboxSettings: (inboxId: string | null, isDelete?: boolean) => void;
};

const InboxSettingsContext = createContext<InboxSettingsContextType>({
    inboxId: null,
    isDeleteView: false,
    openInboxSettings: () => {},
});

export const InboxSettingsProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [inboxId, setInboxId] =
        useState<InboxSettingsContextType['inboxId']>(null);
    const [isDeleteView, setIsDeleteView] = useState(false);

    const openInboxSettings = (inboxId: string | null, isDelete = false) => {
        setInboxId(inboxId);
        setIsDeleteView(isDelete);
    };

    return (
        <InboxSettingsContext.Provider
            value={{ inboxId, isDeleteView, openInboxSettings }}
        >
            {children}
            {!!inboxId && (
                <InboxSettings
                    open
                    inboxId={inboxId}
                    deleteInbox={isDeleteView}
                    cancelDeleteInbox={() => {
                        setIsDeleteView(false);
                    }}
                    onClose={() => {
                        openInboxSettings(null);
                    }}
                />
            )}
        </InboxSettingsContext.Provider>
    );
};

export const useSidebarContext = () => {
    return useContext(InboxSettingsContext);
};
