import { Box, SvgIcon } from '@mui/material';
import {
    base,
    icon,
} from '../../../main/settings/KnowledgeBase/List/source-icon.styles';
import CSV from '../../../main/settings/KnowledgeBase/icons/CFileTypeCSV.svg?react';
import Text from '../../../main/settings/KnowledgeBase/icons/CFileTypeText.svg?react';
import Pdf from '../../../main/settings/KnowledgeBase/icons/CFileTypePDF.svg?react';
import Word from '../../../main/settings/KnowledgeBase/icons/CFileTypeDoc.svg?react';
import Sitemap from '../../../main/settings/KnowledgeBase/icons/CFileSitemap.svg?react';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const TextSourceIcon = () => (
    <Box sx={base}>
        <SvgIcon sx={icon} component={Text} inheritViewBox />
    </Box>
);

/**
 * @deprecated use CFileTypeDocIcon.jsx
 */
const WordIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon sx={icon} component={Word} inheritViewBox {...props} />
);

export const WordSourceIcon = () => (
    <Box sx={base}>
        <WordIcon />
    </Box>
);

export const CsvIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon component={CSV} inheritViewBox {...props} />
);
const SitemapIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon component={Sitemap} inheritViewBox {...props} />
);
export const SitemapSourceIcon = () => (
    <Box sx={base}>
        <SitemapIcon sx={icon} />
    </Box>
);

export const CSVSourceIcon = () => (
    <Box sx={base}>
        <CsvIcon sx={icon} />
    </Box>
);

// @deprecated: use CFileTypePDF.jsx
const PdfIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon component={Pdf} inheritViewBox {...props} />
);

// @deprecated: use CFileTypePDF.jsx
export const PdfSourceIcon = () => (
    <Box sx={base}>
        <PdfIcon sx={icon} />
    </Box>
);
