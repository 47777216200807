import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import сreateClient, { client } from '../../../api/http';
import { Attachment, Conversation, Profile } from '../../../api/types';
import { queryClient } from '../../../queries/queryClient';
import { UUID } from '../../../types/uuid';
import { useTrack } from '../../../contexts/analytics';
import { AtLeastOne } from '../../../utils/types';
import isUndefined from 'lodash/isUndefined';
import { CAMPAIGN_PAGES, CampaignPage } from '../../../queries/campaigns';
import { useMeQueryData } from '../../../queries/user';

export enum CampaignMessageStatus {
    Created = 'created',
    Planned = 'planned',
    Running = 'running',
    Fulfilled = 'fulfilled',
    SmsExceeded = 'sms_exceeded',
    NoContacts = 'no_contacts',
}

export interface CampaignMessage {
    id: number;
    userId: string;
    body: string;
    campaignId: number;
    timestamp: string;
    sentCount: number;
    status: CampaignMessageStatus;
    attachments: Attachment[];
    user?: Profile;
}

export const CAMPAIGN_MESSAGES = 'campaign_messages';

const syncLatestMessage = () => {
    queryClient
        .getQueriesData<CampaignMessage[]>({
            queryKey: [CAMPAIGN_MESSAGES],
        })
        .map(([_, messages]) => messages)
        .filter((array): array is CampaignMessage[] => !isUndefined(array))
        .forEach((messages) => {
            const latest = messages.reduce((a, b) =>
                a.timestamp > b.timestamp ? a : b,
            );
            queryClient.setQueriesData<{ pages: CampaignPage[] }>(
                { queryKey: [CAMPAIGN_PAGES] },
                (prev) => {
                    if (!prev?.pages?.length) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) => {
                            if (!page?.data?.length) {
                                return page;
                            }
                            return {
                                ...page,
                                data: page.data.map((campaign) => {
                                    if (campaign.id === latest.campaignId) {
                                        return {
                                            ...campaign,
                                            body: latest.body,
                                            latestTimestamp: latest.timestamp,
                                        };
                                    }

                                    return campaign;
                                }),
                            };
                        }),
                    };
                },
            );
        });
};

export const useCampaignMessages = (campaignId: number | undefined) =>
    useQuery({
        queryKey: [CAMPAIGN_MESSAGES, campaignId],
        queryFn: () =>
            client
                .get<CampaignMessage[]>(`/campaigns/${campaignId}/messages`)
                .then(({ data }) => data),
        enabled: !!campaignId,
    });

const CONVERSATION_PAGE_LIMIT = 15;

export type ConversationPage = {
    data: Conversation[];
    page: number;
    limit: number;
    total: number;
};

const CAMPAIGN_MESSAGE_CONVERSATIONS_KEY = 'campaign_message_conversations';
export const useCampaignMessageConversations = (campaignMessageId: number) =>
    useInfiniteQuery<ConversationPage>({
        initialPageParam: 1,
        queryKey: [CAMPAIGN_MESSAGE_CONVERSATIONS_KEY, campaignMessageId],
        getNextPageParam: (last) => {
            return last?.data?.length < CONVERSATION_PAGE_LIMIT
                ? null
                : (last?.page || 0) + 1;
        },
        queryFn: ({ queryKey: [_, id], pageParam }) =>
            client
                .get<ConversationPage>(
                    `/campaign-messages/${id}/conversations`,
                    {
                        params: {
                            limit: CONVERSATION_PAGE_LIMIT,
                            page: pageParam,
                        },
                    },
                )
                .then(({ data }) => data),
        enabled: !!campaignMessageId,
    });

const CAMPAIGN_MESSAGE = 'campaign_message';

export const useCampaignMessage = (campaignMessageId: number) =>
    useQuery({
        queryKey: [CAMPAIGN_MESSAGE, campaignMessageId],
        queryFn: ({ queryKey: [_, id] }) =>
            client
                .get<CampaignMessage>(`/campaign-messages/${id}`)
                .then(({ data }) => data),
        enabled: !!campaignMessageId,
    });

type CampaignCreate = {
    campaignId: number;
    body: string;
    timestamp?: Date;
    attachments?: UUID[];
};

export const useCreateCampaignMessage = () => {
    const track = useTrack();
    const user = useMeQueryData();

    return useMutation({
        mutationFn: (message: CampaignCreate) =>
            client
                .post<CampaignMessage>(`/campaign-messages/`, message)
                .then(({ data }) => data),
        onSuccess: (message: CampaignMessage) => {
            track('campaign_message_created', {
                type:
                    message.status === CampaignMessageStatus.Planned
                        ? 'planned'
                        : 'instant',
                campaignId: message.campaignId,
                bodyLength: message.body.length,
                attachmentsCount: message.attachments.length,
            });

            if (message.status !== CampaignMessageStatus.Planned) {
                queryClient
                    .getQueryData<
                        CampaignMessage[]
                    >([CAMPAIGN_MESSAGES, message.campaignId])
                    ?.map(({ id }) => {
                        queryClient.removeQueries({
                            queryKey: [CAMPAIGN_MESSAGE_CONVERSATIONS_KEY, id],
                        });
                    });
            }

            queryClient.setQueryData<CampaignMessage[]>(
                [CAMPAIGN_MESSAGES, message.campaignId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return [...prev, { ...message, user }].sort(
                        (a, b) =>
                            new Date(a.timestamp).getTime() -
                            new Date(b.timestamp).getTime(),
                    );
                },
            );
            syncLatestMessage();
        },
    });
};

export const useCampaignMessageRemove = (id: number, campaignId?: string) => {
    const track = useTrack();

    return useMutation({
        mutationFn: () =>
            client
                .delete<void>(`/campaign-messages/${id}`)
                .then(({ data }) => data),
        onSuccess: () => {
            track('campaign_message_deleted');
            queryClient.invalidateQueries({
                queryKey: [CAMPAIGN_MESSAGE, id],
            });
            queryClient.setQueriesData<CampaignMessage[]>(
                { queryKey: [CAMPAIGN_MESSAGES] },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    const afterRemove = prev.filter(
                        (message) => message.id !== id,
                    );
                    if (afterRemove.length === 0 && campaignId) {
                        queryClient.setQueriesData<{ pages: CampaignPage[] }>(
                            { queryKey: [CAMPAIGN_PAGES] },
                            (prev) => {
                                if (!prev?.pages?.length) {
                                    return prev;
                                }
                                return {
                                    ...prev,
                                    pages: prev.pages.map((page) => {
                                        if (!page?.data?.length) {
                                            return page;
                                        }
                                        return {
                                            ...page,
                                            data: page.data.map((campaign) => {
                                                if (
                                                    campaign.id === +campaignId
                                                ) {
                                                    return {
                                                        ...campaign,
                                                        body: '',
                                                    };
                                                }

                                                return campaign;
                                            }),
                                        };
                                    }),
                                };
                            },
                        );
                    }
                    return afterRemove;
                },
            );
            syncLatestMessage();
        },
    });
};

type UpdateParams = AtLeastOne<{
    attachments: UUID[];
    body: string;
    timestamp: string | undefined;
}>;

export const useCampaignMessageUpdate = (id?: number) =>
    useMutation({
        mutationKey: ['campaign_message_edit', id],
        mutationFn: (params: UpdateParams) =>
            сreateClient()
                .patch<
                    Partial<CampaignMessage> & { id: CampaignMessage['id'] }
                >(`/campaign-messages/${id}`, params)
                .then(({ data }) => data),
        onSuccess: (updated) => {
            queryClient.setQueryData<CampaignMessage>(
                [CAMPAIGN_MESSAGE, id],
                (prev) => (prev ? { ...prev, ...updated } : prev),
            );
            queryClient.setQueriesData<CampaignMessage[]>(
                { queryKey: [CAMPAIGN_MESSAGES] },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    return prev.map((message) =>
                        message.id === id
                            ? { ...message, ...updated }
                            : message,
                    );
                },
            );
            syncLatestMessage();
        },
    });
