import { Inbox } from '../../api/types';
import { IconButton } from '@mui/material';
import { MoreHorizFilledIcon } from '../../icons/common/MoreHorizFilled';
import { InboxContextMenu } from './InboxContextMenu';
import { useMenu } from '../../hooks/use-menu';

export function ContextMenu({
    inboxId,
    disabled = false,
}: {
    inboxId: Inbox['id'];
    disabled?: boolean;
}) {
    const menu = useMenu();

    return (
        <>
            <IconButton
                disabled={disabled}
                id={`inbox-context-menu-button-${inboxId}`}
                aria-controls={
                    menu.open ? `inbox-context-menu-${inboxId}` : undefined
                }
                aria-expanded={menu.open ? 'true' : undefined}
                onClick={(e) => {
                    menu.handleOpen(e);
                }}
                aria-haspopup="true"
                color="primary"
            >
                <MoreHorizFilledIcon />
            </IconButton>
            <InboxContextMenu inboxId={inboxId} menu={menu} />
        </>
    );
}
