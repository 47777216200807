import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';
import { SxProps } from '@mui/material';

export const teamSelectorBtn: SxProps = {
    height: HEADER_HEIGHT,
    paddingLeft: 4,
    paddingRight: 0,
    overflow: 'hidden',
    justifyContent: 'flex-start',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiButton-icon': { ml: 0 },
    '& .MuiButton-endIcon': { mr: 0, width: 28, height: 28 },
};
export const avatar: SxProps = {
    height: 1,
    width: 1,
    objectFit: 'cover',
};
export const limitedName: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    textWrap: 'wrap',
};

export const buttonLikeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0.5,
    width: 20,
    height: 20,
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    color: 'custom.gray.super',

    '& svg': {
        width: 20,
        height: 20,
    },

    '&:hover': {
        backgroundColor: 'custom.gray.tableDivider',
        cursor: 'pointer',
    },
};

export const buttonLikeActiveStyle = {
    backgroundColor: 'custom.gray.tableDivider',
};
