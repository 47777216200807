import { UUID } from '../../types/uuid';
import { Descendant } from 'slate';
import { createInitialValue } from '../MessageInput/utils';
import { JSONSerializer } from '../MessageInput/serializers/json';

export const saveMessageDraft = ({
    message,
    conversationId,
}: {
    conversationId: UUID;
    message?: Descendant[];
}) => {
    if (message) {
        window.localStorage.setItem(
            `${conversationId}_draft`,
            JSONSerializer.serialize(message),
        );
    } else {
        window.localStorage.removeItem(`${conversationId}_draft`);
    }
};

export const getMessageDraft = (conversationId?: UUID) => {
    const defaultValue = createInitialValue();
    try {
        if (conversationId) {
            const draft = window.localStorage.getItem(
                `${conversationId}_draft`,
            );
            return draft ? JSONSerializer.deserialize(draft) : defaultValue;
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
    return defaultValue;
};
