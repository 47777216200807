import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles';
import { colors } from './palette';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
    styleOverrides: {
        paperAnchorDockedLeft: ({ theme }) => ({
            width: 248,
            borderRight: '1px solid',
            borderColor: theme.palette.custom.gray.divider,
            backgroundColor: colors.lightGrey[50],
            [theme.breakpoints.down('md')]: {
                width: '100vw',
            },

            '& .MuiList-root': {
                padding: theme.spacing(0, 2, 0, 2),

                '& .MuiButtonBase-root': {
                    margin: 0,
                },

                '& .MuiListSubheader-root': {
                    ...theme.typography.body4,
                    backgroundColor: colors.lightGrey[50],
                    marginBottom: theme.spacing(2),
                    padding: theme.spacing(0, 2),
                },
            },
        }),
    },
};
