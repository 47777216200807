import { SxProps, Theme } from '@mui/material';

export const layoutContainer: SxProps<Theme> = {
    p: ({ spacing }) => spacing(6, 0),
    maxWidth: 808,
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 6,
    mx: 'auto',
    width: 1,
};

export const layout = {
    display: 'grid',
    height: '100vh',
    overflow: 'auto',
    gridTemplateColumns: {
        sm: '1fr',
        md: 'auto 1fr',
    },
    gridTemplateRows: {
        sm: '60px 1fr',
        md: '1fr',
    },
};

export const layoutHeader: SxProps<Theme> = (theme) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
        boxSizing: 'border-box',
        width: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'custom.gray.superLight',
        borderBottom: '1px solid',
        borderColor: 'custom.gray.divider',
    },
});

export const layoutHeaderMenuButton: SxProps<Theme> = {
    width: 64,
    borderRadius: 0,
    backgroundColor: 'custom.gray.active',
};

export const container: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: [0.95, 0.95, 0.95, 1],
    mx: 'auto',
};

export const shrinkHomeButton: SxProps = {
    width: 1,
    color: 'custom.gray.super',
};

export const homeButton: SxProps<Theme> = (theme) => ({
    ...theme.typography.body3,
    color: 'primary.dark',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0,
    height: '100%',
    padding: theme.spacing(5, 4),

    '&:visited': {
        color: 'primary.dark',
    },

    '& .MuiButton-startIcon': {
        color: 'custom.gray.super',
        marginRight: 4,
        marginLeft: 0,
    },
});

export const header: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '60px',
};

export const sidebar = {
    display: 'block',
};

export const collapsedSidebar = {
    width: 72,
    flexDirection: 'column',
};

export const nav: SxProps<Theme> = {
    width: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: 'custom.gray.superLight',
};
