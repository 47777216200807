import { Box, Button, Typography } from '@mui/material';
import { Inbox } from '../../api/types';
import { renderInboxName } from '../../utils/inboxes';
import { UserIcon } from '../../icons/common/CUser';
import { useNavigate } from 'react-router';

type AdminInboxProps = {
    inbox: Inbox;
};

export const AdminInbox = (props: AdminInboxProps) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
            }}
        >
            <Box
                sx={{
                    width: 1,
                    maxWidth: 480,
                    p: 2,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Typography fontSize={56} lineHeight="56px">
                    📱
                </Typography>
                <Typography variant="h1" mt={3}>
                    {renderInboxName(props.inbox)}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body3" component={Box}>
                        As an admin, you can manage member access to this inbox.
                    </Typography>
                    <Button
                        startIcon={<UserIcon />}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/settings/members');
                        }}
                        sx={{
                            mt: 4,
                            maxWidth: 200,
                            mx: 'auto',
                        }}
                        variant="outlined"
                    >
                        Manage Access
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
