import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { borderRadius } from './border-radius';
import { CloseIcon } from '../icons/common/CCloseIcon';
import { colors } from './palette';

export const MuiChip: Components<Theme>['MuiChip'] = {
    defaultProps: {
        deleteIcon: <CloseIcon />,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.body4,

            '&.MuiChip-colorDefault': {
                backgroundColor: theme.palette.custom.action.selected,
                color: theme.palette.primary.dark,
            },
        }),
        label: ({ theme }) => ({
            padding: theme.spacing(0, 1),
        }),
        deleteIcon: {
            marginRight: 0,
            marginLeft: 0,
            width: 16,
            height: 16,
        },
        sizeMedium: ({ theme }) => ({
            padding: theme.spacing(1.5, 2),
        }),
        sizeSmall: ({ theme }) => ({
            height: 24,
            padding: theme.spacing(1),
        }),
        outlined: {
            borderRadius,
            '&.MuiChip-colorDefault': {
                backgroundColor: 'white',
                borderColor: colors.lightGrey[400],
            },
        },
        icon: {
            marginRight: 0,
            width: 16,
            height: 16,
        },
    },
};
