import { useRef } from 'react';
import { Conversation } from '../../../api/types';
import { useSummarizeConversationQuery } from '../../../queries/conversations';
import Loading from '../../Loading/Loading';
import {
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { CopyIcon } from '../../../icons/common/CCopyIcon';
import { SummaryIcon } from '../../../icons/common/CSummaryIcon';
import { useMenu } from '../../../hooks/use-menu';
import { RefreshIcon } from '../../../icons/shared/RefreshIcon';
import { ToolbarPopper } from '../ToolbarPopper/ToolbarPopper';
import { header, noWrap, verticalDivider } from './styles';

interface Props {
    conversation: Conversation;
}

export const SummarizeAction = (props: Props) => {
    const menu = useMenu();
    const {
        data: summary,
        isPending: summaryLoading,
        isFetching: summaryFetching,
        refetch,
    } = useSummarizeConversationQuery(props.conversation.id, !!menu.anchorEl);

    const actionButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <Tooltip title="Summarize Conversation">
                <IconButton
                    onClick={menu.handleOpen}
                    ref={actionButtonRef}
                    color="primary"
                >
                    <SummaryIcon />
                </IconButton>
            </Tooltip>

            {!!menu.open && (
                <ToolbarPopper
                    width={350}
                    anchorEl={menu.anchorEl}
                    handleClose={menu.handleClose}
                    maxContentHeight={176}
                    header={
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            sx={header}
                        >
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                fontWeight="medium"
                            >
                                AI conversation summary
                            </Typography>
                        </Stack>
                    }
                    actions={
                        !summaryLoading && (
                            <Stack
                                direction="row"
                                spacing={3}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        sx={verticalDivider}
                                    />
                                }
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    disabled={summaryFetching}
                                    onClick={() => refetch()}
                                    fullWidth
                                    startIcon={
                                        summaryFetching ? undefined : (
                                            <RefreshIcon />
                                        )
                                    }
                                    sx={noWrap}
                                >
                                    {summaryFetching
                                        ? 'Re-Summarizing...'
                                        : 'Re-Summarize'}
                                </Button>

                                <Button
                                    size="small"
                                    variant="outlined"
                                    disabled={!summary}
                                    startIcon={<CopyIcon />}
                                    onClick={() => {
                                        try {
                                            navigator.clipboard.writeText(
                                                summary || '',
                                            );
                                        } catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.error('Copy error', e);
                                        }
                                    }}
                                    fullWidth
                                >
                                    Copy text
                                </Button>
                            </Stack>
                        )
                    }
                >
                    {summaryLoading ? (
                        <Loading />
                    ) : (
                        <Typography variant="body3" color="primary.dark">
                            {summary}
                        </Typography>
                    )}
                </ToolbarPopper>
            )}
        </>
    );
};
