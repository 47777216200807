import { SxProps, Theme } from '@mui/material';
import { colors } from '../../../theme/palette';

export const textField: SxProps<Theme> = {
    '& .MuiInputBase-root.MuiFilledInput-root': {
        py: 3,
        px: 6,
        background: '#fff',
        '&:focus, &:hover, &:active': {
            background: '#fff',
            borderColor: 'custom.gray.divider',

            '&:before': {
                borderColor: 'custom.gray.divider',
            },
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '&:before': {
            borderColor: 'custom.gray.divider',
        },
    },
    '& .MuiInputAdornment-root': {
        width: 20,
        height: 20,
    },
};

export const textInput: SxProps<Theme> = {
    minHeight: 60,
    height: 'auto',
};

export const popper: SxProps<Theme> = {
    inset: '-10px auto auto 23px!important',
    borderRadius: 2,
    backgroundColor: '#FAFAFA',
    border: '1px solid',
    borderColor: 'custom.gray.tableDivider',

    '& .MuiAutocomplete-paper': {
        borderRadius: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderColor: 'custom.gray.tableDivider',
        px: 1,
        '& ul': {
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            '.MuiAutocomplete-groupLabel': {
                fontSize: '12px',
                lineHeight: '16px',
                pt: 1,
                pb: 2,
                px: 3,
            },
            '& li': {
                '& > div div:last-child svg': {
                    display: 'none',
                    width: 20,
                    height: 20,
                    fill: colors.primary.gray.super,
                },
                '& > div div:last-child span': {
                    display: 'inline',
                },
            },
            '& li.Mui-focused': {
                backgroundColor: 'custom.gray.tableDivider',
                '& > div div:last-child svg': { display: 'inline' },
                '& > div div:last-child span': {
                    display: 'none',
                },
            },
        },
    },
};
export const optionListItem: SxProps = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
};
