import { queryClient } from '../../queries/queryClient';
import { CURRENT_USER_KEY } from '../../queries/user';

export const SUBSCRIPTION_INTENT_SUCCESS_EVENT = 'subscription:intent-success';
type SubscriptionIntentSuccessHandler = () => void;

export const subscriptionIntentSuccessHandler: SubscriptionIntentSuccessHandler =
    () => {
        queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
    };
