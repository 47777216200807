import { Components } from '@mui/material/styles/components';
import { Theme, svgIconClasses } from '@mui/material';
import { borderRadius } from './border-radius';
import { colors } from './palette';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
    defaultProps: {
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            borderRadius,
            '&.MuiIconButton-colorWhite-contained': {
                borderRadius,
                borderWidth: 1,
                borderStyle: 'solid',
                color: colors.blue[500],
                backgroundColor: 'white',
                borderColor: theme.palette.primary.outlinedBorder,
                boxShadow: theme.shadows[1],
                ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                    backgroundColor: colors.darkBlue[50],
                    boxShadow: 'none',
                },
                '&.Mui-disabled': {
                    color: colors.superGrey[200],
                    backgroundColor: colors.action.disabledBackground,
                },
            },
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                backgroundColor: theme.palette.custom.action.hover,
                boxShadow: 'none',
            },
        }),
        sizeLarge: ({ theme }) => ({
            padding: theme.spacing(1.5),
            width: 32,
            height: 32,
            [`& .${svgIconClasses.root}`]: {
                width: 20,
                height: 20,
            },
        }),
        sizeMedium: ({ theme }) => ({
            padding: theme.spacing(1),
            [`& .${svgIconClasses.root}`]: {
                width: 20,
                height: 20,
            },
        }),
        sizeSmall: ({ theme }) => ({
            padding: theme.spacing(0.5),
            [`& .${svgIconClasses.root}`]: {
                width: 16,
                height: 16,
            },
        }),
        colorPrimary: ({ theme }) => ({
            borderRadius,
            padding: theme.spacing(1),
            color: theme.palette.primary.dark,
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                backgroundColor: theme.palette.primary.hover,
            },
            '&.Mui-disabled': {
                color: colors.superGrey[200],
            },
        }),
        colorInfo: ({ theme }) => ({
            backgroundColor: theme.palette.info.main,
            borderRadius,
            color: theme.palette.info.contrastText,
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                backgroundColor: theme.palette.info.dark,
            },
            '&.Mui-disabled': {
                color: colors.superGrey[200],
                backgroundColor: 'none',
            },
        }),
        colorError: ({ theme }) => ({
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                backgroundColor: theme.palette.error.hover,
            },
        }),
    },
};
