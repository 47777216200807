import { Box, Tooltip, Typography } from '@mui/material';
import InviteMsTeamsUserModal from '../../../components/Modals/InviteMsTeamsUserModal/InviteMsTeamsUserModal';
import { useCallback, useState } from 'react';
import { useMeQueryData } from '../../../queries/user';
import { RoleTypes } from '../../../api/types';
import { InviteUserModal } from '../../../components/Modals/InviteUserModal/InviteUserModal';
import { InviteButton } from './InviteButton';
import { useTrack } from '../../../contexts/analytics';
import { ToastActions, useToastContext } from '../../toast/reducer';
import { useSidebar } from '../../../contexts/sidebar';

export const SideBarInvite = () => {
    const [showInviteModal, setShowInviteModal] = useState(false);
    const { dispatch: toastDispatch } = useToastContext();
    const track = useTrack();
    const me = useMeQueryData();
    const { isCollapsed } = useSidebar();

    const handleClick = () => {
        track('clicked_invite_team');
        setShowInviteModal(true);
    };

    const handleSuccessInvite = useCallback(() => {
        toastDispatch({
            type: ToastActions.ADD,
            payload: {
                severity: 'success',
                title: 'Invitation was sent',
                description:
                    'Once your teammate accepts the invitation, they will be able to use Clerk Chat.',
            },
        });
        setShowInviteModal(false);
    }, [toastDispatch]);

    return (
        <>
            <Box
                sx={{
                    px: 4,
                    py: 3,
                    borderBottom: '1px solid',
                    borderBottomColor: 'custom.gray.divider',
                }}
            >
                {isCollapsed ? (
                    <Tooltip
                        disableFocusListener
                        arrow
                        title={
                            <Typography noWrap variant="body4">
                                Invite team
                            </Typography>
                        }
                        placement="right"
                    >
                        <InviteButton onClick={handleClick} />
                    </Tooltip>
                ) : (
                    <InviteButton onClick={handleClick} />
                )}
            </Box>
            {[RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
                me?.activeRole?.type as RoleTypes,
            ) ? (
                <InviteUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                    onSuccess={handleSuccessInvite}
                />
            ) : (
                <InviteMsTeamsUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                    onSuccess={handleSuccessInvite}
                />
            )}
        </>
    );
};
