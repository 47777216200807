import cc from 'classcat';
import styles from './CopyButton.module.scss';
import { Icon } from '../../icons/Icon';

interface CopyButtonProps {
    text: string;
    className?: string;
}

export const CopyButton = ({ text, className }: CopyButtonProps) => (
    <button
        className={cc([styles['copy-btn'], className])}
        onClick={() => navigator.clipboard.writeText(text)}
    >
        <Icon name="copy" className={styles['copy-icon']} />
    </button>
);
