import { InfiniteData, useMutation, useQuery } from '@tanstack/react-query';
import client from '../../api/http';
import { Conversation, Message, MessageStatus } from '../../api/types';
import { Maybe } from '../../types/helpers';
import { UUID } from '../../types/uuid';
import { queryClient } from '../../queries/queryClient';
import { PagedData } from '../../types/PagedData';
import {
    clearAssistant,
    CONVERSATION_MESSAGES_KEY,
} from '../../queries/messages';
import { CONVERSATIONS_LIST_KEY } from '../../queries/conversations';
import { useTrack } from '../../contexts/analytics';
import { isAssistant } from './Assistant/check-is-until-review';

const MESSAGE_KEY = 'message';

export const useMessage = (identifier?: Maybe<number>) => {
    return useQuery({
        queryKey: [MESSAGE_KEY, identifier],
        queryFn: ({ queryKey: [_, id] }) =>
            client()
                .get<Message>(`messages/${id}`)
                .then(({ data }) => data),
        enabled: !!identifier,
        staleTime: 60000 /* one minute */,
    });
};

export const useSendImmediately = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: ({
            messageId,
        }: {
            messageId: number;
            conversationId: UUID;
        }) =>
            client()
                .post<Message>(`/scheduled-messages/${messageId}/send`)
                .then(({ data }) => data),
        onSuccess: (sent, { conversationId, messageId }) => {
            track('scheduled_message_sent_immediately');
            queryClient.setQueryData<InfiniteData<Message[]>>(
                [CONVERSATION_MESSAGES_KEY, conversationId],
                (prev) => {
                    if (!prev?.pages) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) =>
                            page.map((message) => {
                                return message.id === messageId
                                    ? {
                                          ...message,
                                          ...sent,
                                          status: MessageStatus.Sending,
                                      }
                                    : message;
                            }),
                        ),
                    };
                },
            );
            queryClient.setQueryData<Message>(
                [MESSAGE_KEY, messageId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        ...sent,
                        status: MessageStatus.Sending,
                    };
                },
            );
        },
        onError: (_, { messageId }) => {
            if (messageId) {
                queryClient.invalidateQueries({
                    queryKey: [MESSAGE_KEY, messageId],
                });
            }
        },
    });
};

export const useReplyRegeneration = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (conversationId: UUID) =>
            client()
                .post<Message | null>('ai/reply-regenerate', {
                    conversationId,
                })
                .then(({ data }) => data),
        onSuccess: (message, conversationId) => {
            track('assistant_message_regenerated');
            if (!message) {
                clearAssistant(conversationId);
                return;
            }
            queryClient.refetchQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });
            queryClient.setQueriesData<PagedData<Conversation>>(
                {
                    queryKey: [CONVERSATIONS_LIST_KEY],
                },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) =>
                            page.map((conversation) => {
                                if (conversation.id === conversationId) {
                                    return {
                                        ...conversation,
                                        assistantTimestamp: message.timestamp,
                                        assistantMessageId: message.id,
                                    };
                                }
                                return conversation;
                            }),
                        ),
                    };
                },
            );
        },
    });
};

export const useClearAssistant = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (conversationId: UUID) =>
            client().delete<void>(`/messages/clear-assistant`, {
                params: { conversationId },
            }),
        onSuccess: (_, conversationId: UUID) => {
            track('assistant_cleared');

            queryClient.setQueriesData<InfiniteData<Message[]>>(
                {
                    queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
                },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) =>
                            page.filter(
                                (message: Message) =>
                                    !isAssistant(message.senderType),
                            ),
                        ),
                    };
                },
            );
        },
    });
};
