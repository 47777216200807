import * as Yup from 'yup';

export const scheduleTypes = {
    everyday: 'Every day',
    weekdays: 'Weekdays',
    custom: 'Custom',
} as const;

export type ScheduleTypes = keyof typeof scheduleTypes;

export type ScheduleRecordItem = {
    enabled: boolean;
    from: string;
    to: string;
};

export type ScheduleDay = '0' | '1' | '2' | '3' | '4' | '5' | '6';

export type OutOfOfficeInputs = {
    enabled: boolean;
    timezone: string;
    scheduleType: ScheduleTypes;
    schedule: Record<ScheduleDay, ScheduleRecordItem>;
    message: string;
};

export const defaultFrom = '5:0';
export const defaultTo = '20:0';

export const defaultScheduleItem = {
    enabled: true,
    from: defaultFrom,
    to: defaultTo,
};

export function generateWeek<T>(item: T): Record<ScheduleDay, T> {
    return {
        '0': item,
        '1': item,
        '2': item,
        '3': item,
        '4': item,
        '5': item,
        '6': item,
    };
}

export const defaultSchedule: OutOfOfficeInputs['schedule'] =
    generateWeek(defaultScheduleItem);

export function normalizeTime(time: string) {
    const [hours, minutes] = time.split(':', 2).map(Number);
    return {
        hours,
        minutes,
    };
}

export const weekdays = ['1', '2', '3', '4', '5'] as const;

const daySchema = Yup.object({
    enabled: Yup.boolean().required(),
    from: Yup.string()
        .test(
            'isValidTime',
            'Time To must be later than Time From',
            (from, testContext) => {
                const timeFrom = normalizeTime(from as string);
                const timeTo = normalizeTime(testContext.parent.to);

                return !(
                    timeTo.hours < timeFrom.hours ||
                    (timeTo.hours === timeFrom.hours &&
                        timeTo.minutes <= timeFrom.minutes)
                );
            },
        )
        .required(),
    to: Yup.string().required(),
});

export const schema = Yup.object({
    enabled: Yup.boolean().required(),
    timezone: Yup.string().required(),
    scheduleType: Yup.string<ScheduleTypes>().required(),
    schedule: Yup.object(generateWeek(daySchema)),
    message: Yup.string().required(),
});
