import { SxProps } from '@mui/material';

export const container = {
    display: 'grid',
    width: 1,
    gridTemplateRows: '1fr auto',
};
export const historyHolder: SxProps = {
    display: 'flex',
    overflow: 'hidden',
    height: 1,
    width: 1,
};
