import {
    Avatar,
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import { colors } from '../../../theme/palette';
import { section } from '../styles';
import { getInitials } from '../../../main/campaign/v3/string.helpers';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';
import avatarPlaceholder from '../../../icons/common/avatarPlaceholder.svg';
import { getContactName } from '../../../utils/contacts';
import { NavLink } from 'react-router-dom';
import { useMeQueryData } from '../../../queries/user';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { useContacts } from '../../../components/Contacts/contacts.queries';
import isEmpty from 'lodash/isEmpty';
import { ListSkeleton } from './ListSkeleton';
import { useTrack } from '../../../contexts/analytics';

type Props = { query: string };

export const ContactsList = ({ query = '' }: Props) => {
    const track = useTrack();
    const country = useMeQueryData()?.activeTeam.countryCode || 'US';
    const {
        data: { pages: [{ data: contacts }] } = { pages: [{ data: [] }] },
        isLoading,
    } = useContacts({
        query,
        limit: 3,
    });

    if (!isLoading && isEmpty(contacts)) {
        return null;
    }

    return (
        <Box sx={section}>
            <Typography
                variant="body4"
                sx={{ color: colors.text.secondary, px: 3 }}
            >
                Contacts
            </Typography>
            {isLoading && <ListSkeleton />}
            {contacts?.map((contact) => {
                const name = getContactName(contact) || '';
                const initials = getInitials(name);

                return (
                    <MenuItem
                        key={contact.id}
                        component={NavLink}
                        to={`/contacts/book/${contact.id}`}
                        onClick={() => {
                            track('global_search_navigation', {
                                action: 'visit',
                                type: 'contact',
                                queryLength: query.length,
                            });
                        }}
                    >
                        <ListItemIcon>
                            <Avatar
                                sx={{
                                    background: getAvatarGradient(name),
                                    width: 20,
                                    height: 20,
                                    fontSize: 10,
                                }}
                                alt={name}
                            >
                                {(contact.avatarURL || !initials) && (
                                    <Box
                                        src={
                                            contact.avatarURL ||
                                            avatarPlaceholder
                                        }
                                        sx={{ width: 20, height: 20 }}
                                        component="img"
                                        alt="avatar"
                                    />
                                )}
                                {!contact.avatarURL && initials}
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText sx={{ width: 1 }}>
                            <Stack
                                width={1}
                                direction="row"
                                gap={2}
                                justifyContent="space-between"
                            >
                                <div>{getContactName(contact, country)}</div>
                                {contact.name ? (
                                    <div>
                                        {formatPhoneNumber(
                                            contact.phone,
                                            country,
                                        )}
                                    </div>
                                ) : null}
                            </Stack>
                        </ListItemText>
                    </MenuItem>
                );
            })}
        </Box>
    );
};
