import { Outlet } from 'react-router';
import { SetupMicrosoftTeamsInboxProvider } from './setup-teams-phone-context';
import { Banner } from '../../../components/Banner/Banner';
import { Box, Link, Typography } from '@mui/material';

export const MSTeamsPhoneNumbers = () => {
    return (
        <Box
            sx={{
                overflow: 'auto',
            }}
        >
            <Banner color="warning.light">
                <Typography variant="body4" color="primary.dark">
                    Need help with SMS activation? Immediate assistance:{' '}
                    <Link href="tel:+14159001214">+1 (415) 900-1214</Link>
                </Typography>
            </Banner>
            <SetupMicrosoftTeamsInboxProvider>
                <Outlet />
            </SetupMicrosoftTeamsInboxProvider>
        </Box>
    );
};
