import {
    InputAdornment,
    inputBaseClasses,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { SearchIcon } from '../../icons/common/SearchIcon';

export function SearchTextField(
    props: Omit<TextFieldProps, 'variant'> & {
        variant?: TextFieldProps['variant'];
    },
) {
    return (
        <TextField
            {...props}
            size="small"
            name="search"
            placeholder={props.placeholder ?? 'Search'}
            sx={{
                width: props.fullWidth ? undefined : 256,
                [`& .${inputBaseClasses.input}`]: {
                    padding: (theme) => theme.spacing(1.5, 3, 1.5, 0),
                },
                '& .MuiInputAdornment-root': {
                    marginRight: 0,
                },
                ...props.sx,
            }}
            InputProps={{
                ...props.InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}
