import { useCallback, useEffect, memo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { AttachmentIcon } from '../../../../../icons/common/AttachmentIcon';
import * as styles from './styles';
import { CustomEditor } from '../../../slate';
import { acceptFileFormats } from '../../../../../constants';

type Props = {
    disabled?: boolean;
    uploadRef: React.RefObject<HTMLInputElement>;
    uploadFile: (file: File) => void;
    editor: CustomEditor;
};

export const FileUploaderButton = memo(function FileUploaderButton({
    disabled,
    uploadFile,
    uploadRef,
    editor,
}: Props) {
    const onFileUpload = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            if (target && target.files) {
                Array.from(target.files).forEach((file) => {
                    uploadFile(file);
                });
            }
            if (target.value) {
                // @ts-ignore reset input to prevent stuck
                target.value = null;
            }
        },
        [uploadFile],
    );

    const handleSelectFile = useCallback(() => {
        if (disabled) {
            return;
        }

        if (uploadRef.current) {
            uploadRef.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    useEffect(() => {
        const { insertData } = editor;
        editor.insertData = (data) => {
            if (data?.files.length) {
                Array.from(data.files).forEach((file: File) => {
                    uploadFile(file);
                });
                return;
            }

            insertData(data);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box
                component="input"
                sx={styles.fileInput}
                ref={uploadRef}
                type="file"
                accept={acceptFileFormats}
                multiple
                onChange={onFileUpload}
            />
            <Tooltip title="Attach file" arrow placement="top">
                <span>
                    <IconButton
                        color="primary"
                        onClick={handleSelectFile}
                        disabled={disabled}
                        data-testid="upload-file-button"
                    >
                        <AttachmentIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
});
