import { Box, IconButton, Paper, Typography } from '@mui/material';
import { IconOuterButton } from '../IconOuterButton/IconOuterButton';
import { ArrowDownwardFilled } from '../../icons/common/CArrowDownwardFilled';
import { ArrowUpwardFilled } from '../../icons/common/CArrowUpwardFilled';
import { ShiftFilled } from '../../icons/common/CShiftFilled';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { colors } from '../../theme/palette';
import { useLocalStorage } from '@uidotdev/usehooks';

export const Legend = () => {
    const [isHidden, setIsHidden] = useLocalStorage(
        'hideInboxNavigationLegend',
        false,
    );

    return (
        !isHidden && (
            <Paper
                sx={{
                    display: 'flex',
                    px: 3,
                    py: 1.5,
                    gap: 8,
                    borderRadius: 0,
                    justifyContent: 'space-between',
                    borderTop: '1px solid',
                    borderTopColor: colors.primary.gray.tableDivider,
                    mt: 'auto',
                }}
                elevation={5}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <ShiftFilled sx={{ width: 34, height: 22 }} />
                        <Typography
                            variant="body4"
                            color="custom.text.secondary"
                        >
                            +
                        </Typography>
                        <IconOuterButton>
                            <ArrowDownwardFilled />
                        </IconOuterButton>
                        <IconOuterButton>
                            <ArrowUpwardFilled />
                        </IconOuterButton>
                    </Box>
                    <Typography variant="body4" color="custom.text.secondary">
                        Navigate
                    </Typography>
                </Box>
                <IconButton onClick={() => setIsHidden(true)}>
                    <CloseIcon />
                </IconButton>
            </Paper>
        )
    );
};
