import {
    Box,
    CircularProgress,
    Divider,
    Fade,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    CampaignMessage,
    CampaignMessageStatus,
    useCampaignMessageRemove,
    useCampaignMessageUpdate,
} from '../use-campaign-messages';
import { DotDivider } from '../../../../components/common/DotDivider';
import { BoxOwnProps } from '@mui/system/Box/Box';
import { getName } from '../get-profile-name';
import { ProfileAvatar } from '../ProfileAvatar';
import dayjs from 'dayjs';
import { author, body, container } from './message.styles';
import { Attachments } from '../Attachment/Attachments';
import { useMemo, useState } from 'react';
import { DeleteIcon } from '../../../../icons/common/CDeleteIcon';
import { CopyIcon } from '../../../../icons/shared/CopyIcon';
import { useTrack } from '../../../../contexts/analytics';
import { EditIcon } from '../../../../icons/common/CEditIcon';
import { ScheduledIcon } from '../../../../icons/common/ScheduledIcon';
import { getSquare } from '../../../../theme/style.helpers';
import { useNow } from '../../../../components/ConversationRow/use-now';
import { divider, popup, progressHolder } from './styles';
import { TimestampChangeDialog } from '../../../../components/Modals/TimestampChangeDialog/TimestampChangeDialog';
import { addHyperLinksToTextLinks } from '../../../../components/NewConversation/utils';
import { emptyFunction } from '../../../../helpers/function.helpers';
import { useConfirmDelete } from './use-confirm-delete';
import { useParams } from 'react-router';

type Props = BoxOwnProps & {
    message: CampaignMessage;
    onEdit?: () => void;
    onDelete?: () => void;
};

export const Message = ({
    message,
    sx,
    children,
    onDelete = emptyFunction,
    onEdit = emptyFunction,
    ...props
}: Props) => {
    const track = useTrack();
    const update = useCampaignMessageUpdate(message.id);
    const [open, setOpen] = useState<boolean>(false);
    const [confirm, Dialog] = useConfirmDelete();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const { entityId } = useParams<{ entityId?: string }>();
    const removing = useCampaignMessageRemove(message.id, entityId);

    const remove = async () => {
        if (await confirm()) {
            removing.mutateAsync().then(onDelete);
        }
    };

    const isPlanned = useMemo(
        () =>
            message.status === CampaignMessageStatus.Planned &&
            dayjs().diff(message.timestamp, 'seconds') < -60,
        [message.status, message.timestamp],
    );
    const now = useNow({
        interval: 30_000,
        disabled: !isPlanned,
    });

    return (
        <>
            <Box
                data-testid="campaign-message"
                onMouseEnter={() => setShowDialog(true)}
                onMouseLeave={() => setShowDialog(false)}
                {...props}
                sx={{ width: 1, position: 'relative', ...(sx || {}) }}
            >
                <div>
                    <Box sx={container}>
                        <Box sx={{ alignItems: 'flex-start' }}>
                            <Box sx={author} typography="body4">
                                <Typography
                                    fontWeight={500}
                                    variant="body4"
                                    data-testid="campaign-message-author"
                                >
                                    {getName(message.user)}
                                </Typography>
                                <DotDivider />
                                <div data-testid="campaign-message-time">
                                    {isPlanned ? (
                                        <>
                                            <ScheduledIcon
                                                sx={{
                                                    ...getSquare(16),
                                                    mr: 1,
                                                }}
                                            />
                                            Scheduled for{' '}
                                            {dayjs(message.timestamp).format(
                                                'h:mm A',
                                            )}
                                        </>
                                    ) : (
                                        dayjs(message.timestamp).format(
                                            'h:mm A',
                                        )
                                    )}
                                </div>
                            </Box>
                            <Box sx={body}>
                                <p data-testid="campaign-message-body">
                                    {addHyperLinksToTextLinks(message.body)}
                                </p>
                                <Attachments
                                    sx={{ mt: 1 }}
                                    attachments={message.attachments}
                                />
                            </Box>
                        </Box>
                        <ProfileAvatar profile={message.user} />
                    </Box>
                    {children}
                </div>
                <Fade in={showDialog} unmountOnExit timeout={100}>
                    <Box className="campaign-message-actions" sx={popup}>
                        {isPlanned && (
                            <>
                                {onEdit && (
                                    <Tooltip title="Edit">
                                        <IconButton
                                            data-testid="cm_pop_edit"
                                            color="primary"
                                            onClick={() => {
                                                track(
                                                    'campaign_message_edit_clicked',
                                                    { origin: 'popup' },
                                                );
                                                onEdit();
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title="Change timestamp">
                                    <span>
                                        <IconButton
                                            data-testid="cm_pop_change_ts"
                                            disabled={update.isPending}
                                            onClick={() => setOpen(true)}
                                            color="primary"
                                        >
                                            {update.isPending ? (
                                                <CircularProgress size={18} />
                                            ) : (
                                                <ScheduledIcon />
                                            )}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </>
                        )}
                        {!!message.body && (
                            <Tooltip arrow title="Copy">
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        track('campaign_message_copied');
                                        navigator.clipboard.writeText(
                                            message.body || '',
                                        );
                                    }}
                                >
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(message.body || isPlanned) && (
                            <Divider
                                flexItem
                                orientation="vertical"
                                sx={divider}
                            />
                        )}
                        <Tooltip title="Delete">
                            <span>
                                <IconButton
                                    data-testid="cm_pop_delete"
                                    disabled={removing.isPending}
                                    color="error"
                                    onClick={remove}
                                >
                                    {removing.isPending ? (
                                        <CircularProgress
                                            size={27}
                                            color="error"
                                        />
                                    ) : (
                                        <DeleteIcon />
                                    )}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Fade>
                {isPlanned && (
                    <TimestampChangeDialog
                        timestamp={message.timestamp}
                        disabled={update.isPending}
                        open={open}
                        minDate={now}
                        onClose={() => setOpen(false)}
                        onChange={(date) => {
                            update
                                .mutateAsync({
                                    timestamp: date.toString(),
                                })
                                .then(() => {
                                    setOpen(false);
                                    track(
                                        'campaign_message_timestamp_changed',
                                        {
                                            origin: 'popup_dialog',
                                        },
                                    );
                                });
                        }}
                    >
                        {update?.error?.data?.message?.[0] && (
                            <Typography variant="body2" mt={2} color="error">
                                {update?.error?.data?.message?.[0]}
                            </Typography>
                        )}
                        {update.isPending && (
                            <Box sx={progressHolder}>
                                <LinearProgress sx={{ width: 1 }} />
                            </Box>
                        )}
                    </TimestampChangeDialog>
                )}
            </Box>
            <Dialog />
        </>
    );
};
