import {
    InfiniteData,
    useInfiniteQuery,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    getScheduledConversations,
    getScheduledMessages,
    removeScheduledMessage,
    updateScheduledMessage,
} from '../api/scheduled-messages';
import {
    Conversation,
    ScheduledMessage,
    UpdateScheduledMessageDto,
} from '../api/types';
import { queryClient } from './queryClient';
import { useLocation, useNavigate } from 'react-router';

const SCHEDULED_CONVERSATIONS_LIST_KEY = 'scheduled_conversations_list';
const SCHEDULED_MESSAGES_LIST_KEY = 'scheduled_messages_list';

/** @deprecated use messages with timestamp isntead */
export function useScheduledConversationsQuery() {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteQuery({
            queryKey: [SCHEDULED_CONVERSATIONS_LIST_KEY],
            queryFn: ({ pageParam }) => getScheduledConversations(pageParam),
            initialPageParam: undefined,
            getNextPageParam: (lastPage) => {
                if (lastPage && lastPage.length > 0) {
                    return new Date(
                        lastPage[lastPage.length - 1].updated,
                    ).getTime();
                }
                return undefined;
            },
        });

    const conversations =
        data?.pages.reduce(
            (acc: Conversation[], msgs) => acc.concat(msgs),
            [],
        ) || [];

    return {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    };
}

/** @deprecated use Message with timestamp value instead */
export function useScheduledMessagesQuery(conversationId: string) {
    const { data } = useQuery({
        queryKey: [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
        queryFn: () => getScheduledMessages(conversationId),
        initialData: [],
    });

    return data || [];
}

/** @deprecated use Message with timestamp value instead */
export function useScheduledMessageUpdateQuery(conversationId: string) {
    return useMutation<
        ScheduledMessage,
        unknown,
        UpdateScheduledMessageDto,
        { prevMessages: ScheduledMessage[] }
    >({
        mutationKey: ['scheduled_message_update'],
        mutationFn: (updateDto) =>
            updateScheduledMessage(updateDto.id, updateDto),
        onMutate: async (updateDto) => {
            await queryClient.cancelQueries({
                queryKey: [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
            });

            const prevMessages =
                queryClient.getQueryData<ScheduledMessage[]>([
                    SCHEDULED_MESSAGES_LIST_KEY,
                    conversationId,
                ]) || [];

            queryClient.setQueryData<ScheduledMessage[]>(
                [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
                (messages = []) => {
                    const idx = messages.findIndex(
                        ({ id }) => id === updateDto.id,
                    );
                    const updatedMessages = messages.slice();
                    updatedMessages[idx] = {
                        ...updatedMessages[idx],
                        ...updateDto,
                    };

                    return updatedMessages;
                },
            );

            return { prevMessages };
        },
        onError: (_error, _updateDto, context) => {
            const { prevMessages } = context || { prevMessages: [] };

            queryClient.setQueryData<ScheduledMessage[]>(
                [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
                () => prevMessages,
            );
        },
    });
}

/** @deprecated use Message with timestamp value instead */
export function useScheduledMessageRemoveQuery(conversationId: string) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return useMutation<
        void,
        unknown,
        number,
        { prevMessages: ScheduledMessage[] }
    >({
        mutationKey: ['scheduled_message_delete'],
        mutationFn: removeScheduledMessage,
        onMutate: async (messageId: number) => {
            await queryClient.cancelQueries({
                queryKey: [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
            });

            const prevMessages =
                queryClient.getQueryData<ScheduledMessage[]>([
                    SCHEDULED_MESSAGES_LIST_KEY,
                    conversationId,
                ]) || [];

            queryClient.setQueryData<ScheduledMessage[]>(
                [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
                (messages = []) =>
                    messages.filter(({ id }) => id !== messageId),
            );

            return { prevMessages };
        },
        onError: (_error, _, context) => {
            const { prevMessages } = context || { prevMessages: [] };

            queryClient.setQueryData<ScheduledMessage[]>(
                [SCHEDULED_MESSAGES_LIST_KEY, conversationId],
                () => prevMessages,
            );
        },
        onSuccess: () => {
            const messages = queryClient.getQueryData<ScheduledMessage[]>([
                SCHEDULED_MESSAGES_LIST_KEY,
                conversationId,
            ]);

            // Check if certain scheduled conversation view is on screen and last scheduled message is removed
            const regex = new RegExp(`/scheduled/${conversationId}`);
            if (!messages?.length && regex.test(pathname)) {
                // Check if there is the only scheduled conversation is in the list
                const { pages = [] } =
                    queryClient.getQueryData<InfiniteData<Conversation>>([
                        SCHEDULED_CONVERSATIONS_LIST_KEY,
                    ]) || {};
                const conversations = pages.reduce<Conversation[]>(
                    (acc, msgs) => acc.concat(msgs),
                    [],
                );

                if (conversations.length > 1) {
                    navigate('/scheduled');
                } else {
                    navigate('/contacts/book');
                }
            }

            queryClient.refetchQueries({
                queryKey: [SCHEDULED_CONVERSATIONS_LIST_KEY],
            });
        },
    });
}
