import Loading from './Loading';

export const OverlayLoading = () => {
    return (
        <Loading
            sx={{
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                position: 'fixed',
            }}
        />
    );
};
