import { Message, SenderType } from '../../../api/types';
import dayjs from 'dayjs';

export const isAssistant = (senderType: SenderType): boolean =>
    [SenderType.ASSISTANT, SenderType.AI_PIPELINE].includes(senderType);

export const checkIsTooLate = (time: dayjs.ConfigType): boolean =>
    dayjs(time).diff(new Date(), 'years') > 1;

export const checkIsUntilReview = (
    message: Pick<Message, 'senderType' | 'timestamp'>,
) => checkIsTooLate(message.timestamp) && isAssistant(message.senderType);
