import { RoleTypes } from '../../../api/types';

type RoleOption = { id: string; label: string; description: string };

export const roleLabels: Record<RoleTypes, string> = {
    [RoleTypes.Admin]: 'Admin',
    [RoleTypes.Member]: 'Member',
    [RoleTypes.Agent]: 'Agent',
    [RoleTypes.Manager]: 'Manager',
    [RoleTypes.WorkspaceOwner]: 'Workspace Owner',
};

export const rolesOptions: RoleOption[] = [
    {
        id: RoleTypes.WorkspaceOwner,
        label: 'Workspace Owner',
        description:
            'Can add and remove members from the organization, and set inbox permissions. Has the ability to update payments and billing.',
    },
    {
        id: RoleTypes.Admin,
        label: 'Admin',
        description:
            'Has all the permissions as Workspace Owner, but CANNOT view or send messages.',
    },
    {
        id: RoleTypes.Manager,
        label: 'Manager',
        description:
            'Can add members but cannot remove them. Can set viewing permissions per inbox.',
    },
    {
        id: RoleTypes.Agent,
        label: 'Agent',
        description:
            'Can add members but not remove them. Can set viewing permissions for the inboxes they belong to.',
    },
    {
        id: RoleTypes.Member,
        label: 'Member',
        description:
            'Can send and receive message in the inbox(s) they belong to.',
    },
];
