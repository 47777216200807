import { Avatar, AvatarProps, Theme } from '@mui/material';
import { Profile } from '../../../api/types';
import { getName } from './get-profile-name';
import { useMemo } from 'react';
import { getInitials } from './string.helpers';
import avatarPlaceholder from '../../../icons/common/avatarPlaceholder.svg';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';

const getPublicFileUrl = (id: string): string => {
    const url = new URL(import.meta.env.VITE_API_BASE_URL);
    url.pathname = `/files/${id}`;
    return url.toString();
};

type Props = { profile: Profile } & AvatarProps<Theme>;

export const ProfileAvatar = ({
    profile,
    size = 40,
    sx = {},
    ...props
}: Props) => {
    const name = useMemo(
        () => getName(profile),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profile.firstname, profile.lastname, profile.email],
    );
    const initials = getInitials(name);

    const src =
        props.src ||
        (profile.avatarId && getPublicFileUrl(profile.avatarId)) ||
        (!initials && avatarPlaceholder) ||
        undefined;
    const gradient = getAvatarGradient(profile.id);

    return (
        <Avatar
            {...props}
            size={size}
            sx={{ ...sx, background: gradient }}
            src={src}
            alt={props.alt || getName(profile)}
        >
            {props.children || initials}
        </Avatar>
    );
};
