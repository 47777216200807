import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Divider,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    useTeamCreate,
    useUpdateTeam,
    useUpdateTeamAvatar,
} from '../../../queries/team';
import { useMeQueryData, useTeammates } from '../../../queries/user';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import AvatarControl from '../../../components/AvatarControl/AvatarControl';
import { ClerkPermission } from '../../../api/types';
import { WithPermission } from '../../../containers/WithPermission/WithPermission';
import { useMutation } from '@tanstack/react-query';
import { deleteTeam } from '../../../api/team';
import { queryClient } from '../../../queries/queryClient';
import { useNavigate } from 'react-router';
import { useTrack } from '../../../contexts/analytics';
import { useConfirmDelete } from '../../../hooks/useConfirmDelete';
import {
    ToastActions,
    useToastContext,
} from '../../../containers/toast/reducer';
import { AxiosError } from 'axios';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';
import { pluralize } from '../../../utils/pluralize';
import { errorToToast } from '../../../components/MessageFormContainers/utils';
import { TeamWhitelistDomains } from './TeamWhitelistDomains';
import { ShowForPaidTeam } from '../../../guards/ShowForPaidTeam';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';

type Inputs = {
    name: string;
    contactsPublicByDefault: boolean;
};

export const TeamInfo = () => {
    const currentUser = useMeQueryData();

    const teammates = useTeammates();
    const { dispatch: toastDispatch } = useToastContext();
    const navigate = useNavigate();
    const track = useTrack();
    const { mutate: updateTeam, isPending } = useUpdateTeam();
    const updateTeamAvatar = useUpdateTeamAvatar();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<Inputs>({
        defaultValues: {
            name: currentUser?.activeTeam.name ?? '',
            contactsPublicByDefault:
                currentUser?.activeTeam.contactsPublicByDefault ?? true,
        },
    });

    const [toggleDeleteTeamModal, DeleteTeamModal] = useConfirmDelete({
        title: 'Delete Team',
        subTitle: `Are you sure you want to delete the team? This will prevent ${pluralize(teammates.data?.length || 0, 'member', 'members')} from accessing the team and inboxes`,
    });

    const { mutateAsync: createTeam, isPending: createNewTeamLoading } =
        useTeamCreate();

    const { mutateAsync: removeTeam } = useMutation<
        undefined,
        AxiosError<{ message: string }>
    >({
        mutationFn: deleteTeam,
        mutationKey: ['deleteTeam'],
        onSuccess: () => {
            navigate('/');
            queryClient.resetQueries();
        },
        onError: (e) => {
            toastDispatch({
                type: ToastActions.ADD,
                payload: errorToToast(e),
            });
        },
    });

    const onSubmit = (data: Inputs) => {
        updateTeam(
            {
                name: data.name,
                contactsPublicByDefault: data.contactsPublicByDefault,
            },
            {
                onSuccess: () => {
                    toastDispatch({
                        type: ToastActions.ADD,
                        payload: {
                            severity: 'success',
                            title: 'Team info',
                            description:
                                'The team information has been successfully updated',
                        },
                    });
                },
            },
        );
    };

    const onReset = () => {
        reset();
    };

    return (
        <SettingsLayout
            title="Team info"
            category="Workspace"
            description="All of the information about your company"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                    <Stack spacing={4}>
                        <Typography variant="body2">Company profile</Typography>
                        <AvatarControl
                            title="Company avatar"
                            imageUrl={currentUser?.activeTeam?.teamAvatarURL}
                            uploadImageMutation={updateTeamAvatar}
                            uploadImageErrorMessage="There was an error while uploading your avatar"
                        />
                        <Divider />

                        <Stack
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            direction={{ xs: 'column', sm: 'row' }}
                        >
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                fontWeight={500}
                            >
                                Workspace name
                            </Typography>

                            <TextField
                                {...register('name', { required: true })}
                                error={!!errors.name}
                                helperText={
                                    !!errors.name && 'This field is required'
                                }
                                fullWidth
                                sx={{ maxWidth: { xs: 'auto', sm: 264 } }}
                            />
                        </Stack>
                        <Divider />
                    </Stack>

                    <Stack spacing={4} py={6}>
                        <div>
                            <Typography variant="body2">
                                Workspace settings
                            </Typography>
                            <Typography
                                component="p"
                                variant="body4"
                                color="custom.gray.super"
                                mb={4}
                            >
                                Only team admins can change the global settings.
                                Contact your administrator if you need to make
                                changes.
                            </Typography>
                        </div>

                        <Stack
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            direction={{ xs: 'column', sm: 'row' }}
                        >
                            <div>
                                <Typography
                                    variant="body3"
                                    color="primary.dark"
                                    fontWeight={500}
                                >
                                    Contacts
                                </Typography>
                                <Typography
                                    component="p"
                                    variant="body4"
                                    color="custom.gray.super"
                                >
                                    Choose whether new contacts are set to
                                    private or public to the team
                                </Typography>
                            </div>

                            <Controller
                                control={control}
                                name="contactsPublicByDefault"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        error={!!errors.contactsPublicByDefault}
                                        onChange={onChange}
                                        value={value}
                                        name="contacts"
                                        select
                                        fullWidth
                                        sx={{
                                            maxWidth: { xs: 'auto', sm: 264 },
                                        }}
                                    >
                                        <MenuItem value={true}>Team</MenuItem>
                                        <MenuItem value={false}>
                                            Private
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                        </Stack>
                    </Stack>

                    <Divider />

                    <TeamWhitelistDomains />

                    <Stack
                        spacing={4}
                        direction={{
                            xs: 'column',
                            sm: 'row',
                        }}
                        justifyContent="space-between"
                        flex={1}
                        useFlexGap
                    >
                        <Stack
                            direction="row"
                            spacing={4}
                            pt={6}
                            pb={{ xs: 2 }}
                        >
                            <Button
                                sx={(theme) => ({
                                    width: 152,
                                    mr: 3,
                                    [theme.breakpoints.down('sm')]: {
                                        flex: 1,
                                    },
                                })}
                                type="submit"
                                color="info"
                            >
                                {isPending ? (
                                    <ButtonDotLoader />
                                ) : (
                                    'Save changes'
                                )}
                            </Button>
                            <Button
                                sx={(theme) => ({
                                    width: 96,
                                    [theme.breakpoints.down('sm')]: {
                                        flex: 1,
                                    },
                                })}
                                variant="text"
                                onClick={onReset}
                            >
                                Cancel
                            </Button>
                        </Stack>

                        <Stack
                            direction="row"
                            spacing={4}
                            pt={6}
                            sx={(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                    borderTop: '1px solid',
                                    borderTopColor: 'custom.gray.divider',
                                },
                            })}
                        >
                            <ShowForPaidTeam>
                                <WithPermission
                                    requiredPermissions={[
                                        ClerkPermission.CreateTeam,
                                    ]}
                                >
                                    <Button
                                        variant="outlined"
                                        disabled={createNewTeamLoading}
                                        onClick={() => {
                                            track('clicked_create_new_team');
                                            createTeam()
                                                .then(() => {
                                                    navigate('/');
                                                })
                                                .catch((e) =>
                                                    toastDispatch({
                                                        type: ToastActions.ADD,
                                                        payload:
                                                            errorToToast(e),
                                                    }),
                                                );
                                        }}
                                        sx={(theme) => ({
                                            whiteSpace: 'nowrap',
                                            [theme.breakpoints.down('sm')]: {
                                                flex: 1,
                                            },
                                        })}
                                    >
                                        Create New Team
                                    </Button>
                                </WithPermission>
                            </ShowForPaidTeam>
                            <WithPermission
                                requiredPermissions={[
                                    ClerkPermission.DeleteTeam,
                                ]}
                            >
                                <Button
                                    variant="outlined"
                                    disabled={createNewTeamLoading}
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                        track('clicked_delete_team');
                                        toggleDeleteTeamModal()
                                            .then(async (result) => {
                                                if (result) {
                                                    await removeTeam();
                                                }
                                            })
                                            .catch(() => undefined);
                                    }}
                                    sx={(theme) => ({
                                        whiteSpace: 'nowrap',
                                        [theme.breakpoints.down('sm')]: {
                                            flex: 1,
                                        },
                                    })}
                                >
                                    Delete Team
                                </Button>
                                {DeleteTeamModal()}
                            </WithPermission>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </SettingsLayout>
    );
};
