import * as styles from '../SettingsLayout/styles';
import { useBetweenMdLg } from '../../hooks/useBetweenMdLg';
import { useSidebar } from '../../contexts/sidebar';
import { Box, Drawer } from '@mui/material';
import { useIsDownMd } from '../../hooks/useIsDownMd';
import { useIs10DLCRequired } from '../../main/settings/Compliance/useIs10DLCRequired';
import { BANNER_HEIGHT } from '../../components/Banner/Banner';

const MOBILE_BAR_HEIGHT = 60;

export function SidebarDrawer({ children }: { children: React.ReactNode }) {
    const isNarrowScreen = useBetweenMdLg();
    const { isCollapsed, isOpened } = useSidebar();
    const isMobile = useIsDownMd();
    const is10DLCRequired = useIs10DLCRequired();
    const mobileOffsetTop = is10DLCRequired
        ? MOBILE_BAR_HEIGHT + BANNER_HEIGHT
        : MOBILE_BAR_HEIGHT;

    if (isMobile) {
        return (
            <Box
                sx={(theme) => ({
                    width: 1,
                    height: is10DLCRequired
                        ? `calc(100% - ${mobileOffsetTop}px)`
                        : 1,
                    maxHeight: `calc(100vh - ${mobileOffsetTop}px)`,
                    top: mobileOffsetTop,
                    background: theme.palette.background.paper,
                    overflowWrap: 'anywhere',
                    position: 'absolute',
                    zIndex: theme.zIndex.drawer,
                    display: isOpened ? 'block' : 'none',
                })}
            >
                {children}
            </Box>
        );
    }

    return (
        <Drawer
            open={!isMobile || isOpened}
            variant={
                isNarrowScreen && !isCollapsed ? 'persistent' : 'permanent'
            }
            PaperProps={{
                sx: [
                    styles.sidebar,
                    {
                        height: is10DLCRequired
                            ? `calc(100% - ${BANNER_HEIGHT}px)`
                            : 1,
                        top: is10DLCRequired ? BANNER_HEIGHT : 0,
                    },
                    isCollapsed && styles.collapsedSidebar,
                ],
                elevation: isNarrowScreen && !isCollapsed ? 5 : 0,
            }}
            sx={{
                width: [1, isCollapsed ? 72 : 248],
            }}
            anchor="left"
        >
            {children}
        </Drawer>
    );
}
