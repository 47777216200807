import { contextMenuWrapper, contextMenuWrapperHidden } from './styles';
import { Box, IconButton } from '@mui/material';
import { MoreHorizFilledIcon } from '../../icons/common/MoreHorizFilled';
import { MenuProps } from '../../hooks/use-menu';
import { Inbox } from '../../api/types';

type Props = {
    inboxId: Inbox['id'];
    menu: MenuProps;
};

export function ContextMenuButton({ inboxId, menu }: Props) {
    return (
        <Box
            className="context-menu-button"
            sx={menu.open ? contextMenuWrapper : contextMenuWrapperHidden}
        >
            <IconButton
                id={`inbox-context-menu-button-${inboxId}`}
                aria-controls={
                    menu.open ? `inbox-context-menu-${inboxId}` : undefined
                }
                aria-expanded={menu.open ? 'true' : undefined}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    menu.handleOpen(e);
                }}
                aria-haspopup="true"
                color="default"
                size="small"
            >
                <MoreHorizFilledIcon />
            </IconButton>
        </Box>
    );
}
