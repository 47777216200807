import { client } from './http';
import { Cohort } from './types';
import { UUID } from '../types/uuid';
import { SlimFilter } from '../components/Contacts/contacts.queries';

export interface CohortMetaDto {
    id: number;
    name: string;
    isPublic: boolean;
    updated: string;
    contactsCount: number;
}

interface CohortModifyContactsDto {
    contactIds: UUID[];
    cohortIds: number[];
}

interface CohortModifyContactsByFiltersDto {
    cohortId: number;
    payload: {
        excluded: UUID[];
        query: string;
        filters: SlimFilter[];
        cohortId?: number;
    };
}

export const getCohort = (id: number) =>
    client.get<Cohort>(`/cohorts/${id}`).then(({ data }) => data);

export function addContactsToCohorts(dto: CohortModifyContactsDto) {
    return client.post('/cohorts/add-contacts', dto);
}

export function addContactsToCohortsByFilters({
    cohortId,
    payload,
}: CohortModifyContactsByFiltersDto) {
    return client.post(`/cohorts/${cohortId}/contacts/add-by-filters`, payload);
}

export function removeContactsFromCohorts(dto: CohortModifyContactsDto) {
    return client.post('/cohorts/remove-contacts', dto);
}

export function removeContactsFromCohortsByFilters({
    cohortId,
    payload,
}: CohortModifyContactsByFiltersDto) {
    return client.delete(`/cohorts/${cohortId}/contacts/remove-by-filters`, {
        data: payload,
    });
}

export function getContactCohorts({ contactId }: { contactId: UUID }) {
    return client.get(`/cohorts/for-contact/${contactId}`);
}
