import closeIcon from '../../../../assets/icons/icons-24/gray/close.svg';
import styles from '../WorkflowStepConfigurator.module.scss';
import filterStyles from './Condition.module.scss';
import {
    FilterCondition,
    FilterOption,
    WorkflowCondition,
    WorkflowConditionTypes,
} from '../../types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import { OptionBase } from '../../../../elements/AdvancedSelect';
import { useCohortsQuery } from '../../../../queries/cohorts';
import { Condition } from './Condition';
import Button from '../../../../elements/Buttons';
import Input from '../../../../elements/Inputs/Input';
import { Icon } from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';
import cloneDeep from 'lodash/cloneDeep';

interface FilterStepProps {
    step: WorkflowCondition;
}

const validateStep = (x: Partial<FilterCondition>): boolean => {
    if (isEmpty(x.operator) || isEmpty(x.option)) {
        return false;
    }

    if (x.option === FilterOption.Cohort && isNil(x.cohortId)) {
        return false;
    }

    if (x.option === FilterOption.Body && isEmpty(x.text)) {
        return false;
    }
    return true;
};

export const FilterStep = (props: FilterStepProps) => {
    const [conditionalStep, setStep] = useState(cloneDeep(props.step));
    const { dispatch } = useWorkflowContext();
    const cohorts = useCohortsQuery();
    const cohortOptions = useMemo(
        (): OptionBase[] =>
            cohorts.map((c) => ({ id: c.id.toString(), label: c.name })),
        [cohorts],
    );
    const [valid, setIsValid] = useState<boolean>(false);

    const [filterConditions, setFilterState] = useState<
        Partial<FilterCondition>[]
    >(conditionalStep.filterSettings?.filters || []);

    useEffect(() => {
        for (const filter of filterConditions) {
            if (!validateStep(filter)) {
                setIsValid(false);
                return;
            }
            setIsValid(true);
        }
    }, [filterConditions]);

    const onSaveHandler = () => {
        if (filterConditions) {
            dispatch({
                type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
                payload: {
                    ...conditionalStep,
                    kind: WorkflowConditionTypes.Filter,
                    filterSettings: {
                        filters: filterConditions,
                    },
                },
            });
        }
    };

    const stateUpdater = (
        filterCondition: Partial<FilterCondition>,
        index: number,
    ) => {
        setFilterState((state) => {
            const newStep = {
                ...(state[index] || {}),
                ...filterCondition,
            };
            const stateCopy = cloneDeep(state);
            stateCopy.splice(index, 1, newStep);
            return stateCopy;
        });
    };

    const deleteCondition = (index: number) => {
        setFilterState((state) => {
            const stateCopy = cloneDeep(state);
            stateCopy.splice(index, 1);
            return stateCopy;
        });
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[props.step.kind]} />
                    <span>Filter</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <img src={closeIcon} />
                </button>
            </header>
            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <Input
                        placeholder="Enter name (optional)"
                        value={conditionalStep?.name || ''}
                        onChange={(name) =>
                            setStep({ ...conditionalStep, name })
                        }
                        uncontrolled
                        fullWidth
                    />
                </section>
                {filterConditions.length < 1 ? (
                    <Condition
                        index={0}
                        filterCondition={{ condition: 'AND' }}
                        onChange={stateUpdater}
                        cohortOptions={cohortOptions}
                        deleteCondition={deleteCondition}
                    />
                ) : (
                    filterConditions.map((c, i) => (
                        <Condition
                            key={`${i}_${c.operator}_${c.condition}_${c.cohortId}`}
                            index={i}
                            onChange={stateUpdater}
                            cohortOptions={cohortOptions}
                            filterCondition={c}
                            deleteCondition={deleteCondition}
                        />
                    ))
                )}
                <div className={filterStyles.add_condition_btn}>
                    <Button
                        type="text"
                        onClick={() =>
                            stateUpdater(
                                { condition: 'AND' },
                                filterConditions.length + 1,
                            )
                        }
                    >
                        Add &quot;AND&quot; rule
                    </Button>
                    <Button
                        type="text"
                        onClick={() =>
                            stateUpdater(
                                { condition: 'OR' },
                                filterConditions.length + 1,
                            )
                        }
                    >
                        Add &quot;OR&quot; rule
                    </Button>
                </div>
            </div>
            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} disabled={!valid} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};
