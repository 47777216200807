import React, { useCallback, useEffect } from 'react';
import {
    Navigate,
    Route,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router';
import { useEnabledFeature, useMeQuery } from './queries/user';
import { Main } from './main';
import LoginPage from './pages/auth/LoginPage';
import * as storage from './utils/local-storage';
import { parseJwt, set } from './utils/local-storage';
import { Feature, RoleTypes } from './api/types';
import PaymentFlow from './containers/PaymentFlow';
import PhoneVerification from './containers/PhoneVerification';
import CsvImportWizard from './main/contacts-import/CsvImportWizard';
import { Campaign } from './main/campaign/Campaign';
import { Scheduled } from './main/scheduled/Scheduled';
import { IntegrationPropertyMap } from './containers/IntegrationPropertyMap/IntegrationPropertyMap';
import Inbox from './main/inbox';
import WorkflowsContainerProps from './main/workflows/index';
import InboxLoader from './containers/InboxLoader';
import { NotFound } from './not-found';
import PaymentPlans from './containers/PaymentFlow/PaymentPlans';
import BillingInfo from './containers/PaymentFlow/BillingInfo';
import PaymentFinish from './containers/PaymentFlow/PaymentFinish';
import { CustomerVerificationStep } from './containers/PhoneVerification/CustomerVerificationStep';
import { PhoneCallVerificationStep } from './containers/PhoneVerification/PhoneCallVerificationStep';
import { AddressVerificationStep } from './containers/PhoneVerification/AddressVerificationStep';
import { PhoneTypeVerificationStep } from './containers/PhoneVerification/PhoneTypeVerificationStep';
import { PhoneConfigurationStep } from './containers/PhoneVerification/PhoneConfigurationStep';
import { SignatureVerificationStep } from './containers/PhoneVerification/SignatureVerificationStep';
import TeamInfo from './main/settings/TeamInfo';
import { KnowledgeBase } from './main/settings/KnowledgeBase/KnowledgeBase';
import Members from './main/settings/Members';
import Integrations from './main/settings/Integrations';
import IntegrationSettings from './containers/IntegrationSettings';
import ExportLogs from './main/settings/ExportLogs';
import { Billing } from './main/settings/Billing/Billing';
import { Compliance } from './main/settings/Compliance/Compliance.lazy';
import Api from './main/settings/Api';
import Profanity from './main/settings/Profanity';
import Profile from './main/settings/Profile';
import { Widget } from './main/settings/Widget/Widget';
import { useOnlyGuestGuard } from './useOnlyGuestGuard';
import { NotificationSettings } from './main/settings/Notifications/NotificationSettings';
import { Reseller } from './containers/PaymentFlow/Reseller/Reseller';
import { queryClient } from './queries/queryClient';
import { Routes, useSearchParams } from 'react-router-dom';
import { TermsOfService } from './containers/TermsOfService/TermsOfService';
import { OnboardingProgress } from './components/OnboardingProgress/OnboardingProgress';
import AddInbox from './containers/AddInbox';
import { PhoneConnect } from './containers/AddInbox/CarrierConnect/PhoneConnect';
import PhoneNumberType from './containers/AddInbox/PhoneNumberType';
import ExistingPhoneNumber from './containers/AddInbox/ExistingPhoneNumber';
import MSTeamsPhoneNumbers from './containers/AddInbox/MSTeamsPhoneNumbers';
import { MSTeamsAuthStep } from './containers/AddInbox/MSTeamsPhoneNumbers/AuthStep';
import { MSTeamsInformationStep } from './containers/AddInbox/MSTeamsPhoneNumbers/InformationStep';
import { MSTeamsPhoneSelectionStep } from './containers/AddInbox/MSTeamsPhoneNumbers/PhoneSelectionStep';
import { ActivationDate } from './containers/AddInbox/MSTeamsPhoneNumbers/ActivationDate';
import { ConfirmStep } from './containers/AddInbox/MSTeamsPhoneNumbers/ConfirmStep';
import CarrierConnect from './containers/AddInbox/CarrierConnect';
import CarrierPhoneNumber from './containers/AddInbox/CarrierPhoneNumber';
import { BlockListPage } from './main/settings/BlockList/Page/BlockListPage';
import { Brand } from './main/settings/Brand/Brand.lazy';
import { OverlayLoading } from './components/Loading/OverlayLoading';
import * as Sentry from '@sentry/react';
import { ZoomAuth } from './pages/Zoom/Zoom.lazy';
import { useAnalyticsChannelSetup } from './services/analytics';
import { Webex } from './pages/Webex/Webex.lazy';
import { useVitePreloadErrorHandler } from './hooks/useVitePreloadErrorHandler';
import { WebexAuthStep } from './containers/AddInbox/WebexPhoneNumbers/AuthStep';
import { WebexPhoneNumbers } from './containers/AddInbox/WebexPhoneNumbers/PhoneNumbers';
import { WebexPhoneSelectionStep } from './containers/AddInbox/WebexPhoneNumbers/PhoneSelectionStep';
import { SidebarProvider } from './contexts/sidebar';

const Settings = React.lazy(() => import('./main/settings/Settings'));
const ContactsPage = React.lazy(
    () => import('./pages/NextContacts/ContactsPage'),
);
const CohortsPage = React.lazy(() => import('./pages/Cohorts/CohortsPage'));
const MessageAnalytics = React.lazy(
    () => import('./main/analytics/MessageAnalytics'),
);
const CallingAnalytics = React.lazy(
    () => import('./main/analytics/CallingAnalytics'),
);
const AiWorkflows = React.lazy(() => import('./pages/AiWorkflows/AiWorkflows'));
const AiWorkflow = React.lazy(() => import('./pages/AiWorkflows/AiWorkflow'));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
    useOnlyGuestGuard();
    useVitePreloadErrorHandler();
    useAnalyticsChannelSetup();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const pathParams = useParams();
    const navigate = useNavigate();
    const { status, data: me, refetch, error, isError } = useMeQuery();
    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);
    const isCallingAnalyticsEnabled = useEnabledFeature(
        Feature.CallingStatistics,
    );
    const isBrandWidgetEnabled = useEnabledFeature(
        Feature.BrandOptInIntegration,
    );
    const canManageBilling = useEnabledFeature(Feature.ManageBilling);

    useEffect(() => {
        if (
            isError &&
            storage.get('auth') &&
            error?.response?.data?.statusCode === 401
        ) {
            queryClient.resetQueries();
            queryClient.clear();
            storage.clear('auth');
            storage.clear('teamId');
            if (
                !location.pathname.startsWith('/login') ||
                !location.pathname.startsWith('/signup')
            ) {
                setTimeout(() => {
                    navigate('/login');
                }, 1001);
            }
        }
    }, [
        error?.response?.data?.statusCode,
        isError,
        location.pathname,
        navigate,
    ]);

    const checkAuth = useCallback(() => {
        const msUserId = searchParams.get('msUserId');
        const msTenantId = searchParams.get('tid');
        const authKey = searchParams.get('k');

        if (msUserId) {
            set('MS_TEAMS_USER_ID', msUserId);
        }

        if (msTenantId) {
            set('MS_TENET_ID', msTenantId);
        }
        if (authKey) {
            set('auth', authKey);
            const { teamId } = parseJwt(authKey);
            set('teamId', teamId);
            refetch();
        }
        if (
            status === 'error' &&
            !location.pathname.match('/login') &&
            !location.pathname.match('/zoom-app') &&
            !location.pathname.match('/webex') &&
            !location.pathname.match('/signup') &&
            !location.pathname.match('/forgot')
        ) {
            navigate('/signup');
            queryClient.resetQueries();
            queryClient.clear();
        }

        if (status === 'success') {
            if (!me?.id) {
                if (!location.pathname.startsWith('/login')) {
                    queryClient.resetQueries();
                    queryClient.clear();
                    navigate('/login');
                }
            } else if (
                !me?.acceptedTermsOfService &&
                me?.activeRole?.type === RoleTypes.WorkspaceOwner
            ) {
                const url = new URL('http://any.com');
                url.pathname = '/setup/terms-of-service';
                if (pathParams.next) {
                    url.searchParams.append('next', pathParams.next);
                }
                navigate(url.href.substring(url.origin.length));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, me, pathParams, searchParams]);

    useEffect(() => {
        checkAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, status]);

    useEffect(() => {
        Sentry.setUser({ email: me?.email, teamId: me?.activeTeam?.id });
    }, [me]);

    if (['idle', 'pending'].includes(status)) {
        return <OverlayLoading />;
    }

    return (
        <SidebarProvider>
            <SentryRoutes>
                <Route
                    path="/zoom-app"
                    element={
                        <React.Suspense fallback={<OverlayLoading />}>
                            <ZoomAuth />
                        </React.Suspense>
                    }
                    errorElement={<NotFound />}
                />
                <Route
                    path="/webex"
                    element={
                        <React.Suspense fallback={<OverlayLoading />}>
                            <Webex />
                        </React.Suspense>
                    }
                    errorElement={<NotFound />}
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<LoginPage />} />
                <Route
                    path="/setup/terms-of-service"
                    element={<TermsOfService />}
                />

                <Route path="/" element={<Main />}>
                    {/* New number setup flow */}
                    <Route path="/setup-inbox/:step?" element={<AddInbox />}>
                        <Route
                            index
                            path="phone-connect"
                            element={<PhoneConnect />}
                        />
                        <Route
                            index
                            path="number-type"
                            element={<PhoneNumberType />}
                        />

                        {/* Existing number setup flow */}
                        <Route
                            path="existing-number"
                            element={<ExistingPhoneNumber />}
                        />

                        {/* Microsoft Teams number setup flow */}

                        <Route
                            path="ms-teams/:msteamsstep?"
                            element={<MSTeamsPhoneNumbers />}
                        >
                            <Route path="auth" element={<MSTeamsAuthStep />} />
                            <Route
                                path="info"
                                element={<MSTeamsInformationStep />}
                            />
                            <Route
                                path="select"
                                element={<MSTeamsPhoneSelectionStep />}
                            />
                            <Route
                                path="activation-date"
                                element={<ActivationDate />}
                            />
                            <Route path="confirm" element={<ConfirmStep />} />
                        </Route>

                        {/* Webex number setup flow */}

                        <Route
                            path="webex/:webexstep?"
                            element={<WebexPhoneNumbers />}
                        >
                            <Route path="auth" element={<WebexAuthStep />} />
                            <Route
                                path="select"
                                element={<WebexPhoneSelectionStep />}
                            />
                        </Route>

                        {/* Twilio number setup flow */}
                        <Route
                            path="carrier-connect"
                            element={<CarrierConnect />}
                        />
                        <Route
                            path="carrier-number"
                            element={<CarrierPhoneNumber />}
                        />
                    </Route>
                    <Route index path="/" element={<InboxLoader />} />

                    <Route
                        path="/onboarding-progress"
                        element={<OnboardingProgress />}
                    />
                    <Route path="/payment/:step?" element={<PaymentFlow />}>
                        <Route
                            path="plan"
                            element={
                                canManageBilling ? (
                                    <PaymentPlans />
                                ) : (
                                    <Reseller />
                                )
                            }
                        />
                        <Route path="billing-info" element={<BillingInfo />} />
                        <Route path="finish" element={<PaymentFinish />} />
                    </Route>

                    <Route
                        path="/contacts/book/import-csv"
                        element={<CsvImportWizard />}
                    />
                    <Route
                        path="/contacts"
                        element={<Navigate replace to="/contacts/book" />}
                    />
                    <Route
                        path="/contacts/book/:id?"
                        element={
                            <React.Suspense fallback={<OverlayLoading />}>
                                <ContactsPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/contacts/cohorts"
                        element={
                            <React.Suspense fallback={<OverlayLoading />}>
                                <CohortsPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/contacts/block-list"
                        element={<BlockListPage />}
                    />
                    <Route
                        path="/analytics"
                        element={<Navigate replace to="/analytics/messages" />}
                    />
                    <Route
                        path="/analytics/messages"
                        element={
                            <React.Suspense fallback={<OverlayLoading />}>
                                <MessageAnalytics />
                            </React.Suspense>
                        }
                    />

                    <Route
                        path="/analytics/calling-statistics"
                        element={
                            isCallingAnalyticsEnabled ? (
                                <React.Suspense fallback={<OverlayLoading />}>
                                    <CallingAnalytics />
                                </React.Suspense>
                            ) : (
                                <Navigate replace to="/analytics/messages" />
                            )
                        }
                    />

                    <Route
                        path="/campaigns/:campaignId/:conversationId"
                        element={<Campaign />}
                    />
                    <Route
                        path="/inbox/:inboxId/verify/*"
                        element={<PhoneVerification />}
                    >
                        <Route
                            index
                            path="customer"
                            element={<CustomerVerificationStep />}
                        />
                        <Route
                            path="call"
                            element={<PhoneCallVerificationStep />}
                        />
                        <Route
                            path="address"
                            element={<AddressVerificationStep />}
                        />
                        <Route
                            path="phone-type"
                            element={<PhoneTypeVerificationStep />}
                        />
                        <Route
                            path="phone-configuration"
                            element={<PhoneConfigurationStep />}
                        />
                        <Route
                            path="signature"
                            element={<SignatureVerificationStep />}
                        />
                    </Route>
                    <Route
                        path="/scheduled/:conversationId?"
                        element={<Scheduled />}
                    />
                    <Route
                        path="/integration/:integrationId/settings"
                        element={<IntegrationPropertyMap />}
                    />
                    {isNewInboxEnabled ? (
                        <Route
                            path="/inbox/:inboxId/:entity?/:entityId?/:subEntity?/:subEntityId?/:query?"
                            element={<Inbox />}
                        />
                    ) : (
                        <Route
                            path="/inbox/:inboxId/:actionId?/:campaignId?/:subEntity?/:campaignMessageId?"
                            element={<Inbox />}
                        />
                    )}
                    <Route
                        path="/pipelines/"
                        element={
                            <React.Suspense fallback={<OverlayLoading />}>
                                <AiWorkflows />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/pipelines/:id"
                        element={
                            <React.Suspense fallback={<OverlayLoading />}>
                                <AiWorkflow />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/workflows/:workflowId?"
                        element={<WorkflowsContainerProps />}
                    />
                    <Route path="/*" element={<NotFound />} />
                </Route>
                <Route
                    path="/settings"
                    element={
                        <React.Suspense fallback={<OverlayLoading />}>
                            <Settings />
                        </React.Suspense>
                    }
                >
                    <Route path="/settings/team" element={<TeamInfo />} />
                    <Route
                        path="/settings/knowledge-base"
                        element={<KnowledgeBase />}
                    />
                    <Route path="/settings/members" element={<Members />} />
                    <Route
                        path="/settings/integrations"
                        element={<Integrations />}
                    />
                    <Route
                        path="/settings/integrations/:integrationId"
                        element={<IntegrationSettings />}
                    />
                    <Route path="/settings/export" element={<ExportLogs />} />
                    <Route path="/settings/billing" element={<Billing />} />
                    <Route
                        path="/settings/compliance"
                        element={
                            <React.Suspense fallback={<OverlayLoading />}>
                                {isBrandWidgetEnabled ? (
                                    <Brand />
                                ) : (
                                    <Compliance />
                                )}
                            </React.Suspense>
                        }
                    />
                    <Route path="/settings/api" element={<Api />} />
                    <Route
                        path="/settings/notifications"
                        element={<NotificationSettings />}
                    />
                    <Route path="/settings/profanity" element={<Profanity />} />
                    <Route path="/settings/widget" element={<Widget />} />
                    <Route path="profile" element={<Profile />} index />
                </Route>
            </SentryRoutes>
        </SidebarProvider>
    );
}
