import { Controller, useFormContext } from 'react-hook-form';
import { OutOfOfficeInputs, ScheduleTypes } from './constants';
import { DayForm } from './DayForm';
import { Box } from '@mui/material';
import { updateEveryDay } from './utils';

export function MultiDayForm({
    disabled = false,
    scheduleType,
}: {
    scheduleType: ScheduleTypes;
    disabled?: boolean;
}) {
    const { control } = useFormContext<OutOfOfficeInputs>();

    return (
        <Box
            alignSelf={{
                xs: 'start',
                md: 'end',
            }}
        >
            <Controller
                control={control}
                name="schedule"
                render={({ field, formState }) => {
                    const schedule =
                        field.value as OutOfOfficeInputs['schedule'];
                    const error =
                        formState.errors?.schedule?.[1]?.from?.message;

                    return (
                        <DayForm
                            error={error}
                            to={schedule[1].to}
                            from={schedule[1].from}
                            onChangeFrom={(val) => {
                                field.onChange(
                                    updateEveryDay(schedule, scheduleType, {
                                        from: val,
                                    }),
                                );
                            }}
                            onChangeTo={(val) => {
                                field.onChange(
                                    updateEveryDay(schedule, scheduleType, {
                                        to: val,
                                    }),
                                );
                            }}
                            disabled={disabled}
                        />
                    );
                }}
            />
        </Box>
    );
}
