import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import { Attachment } from '../../../../api/types';
import { Download } from '@mui/icons-material';
import { prettySize } from '../../../settings/KnowledgeBase/SourceForm/pretty-bytes';

import { MimeTypeIcon } from '../../../../icons/common/files/MimeTypeIcon';
import { useTrack } from '../../../../contexts/analytics';
import { useDownloadAttachment } from '../../../../components/MessageForm/use-attachment';
import { CustomDownload } from '../../../../icons/common/CustomDownload';

type Props = {
    attachment: Attachment;
};

const image: SxProps = {
    maxWidth: '75vw',
    maxHeight: '75vh',
    mb: 4,
    width: 'auto',
    objectFit: 'contain',
};

export function AttachmentPreview({ attachment }: Props) {
    const track = useTrack();
    const { mutate: download } = useDownloadAttachment(attachment);

    if (attachment.type && attachment.type.includes('image/')) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'normal',
                    color: 'white',
                    px: 2,
                }}
            >
                <Box
                    sx={image}
                    component="img"
                    src={attachment.url}
                    alt={attachment.name}
                />
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack>
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                lineClamp: '2' /* number of lines to show */,
                                boxOrient: 'vertical',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                                maxWidth: '50vh',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textWrap: 'wrap',
                            }}
                            variant="body2"
                        >
                            {attachment.name}
                        </Typography>
                        <Typography variant="body4">
                            {prettySize(attachment.size)}
                        </Typography>
                    </Stack>
                    <div>
                        <Button
                            onClick={() => {
                                track('attachment_downloading', {
                                    attachmentType: attachment.type,
                                });
                                download();
                            }}
                            size="small"
                            color="info"
                            variant="contained"
                            sx={{
                                mt: 1,
                                minWidth: 120,
                                height: 'auto',
                            }}
                            startIcon={<CustomDownload />}
                        >
                            Download
                        </Button>
                    </div>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: 0.9,
                maxWidth: 650,
                px: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    maxWidth: 1,
                    background: 'white',
                    px: 4,
                    py: 2,
                    borderRadius: 2,
                    gap: 2,
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <MimeTypeIcon type={attachment.type} />
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                lineClamp: '2',
                                boxOrient: 'vertical',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                                maxWidth: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textWrap: 'wrap',
                            }}
                            variant="h2"
                        >
                            {attachment.name}
                        </Typography>
                    </Box>
                    <Typography variant="body2">
                        {prettySize(attachment.size)}
                    </Typography>
                </Box>
                <div>
                    <Button
                        href={attachment.url}
                        onClick={() =>
                            track('attachment_downloading', {
                                attachmentType: attachment.type,
                            })
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 1, minWidth: 120 }}
                        startIcon={<Download />}
                        download
                    >
                        Download
                    </Button>
                </div>
            </Box>
        </Box>
    );
}
