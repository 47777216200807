import { Contact, Profile } from '../../api/types';
import { CONTACT } from '../../queries/contacts';
import { queryClient } from '../../queries/queryClient';
import { CURRENT_USER_KEY } from '../../queries/user';
import {
    NEXT_CONTACTS,
    Page,
} from '../../components/Contacts/contacts.queries';
import { CONTACT_BY_PHONE_KEY } from '../../components/Contacts/use-contact-by-phone';

export const CONTACT_UPDATED_EVENT = 'contact:updated';
export type ContactUpdateHandler = (c: Contact) => void;

export const handleContactUpdatedEvent: ContactUpdateHandler = (updated) => {
    const me = queryClient.getQueryData<Profile>([CURRENT_USER_KEY])!;
    const shouldBeUpdated = updated.public || updated.createdByUserId === me.id;

    if (shouldBeUpdated) {
        queryClient
            .getQueriesData<Contact>({
                queryKey: [CONTACT_BY_PHONE_KEY],
                exact: false,
            })
            .forEach(([queryKey, found]) => {
                if (
                    found?.phone === updated.phone &&
                    found?.id !== updated.id
                ) {
                    queryClient.invalidateQueries({ queryKey });
                }
            });
        queryClient.setQueryData<Contact>(
            [CONTACT_BY_PHONE_KEY, updated.phone],
            updated,
        );
        queryClient.setQueriesData<Page<Contact[]>>(
            {
                queryKey: [NEXT_CONTACTS],
                exact: false,
            },
            (prev) => {
                if (!prev) {
                    return prev;
                }
                return {
                    ...prev,
                    pages: prev.pages.map((page) => ({
                        ...page,
                        data: page.data.map((contact) => {
                            if (contact.id === updated.id) {
                                return {
                                    ...contact,
                                    ...updated,
                                };
                            }
                            return contact;
                        }),
                    })),
                };
            },
        );
    }

    if (shouldBeUpdated) {
        queryClient.setQueryData<Contact>([CONTACT, updated.id], (prev) => {
            if (!prev) {
                return prev;
            }
            return updated;
        });
    } else {
        queryClient.invalidateQueries({
            queryKey: [CONTACT, updated.id],
        });
    }
};
