import { CancellationReason } from '../main/settings/Billing/CancelSubscriptionModal';
import { client } from './http';

type StripeInterval = 'day' | 'month' | 'week' | 'year';

type SubscriptionStatus =
    | 'active'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'trialing'
    | 'unpaid';

export enum AddonFeatureSet {
    AnalyticsReports = 'analytics_reports',
}

export interface StripeMainSubscription {
    id: string;
    interval: StripeInterval;
    price: number;
    seats: number;
    planId: string;
    currentPeriodEnd: number;
    cancelAtPeriodEnd?: boolean;
    status: SubscriptionStatus;
}

interface UpdateSubscriptionPayload {
    subscriptionItemId: string;
    priceId: string;
    seats: number;
}

export interface StripeInvoice {
    id: string;
    number: string;
    created: number;
    status: string;
    total: number;
    invoice_pdf: string;
}

export enum CardBrand {
    Amex = `amex`,
    Diners = `diners`,
    Discover = `discover`,
    Jcb = `jcb`,
    Mastercard = `mastercard`,
    Unionpay = `unionpay`,
    Visa = `visa`,
    Unknown = `unknown`,
}

export type StripePaymentMethod = {
    id: string;
    card: {
        brand: CardBrand;
        display_brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
    };
};

export type PaymentMethods = {
    paymentMethods: StripePaymentMethod[];
    defaultPaymentMethodId: string | StripePaymentMethod;
};

type DefaultPaymentMethod = {
    invoice_settings: {
        default_payment_method: string;
    };
};

export function getSubscription(): Promise<StripeMainSubscription> {
    return client.get('/billing/subscriptions/main').then((res) => res.data);
}

export function updateMainSubscription({
    subscriptionItemId,
    priceId,
    seats,
}: UpdateSubscriptionPayload): Promise<void> {
    return client
        .post('/billing/subscriptions/update', {
            subscriptionItemId,
            priceId,
            seats,
        })
        .then((res) => res.data);
}

export function subscribeToFeature({
    feature,
}: {
    feature: AddonFeatureSet;
}): Promise<void> {
    return client
        .post('/billing/subscriptions/feature', { feature })
        .then((res) => res.data);
}

export function cancelSubscription({
    feedback,
    comment,
}: {
    feedback: CancellationReason;
    comment: string;
}): Promise<void> {
    return client
        .patch('/billing/subscriptions/cancel', { feedback, comment })
        .then((res) => res.data);
}

export function renewSubscription(): Promise<void> {
    return client.get('/billing/subscriptions/renew');
}

export function getPaymentMethods(): Promise<PaymentMethods> {
    return client.get('/billing/payment-methods').then((res) => res.data);
}

export function addPaymentMethod(
    methodId: string,
): Promise<StripePaymentMethod> {
    return client
        .post('/billing/payment-methods', { methodId })
        .then((res) => res.data);
}

export function deletePaymentMethod(paymentMethodId: string) {
    return client.delete(`/billing/payment-methods/${paymentMethodId}`);
}

export function setDefaultPaymentMethod(
    methodId: string,
): Promise<DefaultPaymentMethod> {
    return client
        .patch('/billing/payment-methods/default', { methodId })
        .then((res) => res.data);
}

export function getInvoices(): Promise<StripeInvoice[]> {
    return client.get('/billing/invoices').then((res) => res.data);
}
