import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiBadge: Components<Theme>['MuiBadge'] = {
    styleOverrides: {
        badge: ({ theme }) => ({
            ...theme.typography.body4,
            letterSpacing: 0.2,
            padding: theme.spacing(0, 1.5),
            height: 16,
        }),
    },
};
