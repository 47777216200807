import { MouseEvent, useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem, Stack } from '@mui/material';
import * as styles from './styles';
import { VerticalThreeDotsIcon } from '../../icons/common/VerticalThreeDotsIcon';

export type MenuItem = {
    label: React.ReactNode;
    icon?: React.ReactNode;
    onClick: (() => void) | (() => Promise<void>);
    divider?: boolean;
};

type Props = {
    children: React.ReactNode;
    menuItems?: MenuItem[];
    isMenuHidden?: boolean;
};

export function RowWithMenu({ children, menuItems, isMenuHidden }: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickItem = (clickCallback: () => void) => () => {
        handleClose();
        clickCallback();
    };

    return (
        <Stack
            spacing={6}
            direction="row"
            alignItems="center"
            sx={{
                ...styles.row,
                ...(open
                    ? { borderColor: 'info.main' }
                    : { borderColor: 'custom.gray.light400' }),
            }}
        >
            {children}
            {menuItems && !isMenuHidden && (
                <div>
                    <IconButton
                        disableRipple
                        sx={styles.contextMenuButton}
                        color={open ? 'info' : 'primary'}
                        onClick={handleClick}
                    >
                        <VerticalThreeDotsIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                        sx={styles.contextMenu}
                    >
                        {menuItems.map(({ label, icon, onClick, divider }) => (
                            <MenuItem
                                onClick={handleClickItem(onClick)}
                                key={label}
                                divider={divider}
                            >
                                {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
                                {label}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </Stack>
    );
}
