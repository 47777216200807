import { handleNewMessageEvent, NEW_MESSAGE_EVENT } from './new-message-event';
import {
    CONVERSATION_CHANGE_EVENT,
    handleConversationChangeEvent,
} from './new-conversation-event';
import {
    handleNewDiscussionCommentEvent,
    NEW_DISCUSSION_COMMENT_MESSAGE,
} from './new-discussion-comment-event';
import {
    CONTACT_CREATED_EVENT,
    handleContactCreatedEvent,
} from './new-contact-event';
import {
    CONTACT_UPDATED_EVENT,
    ContactUpdateHandler,
    handleContactUpdatedEvent,
} from './event-handlers/contact-updated.event-handler';
import {
    CONVERSATION_UPDATED_EVENT,
    ConversationUpdateHandler,
    handleConversationUpdated,
} from './event-handlers/handle-conversation.updated.event';
import {
    handleUpdatedMessageEvent,
    MESSAGE_UPDATED_EVENT,
    MessageUpdateHandler,
} from './event-handlers/handle-message.updated.event';
import {
    COHORT_UPDATED,
    handleCohorUpdatedEvent,
} from './cohort-updated-event';
import {
    INBOX_UPDATED_EVENT,
    InboxUpdatedHandler,
    handleInboxUpdate,
} from './event-handlers/inbox-updated.event-handler';
import {
    INBOX_DELETED_EVENT,
    inboxDeletedHandler,
    InboxDeletedHandler,
} from './event-handlers/inbox-deleted.event-handler';
import {
    INBOX_CREATED_EVENT,
    inboxCreatedHandler,
    InboxCreatedHandler,
} from './event-handlers/inbox-created.event-handler';
import {
    SUBSCRIPTION_INTENT_SUCCESS_EVENT,
    subscriptionIntentSuccessHandler,
} from './event-handlers/subscription-intent-success.event-handler';
import {
    SUBSCRIPTION_DELETED_EVENT,
    subscriptionDeletedHandler,
} from './event-handlers/subscription-deleted.event-handler';
import {
    SUBSCRIPTION_INTENT_PAYMENT_FAILED_EVENT,
    subscriptionIntentPaymentFailedHandler,
} from './event-handlers/subscription-intent-payment-failed.event-handler';

/** NOTICE: Please start to write typed handlers */
type Mapping = {
    [CONVERSATION_UPDATED_EVENT]: ConversationUpdateHandler;
    [INBOX_UPDATED_EVENT]: InboxUpdatedHandler;
    [INBOX_DELETED_EVENT]: InboxDeletedHandler;
    [INBOX_CREATED_EVENT]: InboxCreatedHandler;
    [MESSAGE_UPDATED_EVENT]: MessageUpdateHandler;
    [CONTACT_UPDATED_EVENT]: ContactUpdateHandler;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (payload: any) => void | Promise<void>;
};

/** NOTICE: Please start to write typed handlers */
export const EVENT_HANDLERS_MAP: Mapping = {
    [NEW_MESSAGE_EVENT]: handleNewMessageEvent,
    [INBOX_UPDATED_EVENT]: handleInboxUpdate,
    [INBOX_DELETED_EVENT]: inboxDeletedHandler,
    [INBOX_CREATED_EVENT]: inboxCreatedHandler,
    [MESSAGE_UPDATED_EVENT]: handleUpdatedMessageEvent,
    [CONVERSATION_CHANGE_EVENT]: handleConversationChangeEvent,
    [COHORT_UPDATED]: handleCohorUpdatedEvent,
    [NEW_DISCUSSION_COMMENT_MESSAGE]: handleNewDiscussionCommentEvent,
    [CONTACT_CREATED_EVENT]: handleContactCreatedEvent,
    [CONTACT_UPDATED_EVENT]: handleContactUpdatedEvent,
    [CONVERSATION_UPDATED_EVENT]: handleConversationUpdated,
    [SUBSCRIPTION_INTENT_SUCCESS_EVENT]: subscriptionIntentSuccessHandler,
    [SUBSCRIPTION_INTENT_PAYMENT_FAILED_EVENT]:
        subscriptionIntentPaymentFailedHandler,
    [SUBSCRIPTION_DELETED_EVENT]: subscriptionDeletedHandler,
};
