import styles from './AttachmentImageItem.module.scss';
import Button from '../../elements/Buttons';
import { PublicFile } from '../../api/types';
import { useModalContext } from '../../containers/modal/reducer';
import AttachmentModal from '../Modals/AttachmentModal';
import { Icon } from '../../icons/Icon';
import { downloadResource } from '../../utils/downloads';
import { useMemo } from 'react';

type AttachmentImageItemProps = {
    attachment: PublicFile;
};

export const AttachmentImageItem = (props: AttachmentImageItemProps) => {
    const { dispatch } = useModalContext();
    const modal = useMemo(
        () => <AttachmentModal url={props.attachment.url} />,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <div className={styles.attachment}>
            <Button
                type="icon"
                className={styles['download-button']}
                icon={
                    <Icon
                        name="file-download"
                        onClick={() =>
                            downloadResource(
                                `${import.meta.env.VITE_API_BASE_URL}/files/${
                                    props.attachment.id
                                }`,
                                props.attachment.name || 'file',
                            )
                        }
                    />
                }
            />
            <img
                onClick={() =>
                    dispatch({
                        type: 'PUSH',
                        payload: { content: () => modal },
                    })
                }
                className={styles['image']}
                src={props.attachment.url}
            />
        </div>
    );
};
