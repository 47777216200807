import { useMemo } from 'react';
import { useToastContext } from '../../../containers/toast/reducer';
import { mapLegacy, useCampaignCreate } from '../../../queries/campaigns';
import { UUID } from '../../../types/uuid';
import MessageForm, { MessageFormData } from '../../MessageForm';
import { handleError } from '../../MessageFormContainers/utils';
import { useMessageCreateFromInbox } from './use-message.query';
import { useConfirmSending } from '../../../main/campaign/v3/use-confirm-sending';
import isEmpty from 'lodash/isEmpty';
import { Mode } from '../NewConversation';
import { useNewConversationContext } from '../NewConversationProvider/NewConversationProvider';

interface NewConversationMessageProps {
    inboxId: UUID;
    onFinish: (
        entityId: string | number,
        view: 'conversation' | 'campaign',
    ) => void;
    mode: Mode;
    contactIds: UUID[];
    isLimitReached: boolean;
    setIsLoading: (isLoading: boolean) => void;
}

export const NewConversationMessage = ({
    inboxId,
    onFinish,
    mode,
    contactIds,
    isLimitReached,
    setIsLoading,
}: NewConversationMessageProps) => {
    const { state } = useNewConversationContext();
    const { dispatch: toastDispatch } = useToastContext();

    const messageCreation = useMessageCreateFromInbox();
    const { mutateAsync: createCampaignV3 } = useCampaignCreate();

    const placeholder = useMemo(() => {
        if (mode === 'campaign') {
            return 'Write a campaign message...';
        }
        if (contactIds.length > 1) {
            return 'Write a group message...';
        }
        return 'Write a message...';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactIds, state.cohortId, mode]);

    const [confirm, ConfirmDialog] = useConfirmSending();

    const onSend = async (data: MessageFormData): Promise<void> => {
        setIsLoading(true);
        try {
            await submitForm(data);
        } catch {
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const submitForm = async ({
        message,
        sendAt,
        files,
        attachments,
        messageTemplateId,
    }: MessageFormData) => {
        const trimmedMsg = message.trim();

        if (trimmedMsg.trim().length < 1 && isEmpty(attachments)) {
            return Promise.reject();
        }

        const data = sendAt
            ? {
                  inboxId,
                  fileIds: files?.map((f) => f.id),
                  attachments: attachments?.map(({ id }) => id),
                  contactIds,
                  message,
                  sendAt,
                  templateId: messageTemplateId,
              }
            : {
                  inboxId,
                  fileIds: files?.map((f) => f.id),
                  attachments: attachments?.map(({ id }) => id),
                  contactIds,
                  message,
                  templateId: messageTemplateId,
              };

        if (isLimitReached || mode === 'campaign') {
            if (await confirm()) {
                return createCampaignV3(mapLegacy(data))
                    .then(({ id }) => onFinish(id, 'campaign'))
                    .catch(handleError(toastDispatch));
            }
            return Promise.reject();
        }

        return messageCreation
            .mutateAsync(mapLegacy(data))
            .then(({ conversationId }) =>
                onFinish(conversationId, 'conversation'),
            )
            .catch(handleError(toastDispatch));
    };

    return (
        <>
            <MessageForm
                initial={null}
                placeholder={placeholder}
                onSend={onSend}
                templatesAvailable={
                    mode === 'campaign' || contactIds.length === 1
                }
                disabled={contactIds.length === 0 || isLimitReached}
                signatureAvailable
            />
            <ConfirmDialog contactsCount={contactIds.length} />
        </>
    );
};
