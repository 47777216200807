import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { AddFilledIcon } from '../../icons/common/AddFilledIcon';
import { useTeamCreate } from '../../queries/team';
import { useTrack } from '../../contexts/analytics';
import { ToastActions, useToastContext } from '../../containers/toast/reducer';
import { errorToToast } from '../MessageFormContainers/utils';
import { useNavigate } from 'react-router';

export const TeamCreateListItem = () => {
    const track = useTrack();
    const navigate = useNavigate();
    const { dispatch } = useToastContext();
    const { mutateAsync, isPending } = useTeamCreate();

    return (
        <MenuItem
            disabled={isPending}
            onClick={() => {
                track('team_create_clicked');
                mutateAsync()
                    .then(() => {
                        navigate('/');
                    })
                    .catch((e) =>
                        dispatch({
                            type: ToastActions.ADD,
                            payload: errorToToast(e),
                        }),
                    );
            }}
        >
            <ListItemIcon>
                <AddFilledIcon />
            </ListItemIcon>
            <ListItemText>Create a new team</ListItemText>
        </MenuItem>
    );
};
