import styles from './MessageFormVariables.module.scss';
import { useEffect, useRef, useState } from 'react';
import MenuItem from '../../elements/MenuItem';
import { ClickAwayListener } from '@mui/material';
import { useProperties } from '../MessageInput/components/Toolbar/Template/useProperties';

type MessageFormVariablesProps = {
    onSelect: (propertyName: string) => void;
    onClose: () => void;
};

export const MessageFormVariables = ({
    onSelect,
    onClose,
}: MessageFormVariablesProps) => {
    const [search, setSearch] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const { properties: filteredProperties } = useProperties(search);

    return (
        <ClickAwayListener onClickAway={onClose}>
            <section className={styles['root']} ref={containerRef}>
                <p className={styles['root__title']}>Add a variable</p>

                <div />
                <input
                    className={styles['root__search']}
                    placeholder="Property name"
                    onChange={(e) => setSearch(e.currentTarget.value)}
                />

                <ul className={styles['root__list']}>
                    {filteredProperties.map(({ label, value }) => (
                        <li
                            key={`${label}_${value}`}
                            className={styles['root__list-item']}
                        >
                            <MenuItem
                                className={styles['root__btn']}
                                onClick={() => onSelect(value)}
                            >
                                {label}
                            </MenuItem>
                        </li>
                    ))}
                </ul>
            </section>
        </ClickAwayListener>
    );
};
