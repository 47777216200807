import { useCallback, useMemo } from 'react';
import Loading from '../../../components/Loading/Loading';
import { useInboxesQueryData } from '../../../queries/inboxes';
import { StepShell } from '../../Onboarding/StepShell';
import { useTrack } from '../../../contexts/analytics';
import { Button, Stack, Typography } from '@mui/material';
import { useSetupWebexInboxContext } from './setup-webex-phone-context';
import { InboxProvider, WebexPhone } from '../../../api/types';
import { PhonesList } from '../CarrierPhoneNumber/PhonesList';
import { WebexIcon } from '../../../icons/logos/WebexIcon';
import { WebexPhoneItem } from './WebexPhoneItem';
import { useGetWebexPhones } from '../../../queries/integrations';
import { useCreateInboxes } from '../hooks/useCreateInboxes';

export const WebexPhoneSelectionStep = () => {
    const { selectedPhones, setSelectedPhones } = useSetupWebexInboxContext();
    const [createInboxes] = useCreateInboxes();

    const inboxes = useInboxesQueryData();
    const { data = { phones: [] }, isPending } = useGetWebexPhones();
    const track = useTrack();

    const availablePhones = useMemo(
        () =>
            (data.phones ?? []).filter((phone) => {
                return !(inboxes ?? []).find(
                    (inbox) => inbox.phone === phone.phone,
                );
            }),
        [inboxes, data],
    );

    const setUniqueSelectedPhones = useCallback(
        (phone: WebexPhone) => {
            setSelectedPhones((phones: WebexPhone[]) => {
                return phones.some((p) => p.phone === phone.phone)
                    ? phones.filter((p) => p.phone !== phone.phone)
                    : [...phones, phone];
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedPhones],
    );

    const handleSubmit = useCallback(async () => {
        track('clicked_add_webex_phones', {
            phones: selectedPhones.map((phone) => phone.phone),
        });
        const inboxes = selectedPhones.map((phone: WebexPhone) => ({
            phone: phone.phone,
            provider: InboxProvider.WEBEX,
        }));
        await createInboxes(inboxes);
    }, [createInboxes, selectedPhones, track]);

    const ctaText = useMemo(() => {
        if (selectedPhones.length === 0) {
            return 'Select Phone Numbers To Enable';
        }

        if (selectedPhones.length === 1) {
            return 'Enable SMS on 1 Phone';
        }

        return `Enable SMS on ${selectedPhones.length} Phones`;
    }, [selectedPhones.length]);

    return (
        <StepShell
            title="Activate SMS"
            icon={<WebexIcon />}
            subtitle={
                <>
                    <Typography variant="subtitle2">
                        Enable SMS on a Webex phone by selecting below.
                    </Typography>
                    <br />
                    <Typography variant="body4">
                        If you are not an admin for your Webex Org, you will
                        only have access to the primary work phone assigned to
                        you.
                    </Typography>
                </>
            }
            goBackward="/setup-inbox/existing-number"
        >
            <Stack spacing={6} width={320}>
                {isPending ? (
                    <Loading />
                ) : (
                    <>
                        <PhonesList
                            phones={availablePhones}
                            selected={selectedPhones}
                            handleSelect={setUniqueSelectedPhones}
                            phoneToString={(phone) => phone.phone}
                            itemHeight={56}
                            ItemComponent={WebexPhoneItem}
                        />
                        <Button
                            onClick={handleSubmit}
                            disabled={selectedPhones!.length === 0}
                            fullWidth
                        >
                            {ctaText}{' '}
                        </Button>
                    </>
                )}
            </Stack>
        </StepShell>
    );
};
