import {
    Badge,
    Box,
    Divider,
    ListItemIcon,
    Menu,
    MenuItem,
    MenuList,
    Tooltip,
    Typography,
} from '@mui/material';
import { InboxAlternativeIcon } from '../../icons/common/InboxAlternativeIcon';
import {
    navItemIconForNarrowScreens,
    navItemStyle,
} from '../../elements/Navigation/styles';
import { useMemo } from 'react';
import { useGetSetting, useMeQueryData } from '../../queries/user';
import { useInboxes } from '../../queries/inboxes';
import partition from 'lodash/partition';
import { sortInboxesByPredefinedOrder } from '../../utils/inboxes';
import { UserTeamSettingType } from '../../api/types';
import { UUID } from '../../types/uuid';
import { useUnreadMessageCounts } from '../../queries/messages';
import InboxItem from '../../elements/InboxItem';
import { useMenu } from '../../hooks/use-menu';
import { inboxItem, list } from '../../elements/InboxItem/styles';

export function InboxesPopover() {
    const menu = useMenu();
    const currentUser = useMeQueryData();
    const { data: inboxes = [] } = useInboxes();
    const { data: inboxesOrderSetting } = useGetSetting(
        UserTeamSettingType.InboxesOrder,
    );
    const { data: unreadCount } = useUnreadMessageCounts((counts = []) =>
        counts.reduce((acc, { count }) => acc + Number(count), 0),
    );

    const orderedInboxes = useMemo(() => {
        const order = (inboxesOrderSetting?.data?.ids || []) as UUID[];
        const [notMuted, muted] = partition(
            inboxes,
            (inbox) => !currentUser?.mutedInboxIds?.includes(inbox.id),
        );

        return [...sortInboxesByPredefinedOrder(notMuted, order), ...muted];
    }, [inboxesOrderSetting?.data?.ids, inboxes, currentUser?.mutedInboxIds]);

    return (
        <>
            <Box py={2}>
                <Tooltip
                    disableFocusListener
                    arrow
                    title={
                        <Typography noWrap variant="body4">
                            Inboxes
                        </Typography>
                    }
                    placement="right"
                >
                    <MenuItem onClick={menu.handleOpen} sx={navItemStyle}>
                        <ListItemIcon sx={navItemIconForNarrowScreens}>
                            <Badge badgeContent={unreadCount} color="error">
                                <InboxAlternativeIcon />
                            </Badge>
                        </ListItemIcon>
                    </MenuItem>
                </Tooltip>
            </Box>
            <Menu
                open={menu.open}
                anchorEl={menu.anchorEl}
                onClose={menu.handleClose}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: -16,
                }}
                transformOrigin={{
                    horizontal: -16,
                    vertical: 'top',
                }}
            >
                <MenuList sx={{ width: 260 }}>
                    <Box py={2} px={3}>
                        <Typography variant="body3" color="primary.dark">
                            Phones
                        </Typography>
                    </Box>
                </MenuList>
                <Divider />
                <MenuList sx={{ width: 260 }}>
                    {orderedInboxes.map((inbox) => (
                        <Box component="li" sx={list} key={inbox.id}>
                            <InboxItem
                                disableMenus
                                sx={{ ...inboxItem, margin: 0 }}
                                inbox={inbox}
                                onClick={menu.handleClose}
                            />
                        </Box>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
}
