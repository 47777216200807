import { useMemo, useState } from 'react';
import { useInbox } from '../../queries/inboxes';
import {
    Dialog,
    IconButton,
    ListItemIcon,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import { ArrowBackIcon } from '../../icons/common/CArrowBack';
import { InfoIcon } from '../../icons/common/CInfoIcon';
import { AccessTimeIcon } from '../../components/ContactProperty/PropertyIcon/AccessTimeIcon';
import { UrlIcon } from '../../components/ContactProperty/PropertyIcon/UrlIcon';
import { About } from './AboutTab/About';
import { AttachmentIcon } from '../../icons/common/AttachmentIcon';
import { OutOfOffice } from './OutOfOfficeTab/OutOfOffice';
import { colors } from '../../theme/palette';

type InboxSettingsProps = {
    inboxId: string;
    onClose?: () => void;
    open: boolean;
    deleteInbox: boolean;
    cancelDeleteInbox: () => void;
};

const tabTitles = {
    about: {
        label: 'About',
        icon: <InfoIcon />,
        disabled: false,
    },
    outOfOffice: {
        label: 'Out of Office',
        icon: <AccessTimeIcon />,
        disabled: false,
    },
    links: {
        label: 'Links',
        icon: <UrlIcon />,
        disabled: true,
    },
    files: {
        label: 'Files',
        icon: <AttachmentIcon />,
        disabled: true,
    },
} as const;

type Tabs = keyof typeof tabTitles;

export const InboxSettings = ({
    inboxId,
    deleteInbox,
    cancelDeleteInbox,
    open,
    onClose,
}: InboxSettingsProps) => {
    const { data: inbox } = useInbox(inboxId);
    const [tab, setTab] = useState<Tabs>('about');

    const content = useMemo(() => {
        if (!inbox) {
            return null;
        }

        switch (tab) {
            case 'about':
                return <About inbox={inbox} deleteInbox={deleteInbox} />;
            case 'outOfOffice':
                return <OutOfOffice inbox={inbox} />;
            case 'links':
            case 'files':
            default:
                return null;
        }
    }, [inbox, tab, deleteInbox]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                elevation: 5,
                sx: (theme) => ({
                    width: 888,
                    maxWidth: 888,
                    minHeight: 580,
                    borderRadius: 1.5,

                    [theme.breakpoints.down('md')]: {
                        width: 1,
                        maxWidth: 1,
                        height: 1,
                        maxHeight: 1,
                        margin: 0,
                        borderRadius: 0,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    },
                }),
            }}
        >
            <Stack
                direction="row"
                alignItems="start"
                flex={1}
                width={1}
                height={1}
                bgcolor="custom.gray.superLight"
            >
                <Stack spacing={4} width={248} height={1}>
                    <Stack
                        spacing={4}
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            paddingX: 4,
                            paddingY: 5,
                            borderBottom: '1px solid',
                            borderColor: 'custom.gray.divider',
                        }}
                    >
                        <IconButton onClick={onClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            noWrap
                            variant="body3"
                            fontWeight={500}
                            color="primary.dark"
                        >
                            Phone Settings
                        </Typography>
                    </Stack>
                    <Stack px={2} spacing={2} component="ul">
                        {Object.entries(tabTitles).map(([key, value]) => (
                            <MenuItem
                                selected={tab === key}
                                key={key}
                                onClick={() => {
                                    setTab(key as Tabs);
                                    cancelDeleteInbox();
                                }}
                                disabled={value.disabled}
                            >
                                <ListItemIcon>{value.icon}</ListItemIcon>
                                {value.label}
                            </MenuItem>
                        ))}
                    </Stack>
                </Stack>
                <Stack
                    spacing={6}
                    sx={{
                        paddingX: 10,
                        paddingY: 6,
                        flex: 1,
                        height: 1,
                        borderLeft: '1px solid',
                        borderColor: 'custom.gray.divider',
                        backgroundColor: colors.primary.white,
                        minHeight: 580,
                    }}
                >
                    {content}
                </Stack>
            </Stack>
        </Dialog>
    );
};
