import { Contact } from '../api/types';
import { formatPhoneNumber } from './phoneNumber';

export const getContactName = (
    contact: Pick<Contact, 'name' | 'email' | 'phone'>,
    teamCountry?: string,
) => {
    const { name, phone, email } = contact;
    if (name && name.trim().length > 0) {
        return name;
    }

    if (phone) {
        return formatPhoneNumber(phone, teamCountry);
    }

    return email;
};
