import { SxProps, Theme } from '@mui/material';

export const image: SxProps<Theme> = {
    width: 'auto',
    height: 'auto',
    maxWidth: 286,
    maxHeight: 286,
    borderRadius: 1.5,
    overflow: 'hidden',
    backgroundColor: 'white',
    '&:hover': {
        cursor: 'zoom-in',
        '& button': {
            display: 'block',
        },
    },
};

export const downloadButton: SxProps<Theme> = {
    display: 'none',
    position: 'absolute',
    top: 8,
    right: 8,
    elevation: 1,
    border: '1px solid',
    borderColor: 'primary.outlinedBorder',
};
