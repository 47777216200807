import Mustache from 'mustache';
import dayjs from 'dayjs';
import {
    Conversation,
    Message,
    Profile,
    ScheduledMessage,
} from '../../api/types';
import { userName } from '../../helpers/formatting';
import { RefObject } from 'react';
import {
    ContactLibrary,
    getByPhone,
    getByPhoneOrEmpty,
} from '../../queries/contact-library.helpers';

const SCROLL_THRESHOLD = 10; // in pixels

interface EventItem {
    isGroupStart?: boolean;
    isGroupEnd?: boolean;
    date?: Date;
    message?: Message;
    avatar?: string;
    author?: Partial<{ name: string; phone: string }>;
}

const getAuthorFromUser = (sentByName?: string, user?: Profile) => {
    if (sentByName) {
        return sentByName;
    }

    if (user) {
        return userName(user);
    }

    return '';
};

const getInitialEvent = (message: Message, contacts: ContactLibrary) => {
    if (message.inbound) {
        const contact = getByPhoneOrEmpty(message.sender, contacts);
        return {
            message,
            avatar: contact.avatarURL,
            author: {
                name: contact.name,
                phone: contact.phone,
            },
        };
    }
    const user = message.user;
    return {
        message,
        avatar: user?.avatar?.url,
        author: { name: getAuthorFromUser(message.sentByName, user) },
    };
};

const isSameDay = (currentMessageDate: string, prevMessageDate: string) =>
    dayjs(currentMessageDate).isSame(prevMessageDate, 'day');

const isSameMinute = (currentMessageDate: string, prevMessageDate: string) =>
    dayjs(currentMessageDate).isSame(prevMessageDate, 'minute');

export const transformMessages = (
    messages: Message[],
    contacts: ContactLibrary,
) => {
    const events: EventItem[] = [];

    for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        let event: EventItem = getInitialEvent(message, contacts);

        if (
            i === 0 ||
            !isSameDay(message.timestamp, messages[i - 1].timestamp)
        ) {
            events.push({ date: new Date(message.timestamp) });
        }

        // Detect the start of the chain

        if (i === 0) {
            event = { ...event, isGroupStart: true };
        } else if (
            !isSameMinute(message.timestamp, messages[i - 1].timestamp)
        ) {
            // Even if the user is same but minutes are different - mark chain as just started
            event = { ...event, isGroupStart: true };
        } else if (message.inbound !== messages[i - 1].inbound) {
            // If some message is inbound and the other is not, it's a new chain
            event = { ...event, isGroupStart: true };
        } else if (message.inbound) {
            // Check if the users/senders are different
            if (message.sender !== messages[i - 1].sender) {
                event = { ...event, isGroupStart: true };
            }
        } else if (!message.inbound) {
            if (message.user && messages[i - 1].user) {
                if (message.user.id !== messages[i - 1].user.id) {
                    event = { ...event, isGroupStart: true };
                }
            } else if (message.sentByName !== messages[i - 1].sentByName) {
                event = { ...event, isGroupStart: true };
            }
        }

        // Detect the end of the chain

        if (i === messages.length - 1) {
            event = { ...event, isGroupEnd: true };
        } else if (
            !isSameMinute(message.timestamp, messages[i + 1].timestamp)
        ) {
            // Even if the user is same but minutes are different - mark chain as ended
            event = { ...event, isGroupEnd: true };
        } else if (message.inbound !== messages[i + 1].inbound) {
            // If some message is inbound and the other is not, it's an end of the chain
            event = { ...event, isGroupEnd: true };
        } else if (message.inbound) {
            // Check if the users/senders are different
            if (message.sender !== messages[i + 1].sender) {
                event = { ...event, isGroupEnd: true };
            }
        } else if (!message.inbound) {
            if (message.user && messages[i + 1].user) {
                if (message.user.id !== messages[i + 1].user.id) {
                    event = { ...event, isGroupStart: true };
                }
            } else if (message.sentByName !== messages[i + 1].sentByName) {
                event = { ...event, isGroupStart: true };
            }
        }

        // Otherwise it is the intermediate message

        events.push(event);
    }

    return events;
};

export const isScrollAtTheBottom = (scrollRef: RefObject<HTMLDivElement>) => {
    if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        return scrollTop + SCROLL_THRESHOLD >= scrollHeight - clientHeight;
    }
    return false;
};

export const scrollToBottom = (scrollRef: RefObject<HTMLDivElement>) => {
    if (scrollRef.current) {
        const sp =
            scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
        scrollRef.current.scrollTo(0, sp);
    }
};

export const byDate = (a: ScheduledMessage, b: ScheduledMessage) =>
    new Date(a.sendAt).getTime() - new Date(b.sendAt).getTime();

export const transformScheduledMessage = (
    contacts: ContactLibrary,
    conversation: Conversation,
    message: ScheduledMessage,
): ScheduledMessage => {
    if (conversation.members.length === 1) {
        const { name, email, phone, data } =
            getByPhone(conversation.members[0], contacts) || {};
        const view = { name, email, phone, ...data };
        return {
            ...message,
            body: Mustache.render(message.body!, view),
        };
    }
    return message;
};
