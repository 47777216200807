import { forwardRef, useCallback, useMemo, useState } from 'react';
import { UUID } from '../../../types/uuid';
import { useMeQueryData, useTeammates } from '../../../queries/user';
import { Conversation } from '../../../api/types';
import { userName } from '../../../helpers/formatting';
import { useAssignUsers } from '../../../queries/conversations';
import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    InputBase,
    List,
    ListItemButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { AssignMemberIcon } from '../../../icons/common/CAssignMemberIcon';
import { useMenu } from '../../../hooks/use-menu';
import { ToolbarPopper } from '../ToolbarPopper/ToolbarPopper';
import { SearchIcon } from '../../../icons/common/SearchIcon';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { CloseIcon } from '../../../icons/common/CCloseIcon';
import { SelectedCheckmarkIcon } from './SelectedCheckmarkIcon';
import { GroupedVirtuoso } from 'react-virtuoso';
import {
    alightLeftButton,
    divider,
    DIVIDER_HEIGHT,
    emptyDivider,
    item,
    ITEM_HEIGHT,
    list,
    searchInput,
    selectedItemAvatarCheckmark,
    selectedItemAvatarWrapper,
} from './styles';

const MAX_ITEMS = 7;

interface Props {
    conversation: Conversation;
}

export const AssignToTeam = (props: Props) => {
    const { mutate } = useAssignUsers();
    const menu = useMenu();
    const [searchTerm, setSearchTerm] = useState('');

    const currentUser = useMeQueryData();
    const { data: teammates = [] } = useTeammates({ disabled: !menu.open });

    const team = currentUser?.activeTeam;

    const selected = useMemo(
        () => props.conversation.assignee?.map(({ id }) => id) || [],
        [props.conversation],
    );

    const filteredUsers = useMemo(
        () =>
            teammates
                .filter((user) =>
                    userName(user, team?.countryCode)
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                )
                .sort((a, b) => {
                    if (selected.includes(a.id)) {
                        return -1;
                    }

                    if (selected.includes(b.id)) {
                        return 1;
                    }

                    return 0;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [teammates, searchTerm, selected],
    );

    const assign = useCallback(
        (id: UUID) => {
            const newSelected = selected.concat(id);
            mutate({
                conversationId: props.conversation.id,
                userIds: newSelected,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selected],
    );

    const unassign = useCallback(
        (id: UUID) => {
            const newSelected = selected.filter((item) => item !== id);
            mutate({
                conversationId: props.conversation.id,
                userIds: newSelected,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selected],
    );

    const toggleAllHandler = useCallback(() => {
        if (selected.length) {
            mutate({
                conversationId: props.conversation.id,
                userIds: [],
            });
        } else {
            mutate({
                conversationId: props.conversation.id,
                userIds: filteredUsers.map((user) => user.id),
            });
        }
    }, [selected, filteredUsers, props.conversation, mutate]);

    const [selectedAmount, freeAmount] = useMemo(() => {
        const filteredIds = filteredUsers.map((user) => user.id);
        const filteredSelected = selected.filter((id) =>
            filteredIds.includes(id),
        );

        return [
            filteredSelected.length,
            filteredUsers.length - filteredSelected.length,
        ];
    }, [selected, filteredUsers]);

    const listHeight = useMemo(() => {
        const more =
            (!!selectedAmount && !!freeAmount ? DIVIDER_HEIGHT : 1) * 2;

        if (filteredUsers.length > MAX_ITEMS) {
            return MAX_ITEMS * ITEM_HEIGHT + more;
        }

        return filteredUsers.length * ITEM_HEIGHT + more;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredUsers, selected]);

    return (
        <>
            <Tooltip title="Assign a team member">
                <IconButton onClick={menu.handleOpen} color="primary">
                    <AssignMemberIcon />
                </IconButton>
            </Tooltip>
            {menu.open && (
                <ToolbarPopper
                    anchorEl={menu.anchorEl}
                    handleClose={menu.handleClose}
                    header="Assign a team member"
                    disableContentPadding
                >
                    <Stack>
                        <Box px={1}>
                            <InputBase
                                autoFocus
                                fullWidth
                                placeholder="Search"
                                sx={searchInput}
                                value={searchTerm}
                                onChange={(event) => {
                                    setSearchTerm(
                                        (event.target as HTMLInputElement)
                                            .value,
                                    );
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Divider sx={divider} />
                        <Box px={1}>
                            <Button
                                size="small"
                                variant="text"
                                fullWidth
                                color="info"
                                sx={alightLeftButton}
                                onClick={toggleAllHandler}
                            >
                                {selectedAmount ? 'Unassign all' : 'Assign all'}
                            </Button>
                        </Box>
                    </Stack>

                    {!!filteredUsers.length && (
                        <GroupedVirtuoso
                            groupCounts={[selectedAmount, freeAmount]}
                            style={{
                                height: `${listHeight}px`,
                            }}
                            components={{
                                List: forwardRef(
                                    function VirtuosoList(props, ref) {
                                        return (
                                            <List
                                                {...props}
                                                component="div"
                                                ref={ref}
                                                sx={list}
                                            />
                                        );
                                    },
                                ),
                            }}
                            groupContent={(index) => {
                                return (
                                    <Box sx={emptyDivider}>
                                        {index &&
                                        !!selectedAmount &&
                                        !!freeAmount ? (
                                            <Divider sx={divider} />
                                        ) : null}
                                    </Box>
                                );
                            }}
                            itemContent={(index) => {
                                if (filteredUsers.length === 0) {
                                    return null;
                                }
                                const user = filteredUsers[index];

                                if (!user) {
                                    return null;
                                }
                                const isSelected = selected.includes(user.id);
                                const fullName = [user.firstname, user.lastname]
                                    .join(' ')
                                    .trim();

                                const userName = fullName || user.email;
                                const avatar = (
                                    <UserAvatar
                                        size={20}
                                        imageUrl={user.avatar?.url}
                                        alt={userName}
                                        username={fullName}
                                    />
                                );

                                return (
                                    <ListItemButton
                                        selected={isSelected}
                                        onClick={() => {
                                            if (isSelected) {
                                                unassign(user.id);
                                            } else {
                                                assign(user.id);
                                            }
                                        }}
                                        sx={item}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            flex={1}
                                        >
                                            {isSelected ? (
                                                <Box
                                                    sx={
                                                        selectedItemAvatarWrapper
                                                    }
                                                >
                                                    {avatar}
                                                    <SelectedCheckmarkIcon
                                                        sx={
                                                            selectedItemAvatarCheckmark
                                                        }
                                                    />
                                                </Box>
                                            ) : (
                                                avatar
                                            )}

                                            <Typography
                                                variant="body3"
                                                noWrap
                                                flex={1}
                                            >
                                                {userName}
                                            </Typography>
                                            {isSelected && (
                                                <IconButton size="small">
                                                    <CloseIcon />
                                                </IconButton>
                                            )}
                                        </Stack>
                                    </ListItemButton>
                                );
                            }}
                        />
                    )}
                </ToolbarPopper>
            )}
        </>
    );
};
