import { OptionBase, OptionId, OptionWithDescription } from './types';
import styles from './AdvancedSelect.module.scss';
import CheckBox from '../CheckBox/CheckBox';

// Option renderrers

export const defaultSimpleRenderrer = <T extends OptionBase>(option: T) => (
    <span className={styles['advanced-select__default-item-label']}>
        <span>{option.label}</span>
    </span>
);

export const defaultMultiselectRenderrer = <T extends OptionBase>(
    option: T,
    selected: boolean,
) => (
    <>
        <CheckBox checked={selected} />
        <span
            className={
                styles['advanced-select__default-multiselect-item-label']
            }
        >
            {option.label}
        </span>
    </>
);

// Value renderrers

export const defaultValueRenderrer = <T extends OptionBase>(
    options: T[],
    value?: OptionId | OptionId[],
    placeholder?: string,
) => {
    let displayValue = '';

    if (Array.isArray(value)) {
        const selectedOptions = value.reduce<string[]>((acc, optionId) => {
            const option = options.find(({ id }) => id === optionId);
            return option ? acc.concat(option.label) : acc;
        }, []);

        displayValue = selectedOptions.join(', ');
    } else {
        const option = options.find(({ id }) => id === value);
        displayValue = option?.label || '';
    }

    return (
        <span className={styles['advanced-select__value-span']}>
            {displayValue || placeholder}
        </span>
    );
};

// Custom Renderers
export const renderSelectOptionWithDescription = (
    option: OptionWithDescription,
) => (
    <span className={styles['advanced-select__default-item-label']}>
        <span>{option.label}</span>
        {option.description && <p>{option.description}</p>}
    </span>
);
