import { Profile, RoleTypes } from '../api/types';
import { useMeQueryData } from '../queries/user';

export const getFullName = (user?: Profile) => {
    const fullName = [user?.firstname, user?.lastname];
    if (fullName.some((v) => !!v)) {
        return fullName.join(' ');
    }
    return user?.email || user?.phone;
};

export const useCurrentRole = (): RoleTypes | undefined => {
    const me = useMeQueryData();
    return me?.activeRole?.type;
};
