import {
    listItemAvatarClasses,
    listItemTextClasses,
    SxProps,
    Theme,
} from '@mui/material';

export const listItem: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(4, 0),
    border: '1px solid transparent',
    borderBottomColor: 'custom.gray.tableDivider',
    alignItems: 'center',

    '&:focus-visible': {
        outline: 'none',
        border: '1px solid',
        borderColor: 'info.main',
        borderRadius: 1.5,
    },

    '&:hover': {
        cursor: 'pointer',
        [`& .${listItemTextClasses.primary}`]: {
            color: 'info.main',
        },
    },

    [`& .${listItemAvatarClasses.root}`]: {
        display: 'flex',
        minWidth: 'auto',
        marginRight: 4,

        '& img': {
            maxWidth: 40,
        },
    },

    [`& .${listItemTextClasses.root}`]: {
        margin: theme.spacing(0, 6, 0, 0),
    },
});

export const highlighted: SxProps<Theme> = {
    border: '1px solid',
    borderColor: 'info.main',
    boxShadow: '0px 0px 0px 3px rgba(63, 93, 244, 0.12)',
    px: { xs: 2, md: 4 },
    mx: { xs: -2, md: -4 },
    width: { xs: 'calc(100% + 16px)', md: 'calc(100% + 32px)' },
};

export const notHighlighted: SxProps<Theme> = {
    border: '1px solid transparent',
    borderBottomColor: 'custom.gray.tableDivider',
    boxShadow: 'none',
    px: 0,
    mx: 0,
    width: 1,
    transition:
        'border-color 0.5s ease-out, margin 0s ease-out 2s, box-shadow 0.5s ease-out, padding 0s ease-out 2s, width 0s ease-out 2s',
};

export const connectButton: SxProps = {
    minWidth: 96,

    '&:visited': {
        color: 'primary.dark',
    },
};

export const comingSoon: SxProps<Theme> = {
    padding: (theme) => theme.spacing(3.5, 4),
    border: '1px solid',
    borderColor: 'custom.gray.light400',
    borderRadius: 1.5,

    '&:hover': {
        cursor: 'pointer',
        borderColor: 'info.main',
    },

    [`& img`]: {
        width: 20,
    },
};
