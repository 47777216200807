import React from 'react';
import { Box, Button, Divider, IconButton } from '@mui/material';
import * as styles from './styles';
import { Navigation } from './Navigation/Navigation';
import { ContentHeader } from './ContentHeader';
import { SidebarDrawer } from '../SideBar/SidebarDrawer';
import { FullArrowLeftIcon } from './FullArrowLeftIcon';
import { useSidebar } from '../../contexts/sidebar';
import { useNavigate } from 'react-router';
import { MenuIcon } from './MenuIcon';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { useIs10DLCRequired } from '../../main/settings/Compliance/useIs10DLCRequired';
import { TenDLCBanner } from '../../main/settings/Compliance/TenDLCBanner';
import { BANNER_HEIGHT } from '../../components/Banner/Banner';

type Props = {
    children: React.ReactNode;
    title: string;
    category?: string;
    description?: React.ReactNode;
    action?: React.ReactNode | null;
};

export function SettingsLayout({
    children,
    title,
    description,
    action,
    category = 'Settings',
}: Props) {
    const { isCollapsed, toggleOpen, isOpened } = useSidebar();
    const navigate = useNavigate();
    const is10DLCRequired = useIs10DLCRequired();

    return (
        <>
            {is10DLCRequired && <TenDLCBanner />}
            <Box
                sx={[
                    styles.layout,
                    {
                        maxHeight: is10DLCRequired
                            ? `calc(100vh - ${BANNER_HEIGHT}px)`
                            : '100vh',
                    },
                ]}
            >
                <Box sx={styles.layoutHeader}>
                    <IconButton
                        onClick={toggleOpen}
                        sx={styles.layoutHeaderMenuButton}
                    >
                        {isOpened ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </Box>
                <SidebarDrawer>
                    <Box flex={1}>
                        <Box sx={styles.header}>
                            {isCollapsed ? (
                                <IconButton
                                    sx={styles.shrinkHomeButton}
                                    onClick={() => navigate('/')}
                                >
                                    <FullArrowLeftIcon />
                                </IconButton>
                            ) : (
                                <Button
                                    sx={styles.homeButton}
                                    variant="text"
                                    startIcon={<FullArrowLeftIcon />}
                                    onClick={() => navigate('/')}
                                >
                                    Home
                                </Button>
                            )}
                        </Box>
                        <Divider />
                        <Navigation />
                        <Divider sx={{ marginTop: 4 }} />
                    </Box>
                </SidebarDrawer>
                <Box sx={styles.container}>
                    <ContentHeader
                        title={title}
                        description={description}
                        category={category}
                        action={action}
                    />
                    <Box sx={styles.layoutContainer}>{children}</Box>
                </Box>
            </Box>
        </>
    );
}
