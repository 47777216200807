import { Box, Typography } from '@mui/material';
import { useReseller } from '../../../queries/user';

export const Reseller = () => {
    const { data: reseller } = useReseller();

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
            }}
        >
            <Box
                sx={{
                    width: 1,
                    maxWidth: 480,
                    p: 2,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Typography fontSize={56} lineHeight="56px">
                    {reseller?.teamNotificationIcon
                        ? reseller.teamNotificationIcon
                        : '🧑‍💻'}
                </Typography>
                <Typography variant="h2" mt={3}>
                    {reseller?.teamNotificationTitle
                        ? reseller.teamNotificationTitle
                        : 'Contact Reseller'}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {reseller?.teamNotificationMessage ? (
                        reseller.teamNotificationMessage
                    ) : (
                        <>
                            <Typography variant="body3" component={Box}>
                                This Clerk Chat team is managed by a reseller.
                            </Typography>
                            <Typography variant="body3" component={Box}>
                                Please contact the reseller to add users or
                                inboxes to the team.
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
