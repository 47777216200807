import { Conversation, Message } from '../../api/types';
import throttle from 'lodash/throttle';
import { queryClient } from '../../queries/queryClient';
import {
    CONVERSATION_MESSAGES_KEY,
    UNREADS_COUNT_KEY,
} from '../../queries/messages';
import { MessageEvent } from '../types';
import { InfiniteData } from '@tanstack/react-query';

interface MessageUpdatePayload {
    message: Message;
    conversation: Conversation;
}

export const MESSAGE_UPDATED_EVENT = MessageEvent.Updated;
export type MessageUpdateHandler = ({
    conversation,
    message,
}: MessageUpdatePayload) => void | Promise<void>;

let pairs: Record<string, [Message, Conversation]> = {};
const throttledUpdates = throttle(() => {
    const values = Object.values(pairs);
    if (values.some(([{ inbound }]) => inbound)) {
        void queryClient.invalidateQueries({
            queryKey: [UNREADS_COUNT_KEY],
        });
    }
    values.forEach(([message, conversation]) => {
        queryClient.setQueryData<InfiniteData<Message[]>>(
            [CONVERSATION_MESSAGES_KEY, conversation.id],
            (prev) => {
                if (!prev) {
                    return prev;
                }
                return {
                    ...prev,
                    pages: prev.pages.map((page) =>
                        page.map((m) => {
                            if (m.id === message.id) {
                                return { ...m, ...message };
                            }
                            return m;
                        }),
                    ),
                };
            },
        );
    });

    pairs = {};
}, 1_000);

export const handleUpdatedMessageEvent = ({
    message,
    conversation,
}: MessageUpdatePayload) => {
    pairs[`${message.id}:${conversation.id}`] = [message, conversation];
    throttledUpdates();
};
