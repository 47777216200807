import { Inbox } from '../../../api/types';
import { Header } from '../Header';
import { Box, Divider } from '@mui/material';
import { useSystemWorkflowQuery } from '../../../queries/workflows';
import { Form } from './Form';
import { createNewWorkflow } from '../../../components/workflows/utils';
import Loading from '../../../components/Loading/Loading';

const newWorkflow = createNewWorkflow();

export function OutOfOffice({ inbox }: { inbox: Inbox }) {
    const { data: workflow, isLoading } = useSystemWorkflowQuery(inbox.id);

    return (
        <>
            <Header
                title="Out of Office"
                description="Automatically send messages during your off-hours."
            />
            <Divider />

            {isLoading ? (
                <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Loading />
                </Box>
            ) : (
                <Form
                    inboxId={inbox.id}
                    workflow={
                        workflow || {
                            ...newWorkflow,
                            name: `${inbox.name} Out of Office`,
                            inboxes: [inbox],
                            enabled: false,
                        }
                    }
                />
            )}
        </>
    );
}
