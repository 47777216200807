import { useInboxConversations } from '../../queries/conversations';
import { ConversationsFilter, ConversationStatus } from '../../api/types';
import { Virtuoso } from 'react-virtuoso';
import { UUID } from '../../types/uuid';
import { ConversationListItem } from './Conversation/ListItem';
import { NoConversations } from './Conversation/NoConversations';
import { FetchingSkeleton } from '../ConversationsList/FetchingSkeleton';
import { ListSkeleton } from './ListSkeleton';
import { useConversationsSearch } from './conversations.query';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { useInboxNavigate } from './hooks/use-inbox-navigate';
import { useLocation } from 'react-router';

type Props = {
    inboxId: UUID;
    term?: string;
    filter?: ConversationsFilter;
};

const getStatus = (
    type?:
        | ConversationStatus.Active
        | ConversationStatus.Archived
        | undefined
        | string,
): ConversationStatus.Active | ConversationStatus.Archived | undefined =>
    (type && ConversationStatus.Active === type) ||
    ConversationStatus.Archived === type
        ? type
        : undefined;

export const InboxConversationsList = ({ inboxId, filter, term }: Props) => {
    const debounced = useDebounce(term, 450);
    const [query, setQuery] = useState<string>('');
    const {
        data: conversations = [],
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
    } = useInboxConversations(inboxId, filter);
    const search = useConversationsSearch({
        inboxId,
        query,
        status: getStatus(filter?.type),
    });
    const data = useMemo(
        () =>
            query
                ? search.data?.pages.map(({ data }) => data).flat() || []
                : conversations,
        [conversations, query, search.data?.pages],
    );

    const { pathname } = useLocation();

    const [focusedIndex, linksRef, isKeyboardNav] = useInboxNavigate(
        data.findIndex(
            (conv) =>
                `/inbox/${conv.inboxId}/conversations/${conv.id}/` === pathname,
        ),
    );

    useEffect(() => {
        setQuery(debounced || '');
    }, [debounced]);

    if (isLoading || search.isLoading) {
        return <ListSkeleton />;
    }

    if (!data?.length) {
        return <NoConversations inboxId={inboxId} filter={filter} />;
    }

    return (
        <Virtuoso
            data={data}
            overscan={1050}
            increaseViewportBy={200}
            endReached={() => {
                if (query) {
                    if (search.hasNextPage && !search.isFetchingNextPage) {
                        search.fetchNextPage();
                    }
                } else {
                    if (hasNextPage && !isFetchingNextPage) {
                        fetchNextPage();
                    }
                }
            }}
            itemContent={(index, conversation) => (
                <ConversationListItem
                    conversation={conversation}
                    isFocused={index === focusedIndex && !!isKeyboardNav}
                    ref={(el) => (linksRef.current[index] = el)}
                />
            )}
            components={{
                Footer:
                    isFetchingNextPage || search.isFetchingNextPage
                        ? () => <FetchingSkeleton />
                        : undefined,
            }}
        />
    );
};
