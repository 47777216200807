import {
    QueryObserverOptions,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import { client } from '../../../api/http';
import { BlockListItem } from './types';
import { queryClient } from '../../../queries/queryClient';
import { useTrack } from '../../../contexts/analytics';
import { CONVERSATION_SEARCH_KEY } from '../../../components/Inbox/conversations.query';
import { CONVERSATIONS_LIST_KEY } from '../../../queries/conversations';
import { CONVERSATION_MESSAGES_KEY } from '../../../queries/messages';

enum Scope {
    Team = 'team',
}

const BLOCK_LIST_KEY = ['block-list'];
export const useBlockList = (options?: {
    select?: QueryObserverOptions<BlockListItem[]>['select'];
    refetchOnMount?: boolean;
}) =>
    useQuery({
        queryKey: BLOCK_LIST_KEY,
        queryFn: () =>
            client.get<BlockListItem[]>('/block-list').then(({ data }) => data),
        initialData: [],
        placeholderData: [],
        refetchOnMount: options?.refetchOnMount ? 'always' : false,
        select: options?.select,
        staleTime: 2_500,
        meta: {
            shouldCache: true,
        },
    });

export const useUnblock = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (phone: string) =>
            client
                .delete<void>('/block-list', {
                    data: { phone, scope: { type: Scope.Team } },
                })
                .then(({ data }) => data),
        onSuccess: (_, phone) => {
            track('phone_unblocked');
            queryClient.setQueryData<BlockListItem[]>(
                BLOCK_LIST_KEY,
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return prev.filter((item) => item.phone !== phone);
                },
            );
            queryClient.invalidateQueries({ queryKey: BLOCK_LIST_KEY });
        },
    });
};

export const useBlock = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (phones: string | string[]) =>
            Promise.all(
                (Array.isArray(phones) ? phones : [phones]).map((phone) =>
                    client
                        .post<BlockListItem>('/block-list', {
                            phone,
                            scope: { type: Scope.Team },
                        })
                        .then(({ data }) => data),
                ),
            ),
        onSuccess: (blocked) => {
            track('phone_blocked', { count: blocked.length });

            queryClient.setQueryData<BlockListItem[]>(BLOCK_LIST_KEY, (prev) =>
                prev ? prev.concat(blocked) : prev,
            );
            queryClient.invalidateQueries({ queryKey: BLOCK_LIST_KEY });
            queryClient.invalidateQueries({
                queryKey: [CONVERSATION_SEARCH_KEY],
            });
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
            queryClient.invalidateQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY],
            });
        },
    });
};
