import { useState, useMemo, useEffect, forwardRef } from 'react';
import * as styles from './styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    InputBase,
    Link,
    List,
    ListItemButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useMenu } from '../../../hooks/use-menu';
import { ToolbarPopper } from '../ToolbarPopper/ToolbarPopper';
import { SearchIcon } from '../../../icons/common/SearchIcon';
import { Virtuoso } from 'react-virtuoso';
import { ITEM_HEIGHT } from './styles';
import { AddCohortIcon } from '../../../icons/common/CAddCohortIcon';
import {
    useAddContactsToCohortsMutation,
    useCohortCreateQuery,
    useCohorts,
    useGetCohortsForContact,
    useRemoveContactsFromCohortsMutation,
} from '../../../queries/cohorts';
import { UUID } from '../../../types/uuid';
import Loading from '../../Loading/Loading';
import { PlusIcon } from '../../../icons/common/CPlusIcon';
import { CheckIcon } from '../../../icons/common/CCheckIcon';
import { CloseIcon } from '../../../icons/common/CCloseIcon';

const MAX_ITEMS = 5;

interface Props {
    contactId: UUID;
    disabled?: boolean;
}

export const AssignToCohort = (props: Props) => {
    const menu = useMenu();
    const [searchTerm, setSearchTerm] = useState('');
    const [showCreateCohort, setShowCreateCohort] = useState(false);
    const [newCohortName, setNewCohortName] = useState('');

    const { mutateAsync: createCohort } = useCohortCreateQuery();
    const { isPending: isDataPending, data: cohorts = [] } = useCohorts({
        enabled: menu.open,
    });
    const { isPending: isSelectedPending, data: contactCohorts = [] } =
        useGetCohortsForContact(props.contactId, menu.open);
    const { mutateAsync: addToCohorts } = useAddContactsToCohortsMutation();
    const { mutateAsync: removeFromCohorts } =
        useRemoveContactsFromCohortsMutation();

    const isPending = isDataPending || isSelectedPending;

    const filteredCohorts = useMemo(() => {
        return cohorts
            .filter((cohort) =>
                cohort.name.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
    }, [cohorts, searchTerm]);

    const listHeight = useMemo(() => {
        if (filteredCohorts.length > MAX_ITEMS) {
            return MAX_ITEMS * ITEM_HEIGHT;
        }

        return filteredCohorts.length * ITEM_HEIGHT;
    }, [filteredCohorts]);

    useEffect(() => {
        if (!menu.open) {
            setSearchTerm('');
            setNewCohortName('');
            setShowCreateCohort(false);
        }
    }, [menu.open]);

    return (
        <>
            <Tooltip title="Add to cohort">
                <span>
                    <IconButton
                        disabled={props.disabled}
                        onClick={menu.handleOpen}
                        color="primary"
                    >
                        <AddCohortIcon />
                    </IconButton>
                </span>
            </Tooltip>

            {menu.open && (
                <ToolbarPopper
                    anchorEl={menu.anchorEl}
                    handleClose={menu.handleClose}
                    header="Add to cohort"
                    disableContentPadding
                    width={256}
                >
                    {isPending && <Loading />}

                    {!isPending && cohorts.length === 0 && (
                        <Box px={3} py={2}>
                            <Typography variant="body3">
                                You currently do not have any saved cohorts.
                                Please navigate to{' '}
                                <Link href="/contacts" color="info.main">
                                    the contacts page
                                </Link>{' '}
                                to create a new one or add it below.
                            </Typography>
                        </Box>
                    )}

                    {!isPending && cohorts.length > 0 && (
                        <>
                            <Stack>
                                <Box px={1}>
                                    <InputBase
                                        autoFocus
                                        placeholder="Search"
                                        sx={styles.searchInput}
                                        value={searchTerm}
                                        onChange={(event) => {
                                            setSearchTerm(
                                                (
                                                    event.target as HTMLInputElement
                                                ).value,
                                            );
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </Box>
                                <Divider sx={styles.divider} />
                            </Stack>
                            <Virtuoso
                                data={filteredCohorts}
                                style={{
                                    height: `${listHeight}px`,
                                }}
                                components={{
                                    List: forwardRef(
                                        function VirtuosoList(props, ref) {
                                            return (
                                                <List
                                                    {...props}
                                                    component="div"
                                                    ref={ref}
                                                    sx={styles.list}
                                                />
                                            );
                                        },
                                    ),
                                }}
                                itemContent={(_, cohort) => {
                                    const isSelected = contactCohorts.some(
                                        ({ id }) => id === cohort.id,
                                    );

                                    return (
                                        <ListItemButton
                                            key={cohort.id}
                                            onClick={() => {
                                                if (isSelected) {
                                                    removeFromCohorts({
                                                        cohortIds: [cohort.id],
                                                        contactIds: [
                                                            props.contactId,
                                                        ],
                                                    });
                                                } else {
                                                    addToCohorts({
                                                        cohortIds: [cohort.id],
                                                        contactIds: [
                                                            props.contactId,
                                                        ],
                                                    });
                                                }
                                            }}
                                            sx={styles.itemWithCheckbox}
                                        >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                flex={1}
                                                alignItems="center"
                                            >
                                                <Checkbox
                                                    color="info"
                                                    size="small"
                                                    checked={isSelected}
                                                />
                                                <Typography
                                                    variant="body3"
                                                    noWrap
                                                    flex={1}
                                                >
                                                    {cohort.name}
                                                </Typography>
                                            </Stack>
                                        </ListItemButton>
                                    );
                                }}
                            />
                        </>
                    )}

                    <Divider sx={styles.divider} />
                    <Box px={1}>
                        {showCreateCohort ? (
                            <Stack
                                direction="row"
                                spacing={2}
                                px={2}
                                py={1.5}
                                alignItems="center"
                            >
                                <InputBase
                                    autoFocus
                                    fullWidth
                                    value={newCohortName}
                                    sx={styles.addCohortInput}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            createCohort({
                                                name: newCohortName,
                                                contactIds: [props.contactId],
                                            });
                                            setNewCohortName('');
                                            setShowCreateCohort(false);
                                        } else if (event.key === 'Escape') {
                                            setNewCohortName('');
                                            setShowCreateCohort(false);
                                        }
                                    }}
                                    onChange={(event) => {
                                        setNewCohortName(
                                            (event.target as HTMLInputElement)
                                                .value,
                                        );
                                    }}
                                />
                                <Stack direction="row" spacing={1}>
                                    <IconButton
                                        onClick={() => {
                                            createCohort({
                                                name: newCohortName,
                                                contactIds: [props.contactId],
                                            });
                                            setNewCohortName('');
                                            setShowCreateCohort(false);
                                        }}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setNewCohortName('');
                                            setShowCreateCohort(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        ) : (
                            <Button
                                fullWidth
                                variant="text"
                                color="info"
                                sx={styles.alightLeftButton}
                                startIcon={<PlusIcon />}
                                onClick={() => setShowCreateCohort(true)}
                            >
                                Add new
                            </Button>
                        )}
                    </Box>
                </ToolbarPopper>
            )}
        </>
    );
};
