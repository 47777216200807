import { Stack, Grid, Typography, Box, Hidden, Link } from '@mui/material';
import logo from '../../assets/images/clerk-logo.png';
import { comment, container, reviewContainer } from './styles';
import candelaWatermark from './assets/candela_wordmark.png';

type AuthLayoutProps = {
    children: React.ReactNode;
};

export const AuthLayout = (props: AuthLayoutProps) => (
    <Stack
        p={6}
        height="100vh"
        direction="row"
        justifyContent="space-between"
        sx={container}
    >
        <Grid
            container
            direction="column"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
        >
            <Stack
                alignSelf="flex-start"
                spacing={2}
                direction="row"
                alignItems="center"
                mt={4}
                ml={4}
            >
                <img src={logo} alt="Clerk logo" width={32} height={32} />
                <Typography variant="h4" color="primary.dark">
                    Clerk Chat
                </Typography>
            </Stack>
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                flex={1}
            >
                {props.children}
            </Grid>
            <Stack spacing={6} width={400}>
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    align="center"
                >
                    By proceeding you acknowledge that you have read, understood
                    and agree to our{' '}
                    <Link
                        variant="body4"
                        href="https://clerk.chat/legal/terms"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms and Conditions
                    </Link>
                    .
                </Typography>

                <Stack
                    spacing={8}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="body4" color="custom.gray.super">
                        © 2024 Clerk Chat
                    </Typography>

                    <Link
                        variant="body4"
                        href="https://clerk.chat/legal/privacy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </Link>

                    <Link
                        variant="body4"
                        href="https://support.clerk.chat"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Support
                    </Link>
                </Stack>
            </Stack>
        </Grid>
        <Hidden smDown>
            <Grid container width="30vw" height="100%" sx={reviewContainer}>
                <Stack alignSelf="flex-end" flex={1} spacing={4} sx={comment}>
                    <Typography variant="body2" color="white" fontWeight={500}>
                        &quot;Finally a phone system that works the way we work!
                        Absolutely love it!&quot;
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Stack spacing={1}>
                            <Typography variant="h4" color="white">
                                Cristina Lorez
                            </Typography>
                            <Typography variant="body4" color="white">
                                COO at Candela
                            </Typography>
                        </Stack>
                        <Box
                            component="img"
                            alignSelf="center"
                            src={candelaWatermark}
                            alt="Candela"
                        />
                    </Grid>
                </Stack>
            </Grid>
        </Hidden>
    </Stack>
);
