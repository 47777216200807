import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedSelect } from '../../../components/EnhancedSelect/EnhancedSelect';
import { OutOfOfficeInputs, scheduleTypes } from './constants';
import { SettingsRow } from '../SettingsRow';

export function ScheduleTypeRow({ disabled = false }: { disabled?: boolean }) {
    const { control } = useFormContext<OutOfOfficeInputs>();

    return (
        <SettingsRow label="Working hours">
            <Controller
                control={control}
                name="scheduleType"
                render={({ field }) => (
                    <EnhancedSelect
                        options={Object.keys(scheduleTypes).map((type) => ({
                            id: type,
                            label: scheduleTypes[
                                type as keyof typeof scheduleTypes
                            ],
                        }))}
                        value={field.value}
                        onChange={(val) => {
                            field.onChange(val);
                        }}
                        sx={{
                            width: {
                                xs: 1,
                                md: 320,
                            },
                        }}
                        disabled={disabled}
                    />
                )}
            />
        </SettingsRow>
    );
}
