import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addPaymentMethod,
    cancelSubscription,
    deletePaymentMethod,
    getInvoices,
    getPaymentMethods,
    getSubscription,
    PaymentMethods,
    renewSubscription,
    setDefaultPaymentMethod,
    subscribeToFeature,
    updateMainSubscription,
} from '../api/billing';
import { queryClient } from './queryClient';
import { CURRENT_USER_KEY } from './user';

const TEAM_PLAN_KEY = 'TEAM_PLAN_KEY';
const TEAM_PAYMENT_METHODS_KEY = 'TEAM_PAYMENT_METHODS_KEY';
const TEAM_INVOICES_KEY = 'TEAM_INVOICES_KEY';

export function useTeamSubscription({
    teamId,
    enabled,
}: {
    teamId?: string;
    enabled: boolean;
}) {
    return useQuery({
        queryKey: [TEAM_PLAN_KEY, teamId],
        queryFn: getSubscription,
        enabled,
        retry: false,
    });
}

export const useDeletePaymentMethod = () =>
    useMutation({
        mutationFn: deletePaymentMethod,
        onSuccess: ({ data: deletedMethod }) => {
            queryClient.setQueriesData(
                { queryKey: [TEAM_PAYMENT_METHODS_KEY], exact: false },
                (prev?: PaymentMethods) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        paymentMethods:
                            prev.paymentMethods.filter(
                                (paymentMethod) =>
                                    paymentMethod.id !== deletedMethod.id,
                            ) ?? [],
                        defaultPaymentMethodId: prev.defaultPaymentMethodId,
                    };
                },
            );
        },
    });

export const useUpdateDefaultPaymentMethod = () =>
    useMutation({
        mutationFn: setDefaultPaymentMethod,
        onSuccess: ({ invoice_settings }) => {
            queryClient.setQueriesData(
                { queryKey: [TEAM_PAYMENT_METHODS_KEY], exact: false },
                (prev?: PaymentMethods) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        paymentMethods: prev.paymentMethods,
                        defaultPaymentMethodId:
                            invoice_settings.default_payment_method,
                    };
                },
            );
        },
    });

export const useAddPaymentMethod = () =>
    useMutation({
        mutationFn: addPaymentMethod,
        onSuccess: (newPaymentMethod) => {
            queryClient.setQueriesData(
                { queryKey: [TEAM_PAYMENT_METHODS_KEY], exact: false },
                (prev?: PaymentMethods) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        paymentMethods: [
                            ...prev.paymentMethods,
                            newPaymentMethod,
                        ],
                        defaultPaymentMethodId: prev.defaultPaymentMethodId,
                    };
                },
            );
        },
    });

export function useTeamPaymentMethods({
    teamId,
    enabled,
}: {
    teamId?: string;
    enabled: boolean;
}) {
    return useQuery({
        queryKey: [TEAM_PAYMENT_METHODS_KEY, teamId],
        queryFn: getPaymentMethods,
        enabled,
    });
}

export const useUpdateTeamSubscription = () =>
    useMutation({
        mutationFn: updateMainSubscription,
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [TEAM_PLAN_KEY],
                exact: false,
            });
            queryClient.refetchQueries({
                queryKey: [CURRENT_USER_KEY],
            });
            queryClient.invalidateQueries({
                queryKey: [TEAM_INVOICES_KEY],
            });
        },
    });

export const useSubscribeToFeature = () =>
    useMutation({
        mutationFn: subscribeToFeature,
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [CURRENT_USER_KEY],
            });
        },
    });

export const useCancelTeamSubscription = () =>
    useMutation({
        mutationFn: cancelSubscription,
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [TEAM_PLAN_KEY],
                exact: false,
            });
        },
    });

export const useRenewTeamSubscription = () =>
    useMutation({
        mutationFn: renewSubscription,
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [TEAM_PLAN_KEY],
                exact: false,
            });
            queryClient.refetchQueries({
                queryKey: [CURRENT_USER_KEY],
            });
        },
    });

export function useInvoices({
    teamId,
    enabled,
}: {
    teamId?: string;
    enabled: boolean;
}) {
    return useQuery({
        queryKey: [TEAM_INVOICES_KEY, teamId],
        queryFn: getInvoices,
        enabled,
    });
}
