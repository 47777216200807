import { Stack, Typography } from '@mui/material';

export function SettingsRow({
    label,
    children,
    alignItems,
}: {
    alignItems?: string;
    label: React.ReactNode;
    children: React.ReactNode;
}) {
    return (
        <Stack
            spacing={{
                xs: 1,
                md: 6,
            }}
            useFlexGap
            direction={{
                xs: 'column',
                md: 'row',
            }}
            alignItems={
                alignItems ?? {
                    xs: 'flex-start',
                    md: 'center',
                }
            }
        >
            <Typography variant="body3" color="primary.dark" flex={1}>
                {label}
            </Typography>
            <Stack direction="row" alignItems="center">
                {children}
            </Stack>
        </Stack>
    );
}
