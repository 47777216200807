import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { acceptFileFormats } from '../../../constants';
import { colors } from '../../../theme/palette';

export const DropZone = ({ onDrop }: { onDrop: (files: File[]) => void }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragging = (e: DragEvent, currentState: boolean) => {
        e.preventDefault();
        setIsDragging(currentState);
    };

    const handleDragLeave = (e: DragEvent) => {
        if (e.relatedTarget === null || e.relatedTarget === window) {
            handleDragging(e, false);
        }
    };

    const onFilesDrop = useCallback(
        (acceptedFiles: File[]) => {
            onDrop(acceptedFiles);
        },
        [onDrop],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFilesDrop,
        accept: acceptFileFormats.split(',').reduce((acc, type) => {
            return { ...acc, [type]: [] };
        }, {}),
        maxFiles: 10,
    });

    useEffect(() => {
        window.addEventListener('dragover', (e) => handleDragging(e, true));
        window.addEventListener('dragleave', (e) => handleDragLeave(e));
        window.addEventListener('drop', (e) => handleDragging(e, false));

        return () => {
            window.removeEventListener('dragover', (e) =>
                handleDragging(e, true),
            );
            window.removeEventListener('dragleave', (e) => handleDragLeave(e));
            window.removeEventListener('drop', (e) => handleDragging(e, false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            {...getRootProps()}
            sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                margin: 2,
                borderRadius: 1.5,
                border: '1px dashed',
                borderColor: 'info.main',
                background: colors.primary.blue.background,
                backdropFilter: 'blur(2px)',
                display: isDragging ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'grabbing',
            }}
        >
            <input {...getInputProps()} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexDirection: 'column',
                }}
            >
                <Typography fontSize={56} lineHeight={1}>
                    📥
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h2">Drag & drop files here</Typography>
                    <Typography variant="body3">
                        To send them in a quick way!
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
