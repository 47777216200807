import { CountryCode } from 'libphonenumber-js';
import { CAFlagIcon } from './icons/CAFlagIcon';
import { USFlagIcon } from './icons/USFlagIcon';
import { MXFlagIcon } from './icons/MXFlagIcon';
import { GEFlagIcon } from './icons/GEFlagIcon';
import { BRFlagIcon } from './icons/BRFlagIcon';
import { AUFlagIcon } from './icons/AUFlagIcon';
import { GBFlagIcon } from './icons/GBFlagIcon';
import { FC } from 'react';
import { ClerkIconProps } from '../types/clerk-icon.props';

type CountryItem = { name: string; code: CountryCode };

export const countryFlags: Record<string, FC<ClerkIconProps>> = {
    CA: CAFlagIcon,
    US: USFlagIcon,
    MX: MXFlagIcon,
    GE: GEFlagIcon,
    BR: BRFlagIcon,
    AU: AUFlagIcon,
    GB: GBFlagIcon,
} as const;

export const countriesList: CountryItem[] = [
    { name: 'Canada', code: 'CA' },
    { name: 'United States', code: 'US' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'Australia', code: 'AU' },
    { name: 'Germany', code: 'GE' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Brazil', code: 'BR' },
];
