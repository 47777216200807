import { Inbox, InboxStatus } from '../../api/types';
import dayjs from 'dayjs';
import AttentionIcon from '../../assets/icons/different/attention.svg?react';
import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { statusIcon } from './styles';

function getEmoji(
    status:
        | InboxStatus.PENDING
        | InboxStatus.PORT_SUBMITTED
        | InboxStatus.PENDING_HOSTING
        | InboxStatus.PORT_FAILED
        | InboxStatus.PENDING_VALIDATION
        | InboxStatus.VALIDATION_FAILED,
): string {
    switch (status) {
        case InboxStatus.PENDING:
            return 'ℹ️';
        case InboxStatus.PORT_SUBMITTED:
        case InboxStatus.PENDING_HOSTING:
        case InboxStatus.PENDING_VALIDATION:
        case InboxStatus.VALIDATION_FAILED:
            return '🕑';
        case InboxStatus.PORT_FAILED:
            return '🔴';
    }
}

export function getStatusIcon(status: InboxStatus): ReactElement | null {
    switch (status) {
        case InboxStatus.PENDING:
        case InboxStatus.PORT_SUBMITTED:
        case InboxStatus.PENDING_HOSTING:
        case InboxStatus.PORT_FAILED:
            return (
                <Typography fontSize={16} sx={statusIcon}>
                    {getEmoji(status)}
                </Typography>
            );
        case InboxStatus.DISABLED:
            return (
                <Box sx={statusIcon}>
                    <AttentionIcon />
                </Box>
            );
        default:
            return null;
    }
}

export function getStatusText(status: InboxStatus, inbox: Inbox): string {
    switch (status) {
        case InboxStatus.PENDING:
            return 'Please click to verify phone';
        case InboxStatus.PORT_SUBMITTED:
            return inbox.requestedActivationDate
                ? `Verified. Your number will be active on ${dayjs(
                      inbox.requestedActivationDate,
                  ).format('MMMM D, hh:mm A')}`
                : 'Your phone is pending activation';
        case InboxStatus.PENDING_HOSTING:
        case InboxStatus.PENDING_VALIDATION:
            return 'Verified. Your number will be active in the next few minutes.';
        case InboxStatus.VALIDATION_FAILED:
            return 'Verified, but further configuration may be needed with our provider. Support has been notified.';
        case InboxStatus.PORT_FAILED:
            return 'Verification failed. Please schedule onboarding here https://calendly.com/clerkchat/demo';
        case InboxStatus.DISABLED:
            return 'This phone number has been disabled';
        default:
            return '';
    }
}

export const getPath = (inbox: Inbox) => {
    switch (inbox.status) {
        case InboxStatus.ACTIVE:
            return `/inbox/${inbox.id}`;
        case InboxStatus.PENDING:
            return `/inbox/${inbox.id}/verify/customer`;
        case InboxStatus.PORT_SUBMITTED:
        default:
            return null;
    }
};
