import { AttachmentIcon } from '../../../icons/common/AttachmentIcon';
import { getSquare } from '../../../theme/style.helpers';
import { Stack, Typography } from '@mui/material';
import { pluralize } from '../../../utils/pluralize';

export function AttachmentsStatus({ count }: { count: number }) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <AttachmentIcon
                sx={{
                    ...getSquare(16),
                    color: 'custom.gray.super',
                }}
            />
            <Typography variant="body4" color="custom.gray.super">
                {pluralize(
                    count,
                    'Attachment',
                    'Attachments',
                    'No attachments',
                    count === 1 ? '' : String(count),
                )}
            </Typography>
        </Stack>
    );
}
