import { useEnabledFeature, useMeQueryData } from '../../../queries/user';
import { useMemo } from 'react';
import { ClerkPermission, Feature, RoleTypes } from '../../../api/types';
import { useIsCampaignRegistered } from '../../../hooks/useIsCampaignRegistered';
import { useIsDownMd } from '../../../hooks/useIsDownMd';

export function useIs10DLCRequired() {
    const me = useMeQueryData();
    const ffEnabled = useEnabledFeature(Feature.BrandOptInIntegration);
    const isCampaignRegistered = useIsCampaignRegistered();
    const isMobile = useIsDownMd();

    return useMemo(() => {
        // hide on mobile
        if (isMobile) {
            return false;
        }

        // if user is not logged in or is on free tier
        if (!me || me.activeTeam.tier === 'free') {
            return false;
        }

        // if user is not an admin or workspace owner
        if (
            !me.activeRole ||
            ![RoleTypes.Admin, RoleTypes.WorkspaceOwner].includes(
                me.activeRole.type,
            )
        ) {
            return false;
        }

        // if user does not have required permissions
        if (
            !(me?.activeRole?.permissions ?? []).includes(
                ffEnabled
                    ? ClerkPermission.BrandAccess
                    : ClerkPermission.ModifyTeam,
            )
        ) {
            return false;
        }

        // if user is not registered for 10DLC yet
        return !isCampaignRegistered;
    }, [isMobile, me, ffEnabled, isCampaignRegistered]);
}
