import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const CustomDownload = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5744 4.7915C8.89721 4.7915 7.44216 5.86082 6.82611 7.32549C6.74258 7.52409 6.56265 7.66566 6.34997 7.70012C4.40325 8.01556 3.125 9.63918 3.125 11.3742C3.125 13.4863 4.85448 15.2082 7 15.2082L13.75 15.2082C15.4755 15.2082 16.875 13.8083 16.875 12.0806C16.875 10.7802 16.0876 9.58983 14.9996 9.11416C14.7839 9.01986 14.6395 8.81238 14.626 8.57737C14.505 6.4712 12.7415 4.7915 10.5744 4.7915ZM5.81596 6.53121C6.6793 4.79784 8.47801 3.5415 10.5744 3.5415C13.272 3.5415 15.506 5.54073 15.8397 8.13661C17.2134 8.89739 18.125 10.4353 18.125 12.0806C18.125 14.4978 16.1667 16.4582 13.75 16.4582L7 16.4582C4.17496 16.4582 1.875 14.1874 1.875 11.3742C1.875 9.13117 3.44771 7.07042 5.81596 6.53121ZM10 7.2915C10.3452 7.2915 10.625 7.57133 10.625 7.9165V11.4076L11.2247 10.8079C11.4688 10.5638 11.8645 10.5638 12.1086 10.8079C12.3527 11.052 12.3527 11.4477 12.1086 11.6918L10.4419 13.3584C10.1979 13.6025 9.80214 13.6025 9.55806 13.3584L7.89139 11.6918C7.64731 11.4477 7.64731 11.052 7.89139 10.8079C8.13547 10.5638 8.5312 10.5638 8.77528 10.8079L9.375 11.4076V7.9165C9.375 7.57133 9.65482 7.2915 10 7.2915Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
