import { SxProps } from '@mui/material';

export const newConversation = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
};

export const message: SxProps = {
    flexShrink: 0,
};
