import { SxProps, Theme } from '@mui/material';
import { getSquare } from '../../theme/style.helpers';
import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';

export const header: SxProps = {
    display: 'grid',
    gridTemplateRows: '60px auto 1fr',
    width: 1,
    maxWidth: [1, 1, 300],
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'divider',
};
export const topContent: SxProps = {
    height: HEADER_HEIGHT,
    p: 4,
    width: 1,
    maxWidth: [1, 1, 300],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'divider',
};
export const inboxIcon: SxProps<Theme> = {
    ...getSquare(24),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    lineHeight: '24px',
};
