import { createRoot } from 'react-dom/client';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

import { ToastProvider } from './containers/toast/reducer';
import { ModalProvider } from './containers/modal/reducer';
import { AnalyticsProvider } from './contexts/analytics';
import * as Sentry from '@sentry/react';
import { persister, queryClient } from './queries/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { App } from './app';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { MessageUpdateProvider } from './components/MessageForm/MessageUpdateContext';
import { ignoredErrors } from './error.utils';
import { GlobalErrorView } from './main/error-boundary/GlobalErrorView';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import React from 'react';
import { trackPage } from './services/analytics';
import { UtmMiddleware } from './utils/utm-middleware';

/* Sentry must be initialized before the app is imported */
Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    environment: import.meta.env.MODE,
    ignoreErrors: ignoredErrors,
    enabled: import.meta.env.PROD,
    tracesSampleRate: 0.2,
    attachStacktrace: true,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
});

function startApp() {
    const domNode = document.getElementById('app');
    const root = createRoot(domNode!);

    const analyticsMiddleware = new UtmMiddleware();
    analyticsMiddleware.bootstrap().then(() => {
        trackPage('App Loaded');
    });

    root.render(
        <ThemeProvider theme={theme}>
            <Sentry.ErrorBoundary fallback={GlobalErrorView} showDialog>
                <PersistQueryClientProvider
                    client={queryClient}
                    persistOptions={{
                        persister,
                        dehydrateOptions: {
                            shouldDehydrateQuery: (query) => {
                                // Only persist queries that have the meta.shouldCache set to true
                                // See https://github.com/TanStack/query/discussions/3568
                                return query.meta?.shouldCache === true;
                            },
                        },
                    }}
                >
                    <CssBaseline />
                    <GlobalStyles
                        styles={{ body: { fontSize: 14, lineHeight: '20px' } }}
                    />
                    <ModalProvider>
                        <AnalyticsProvider>
                            <MessageUpdateProvider>
                                <BrowserRouter>
                                    <ToastProvider>
                                        <App />
                                    </ToastProvider>
                                </BrowserRouter>
                            </MessageUpdateProvider>
                        </AnalyticsProvider>
                    </ModalProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </PersistQueryClientProvider>
            </Sentry.ErrorBoundary>
        </ThemeProvider>,
    );
}

startApp();
