import { useEffect, useRef, useState } from 'react';

export const useInboxNavigate = (selectedIndex: number) => {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [isKeyboardNav, setIsKeyboardNav] = useState(false);
    const linksRef = useRef<(HTMLButtonElement | null)[]>([]);
    useEffect(() => {
        if (linksRef.current[focusedIndex]) {
            isKeyboardNav
                ? linksRef.current[focusedIndex].focus()
                : linksRef.current[focusedIndex].blur();
        }
    }, [focusedIndex, isKeyboardNav]);

    useEffect(() => {
        setFocusedIndex(selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        function keyHandler(this: Document, e: KeyboardEvent) {
            if (e.code === 'ArrowUp' && e.shiftKey) {
                setFocusedIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex,
                );
                setIsKeyboardNav(true);
            }
            if (e.code === 'ArrowDown' && e.shiftKey) {
                setFocusedIndex((prevIndex) =>
                    prevIndex < linksRef.current.length - 1
                        ? prevIndex + 1
                        : prevIndex,
                );
                setIsKeyboardNav(true);
            }
        }

        function mouseHandler() {
            setIsKeyboardNav(false);
        }

        window.document.addEventListener('keydown', keyHandler);
        window.document.addEventListener('click', mouseHandler);

        return () => {
            window.document.removeEventListener('keydown', keyHandler);
            window.document.removeEventListener('click', mouseHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [focusedIndex, linksRef, isKeyboardNav];
};
