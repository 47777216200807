import { Outlet } from 'react-router';
import { SetupWebexInboxProvider } from './setup-webex-phone-context';

export const WebexPhoneNumbers = () => {
    return (
        <SetupWebexInboxProvider>
            <Outlet />
        </SetupWebexInboxProvider>
    );
};
