import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { Workflow } from '../components/workflows/types';
import {
    attachWorkflow,
    createWorkflow,
    detachWorkflow,
    getSystemWorkflow,
    removeWorkflow,
    renameWorkflow,
    updateWorkflow,
} from '../api/workflows';
import {
    AttachWorkflowDto,
    CreateWorkflowDto,
    RenameWorkflowDto,
} from '../api/types';
import { client } from '../api/http';

const WORKFLOWS_LIST_KEY = 'workflows_list';
const SYSTEM_WORKFLOW = 'system_workflow';

const createPlaceholder = (dto: CreateWorkflowDto): Workflow => ({
    ...dto,
    id: -1,
    enabled: true,
    inboxes: [],
});

export const useWorkflows = () =>
    useQuery({
        queryKey: [WORKFLOWS_LIST_KEY],
        queryFn: () =>
            client.get<Workflow[]>('/workflows').then(({ data }) => data),
    });

export function useWorkflowsQuery() {
    const { data = [], isPending: isPending } = useWorkflows();

    return { workflows: data, isPending };
}

export function useWorkflowCreateQuery() {
    return useMutation<Workflow, unknown, CreateWorkflowDto, unknown>({
        mutationKey: ['workflow_create'],
        mutationFn: createWorkflow,
        onMutate: async (dto) => {
            await queryClient.cancelQueries({ queryKey: [WORKFLOWS_LIST_KEY] });

            queryClient.setQueryData<Workflow[]>(
                [WORKFLOWS_LIST_KEY],
                (workflows = []) => workflows.concat(createPlaceholder(dto)),
            );
        },
        onSuccess: (workflow) => {
            queryClient.setQueryData<Workflow[]>(
                [WORKFLOWS_LIST_KEY],
                (workflows = []) => {
                    const newWorkflows = workflows.slice();
                    newWorkflows[newWorkflows.length - 1] = workflow;
                    return newWorkflows;
                },
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
            queryClient.invalidateQueries({ queryKey: [SYSTEM_WORKFLOW] });
        },
        onError: () => {
            queryClient.setQueryData<Workflow[]>(
                [WORKFLOWS_LIST_KEY],
                (workflows = []) => workflows.slice(0, -1),
            );
        },
    });
}

export function useWorkflowUpdateQuery() {
    return useMutation<
        Workflow,
        unknown,
        { workflowId: number } & Partial<CreateWorkflowDto>,
        { prevWorkflow: Workflow }
    >({
        mutationKey: ['workflow_update'],
        mutationFn: ({ workflowId, ...dto }) => updateWorkflow(workflowId, dto),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
            queryClient.invalidateQueries({ queryKey: [SYSTEM_WORKFLOW] });
        },
    });
}

export function useWorkflowRemoveQuery() {
    return useMutation<
        void,
        unknown,
        number[],
        {
            prevWorkflows: Workflow[];
        }
    >({
        mutationKey: ['workflow_delete'],
        mutationFn: removeWorkflow,
        onMutate: async (workflowIds) => {
            await queryClient.cancelQueries({ queryKey: [WORKFLOWS_LIST_KEY] });

            const prevWorkflows =
                queryClient.getQueryData<Workflow[]>([WORKFLOWS_LIST_KEY]) ||
                [];

            queryClient.setQueryData<Workflow[]>(
                [WORKFLOWS_LIST_KEY],
                (workflows = []) =>
                    workflows.filter(({ id }) => !workflowIds.includes(id)),
            );

            return { prevWorkflows };
        },
        onError: (_error, _, context) => {
            const { prevWorkflows } = context || { prevWorkflows: [] };

            queryClient.setQueryData<Workflow[]>(
                [WORKFLOWS_LIST_KEY],
                () => prevWorkflows,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
        },
    });
}

export function useWorkflowAttachQuery() {
    return useMutation<Workflow, unknown, AttachWorkflowDto, unknown>({
        mutationKey: ['workflow_attach'],
        mutationFn: attachWorkflow,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
        },
    });
}

export function useWorkflowDetachQuery() {
    return useMutation<Workflow, unknown, AttachWorkflowDto, unknown>({
        mutationKey: ['workflow_detach'],
        mutationFn: detachWorkflow,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
        },
    });
}

export function useWorkflowRenameQuery() {
    return useMutation<Workflow, unknown, RenameWorkflowDto, unknown>({
        mutationKey: ['workflow_rename'],
        mutationFn: renameWorkflow,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
        },
        onSuccess: (_, dto) => {
            queryClient.setQueryData<Workflow[]>(
                [WORKFLOWS_LIST_KEY],
                (workflows = []) => {
                    const newWorkflows = workflows.slice();

                    const idx = newWorkflows.findIndex(
                        ({ id }) => id === dto.workflowId,
                    );
                    if (idx > -1) {
                        newWorkflows[idx] = {
                            ...workflows[idx],
                            name: dto.name,
                        };
                    }

                    return newWorkflows;
                },
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [WORKFLOWS_LIST_KEY] });
        },
    });
}

export function useSystemWorkflowQuery(inboxId: string) {
    return useQuery({
        queryKey: [SYSTEM_WORKFLOW, inboxId],
        queryFn: () => getSystemWorkflow(inboxId),
    });
}
