import {
    buttonLikeActiveStyle,
    buttonLikeStyle,
    teamSelectorBtn,
} from './styles';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import { userEmail } from '../../helpers/formatting';
import { useMeQueryData } from '../../queries/user';
import { DEFAULT_TEAM_NAME } from './constants';
import { MenuProps } from '../../hooks/use-menu';
import { ExpandMoreFilledIcon } from '../../icons/common/ExpandMoreFilled';

export function TeamSelectorButton({
    open,
    handleOpen,
}: {
    open: boolean;
    handleOpen: MenuProps['handleOpen'];
}) {
    const me = useMeQueryData();

    return (
        <Button
            sx={teamSelectorBtn}
            variant="text"
            onClick={handleOpen}
            fullWidth
            startIcon={
                <Avatar
                    sx={{ width: 20, height: 20 }}
                    alt={me?.activeTeam?.name || DEFAULT_TEAM_NAME}
                    src={me?.activeTeam.teamAvatarURL || logo}
                />
            }
        >
            <Stack ml={2} flexGrow={1} maxWidth={140} alignItems="flex-start">
                <Stack direction="row" alignItems="center" gap={4} maxWidth={1}>
                    <Typography
                        variant="body3"
                        color="primary.dark"
                        fontWeight={500}
                        noWrap
                    >
                        {me?.activeTeam?.name || DEFAULT_TEAM_NAME}
                    </Typography>
                    <Box sx={[buttonLikeStyle, open && buttonLikeActiveStyle]}>
                        <ExpandMoreFilledIcon />
                    </Box>
                </Stack>
                <Typography
                    variant="body4"
                    color="text.secondary"
                    maxWidth={1}
                    noWrap
                >
                    {userEmail(me!)}
                </Typography>
            </Stack>
        </Button>
    );
}
