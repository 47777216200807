import { queryClient } from '../queries/queryClient';
import { DISCUSSION_KEY, updateReplies } from '../queries/comments';
import { Discussion, MessageComment, Profile } from '../api/types';
import { CURRENT_USER_KEY } from '../queries/user';

type DataPayload = {
    comment: MessageComment;
};

export const NEW_DISCUSSION_COMMENT_MESSAGE = 'NEW_DISCUSSION_COMMENT_MESSAGE';

export const handleNewDiscussionCommentEvent = (payload: DataPayload) => {
    const { comment } = payload;
    const me = queryClient.getQueryData<Profile>([CURRENT_USER_KEY])!;
    if (me.id === comment.userId) {
        return;
    }

    const discussion = queryClient.getQueryData<Discussion>([
        DISCUSSION_KEY,
        comment.discussionId,
    ]);

    if (discussion) {
        const newDiscussion: Discussion = {
            ...discussion,
            replies: discussion.replies + 1,
            comments: discussion.comments.concat(comment),
        };

        queryClient.setQueryData<Discussion>(
            [DISCUSSION_KEY, comment.discussionId],
            newDiscussion,
        );

        updateReplies(discussion, newDiscussion);
    }
};
