/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import cc from 'classcat';
import styles from './UserProperty.module.scss';
import {
    OnOptionCreateHandler,
    OnPropertyChangeHandler,
    OnPropertyDeleteHandler,
    UserPropertyModel,
} from '../types';
import { renderValue } from '../UserPropertyValue';
import { Icon } from '../../../icons/Icon';
import PropertyIconEditor from '../../ContactProperty/PropertyIconEditor';
import PropertyConfigurator from '../../ContactProperty/PropertyConfigurator';
import { Popover } from '@mui/material';
import { useFilterMeta } from '../../Contacts/Cohorts/hooks';
import { getPropertyIcon } from '../../../utils/contact-properties';
import { useLocation } from 'react-router';

interface UserPropertyProps {
    contactId?: string;
    userProperty: UserPropertyModel;
    nameEditMode?: boolean;
    valueTransformer?: (value: any) => any;
    onPropertyChange: OnPropertyChangeHandler;
    onValueChange: (value: any) => void;
    onDelete?: OnPropertyDeleteHandler;
    onOptionCreate?: OnOptionCreateHandler;
}

function UserProperty({
    contactId,
    userProperty,
    nameEditMode = false,
    valueTransformer,
    onPropertyChange,
    onValueChange,
    onDelete,
    onOptionCreate,
}: UserPropertyProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const { pathname } = useLocation();
    const isInbox = pathname.startsWith('/inbox/');
    const getFilterMeta = useFilterMeta();
    const filterMeta = getFilterMeta(userProperty);
    const [iconEditorActive, setIconEditorActive] = useState(false);
    const [nameEditorActive, setNameEditorActive] = useState(nameEditMode);
    const handleClose = () => {
        setAnchorEl(null);
        window.setTimeout(() => {
            setIconEditorActive(false);
            setNameEditorActive(false);
        }, 200);
    };

    return (
        <div className={styles['user-property']}>
            <Icon
                className={cc([
                    styles['user-property__icon'],
                    {
                        [styles['user-property__icon_readonly']]:
                            filterMeta.readonly,
                    },
                ])}
                size="20px"
                name={getPropertyIcon(
                    filterMeta.icon,
                    filterMeta.type,
                    filterMeta.source,
                )}
                onClick={(e) => {
                    if (filterMeta.readonly) {
                        return () => false;
                    }
                    setIconEditorActive(!iconEditorActive);
                    handleClick(e);
                }}
            />

            <span
                title={filterMeta.name}
                className={cc([
                    styles['user-property__name'],
                    {
                        [styles['user-property__name_readonly']]:
                            filterMeta.readonly,
                    },
                ])}
                onClick={(e) => {
                    if (filterMeta.readonly) {
                        return () => false;
                    }
                    setNameEditorActive(true);
                    handleClick(e);
                }}
            >
                {filterMeta.name}
            </span>

            {renderValue(
                userProperty,
                contactId,
                valueTransformer,
                onValueChange,
                onOptionCreate,
                isInbox,
            )}
            <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
                {iconEditorActive && (
                    <div>
                        <PropertyIconEditor
                            currentIcon={userProperty.icon}
                            onSelect={(icon) => {
                                onPropertyChange({ ...userProperty, icon });
                                handleClose();
                            }}
                        />
                    </div>
                )}
                {nameEditorActive && (
                    <div className={styles['user-property__popup']}>
                        <PropertyConfigurator
                            type={userProperty.type}
                            name={userProperty.name}
                            onNameChange={(name) => {
                                onPropertyChange({ ...userProperty, name });
                                handleClose();
                            }}
                            onRemove={() => {
                                onDelete?.(userProperty);
                                handleClose();
                            }}
                        />
                    </div>
                )}
            </Popover>
        </div>
    );
}

export default UserProperty;
