import {
    listItemAvatarClasses,
    listItemTextClasses,
    SxProps,
    Theme,
} from '@mui/material';

export const list: SxProps = {
    width: 1,
    px: 10,
};

export const icon: SxProps = {
    width: 40,
};

export const listItem: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(4, 0),
    borderBottom: '1px solid',
    borderBottomColor: 'custom.gray.tableDivider',
    alignItems: 'center',

    [`& .${listItemAvatarClasses.root}`]: {
        display: 'flex',
        minWidth: 'auto',
        marginRight: 4,

        '& img': {
            maxWidth: 40,
        },
    },

    [`& .${listItemTextClasses.root}`]: {
        margin: theme.spacing(0, 6, 0, 0),
    },
});

export const connectButton: SxProps = {
    minWidth: 96,
};
