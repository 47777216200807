import { useCallback, useEffect, useState } from 'react';
import {
    AddressElement,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import LockIcon from '../../../assets/icons/payment-flow/lock.svg?react';
import { useTrack } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import { Box, Button, Typography } from '@mui/material';
import { useSocket } from '../../../websocket';
import { SUBSCRIPTION_INTENT_PAYMENT_FAILED_EVENT } from '../../../websocket/event-handlers/subscription-intent-payment-failed.event-handler';
import { useNavigate } from 'react-router';
import { ToastActions, useToastContext } from '../../toast/reducer';
import { LoadingWithText } from '../../../components/Loading/LoadingWithText';
import { paymentElement, confirm } from './styles';
import { colors } from '../../../theme/palette';

type TimeoutID = ReturnType<typeof setTimeout>;

export const BillingForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const socket = useSocket().getSocket();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const me = useMeQueryData();
    const track = useTrack();
    const { dispatch: toastDispatch } = useToastContext();
    const [toastTimeoutId, setToastTimeoutId] = useState<TimeoutID | null>(
        null,
    );

    const dispatchPurchaseEvent = useCallback(() => {
        track('user_clicked_purchase');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    useEffect(() => {
        if (socket) {
            socket.on(SUBSCRIPTION_INTENT_PAYMENT_FAILED_EVENT, () => {
                setError(
                    'Your card was declined. Please try another payment method.',
                );
                setIsLoading(false);
            });
        }
    }, [socket]);

    useEffect(() => {
        if (me?.activeTeam.tier !== 'free') {
            track('success_purchase_plan', {
                teamId: me?.activeTeam.id,
                tier: me?.activeTeam.tier,
            });
            navigate('/payment/finish');
            setIsLoading(false);
            if (toastTimeoutId !== null) {
                clearTimeout(toastTimeoutId);
            }
        }
    }, [
        me?.activeTeam.tier,
        me?.activeTeam.id,
        navigate,
        toastTimeoutId,
        track,
    ]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
            toastDispatch({
                type: ToastActions.ADD,
                payload: {
                    title: 'Payment Connection Error',
                    description:
                        'Please visit the billing page in the settings section to verify the payment status.',
                },
            });
        }, 30000);

        setToastTimeoutId(timeoutId);

        dispatchPurchaseEvent();

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.location.origin,
            },
        });

        if (error) {
            setError(error.message || '');
            setIsLoading(false);
        } else {
            setError('');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={paymentElement}>
                <PaymentElement
                    options={{
                        paymentMethodOrder: ['card'],
                        wallets: {
                            applePay: 'never',
                            googlePay: 'never',
                        },
                        layout: 'accordion',
                    }}
                />
                <Box mb={2} />
                <AddressElement
                    options={{
                        mode: 'billing',
                    }}
                />

                {error && (
                    <Typography
                        variant="body4"
                        color="error"
                        pt={1}
                        display="block"
                    >
                        {error}
                    </Typography>
                )}
            </Box>
            <Button type="submit" sx={confirm}>
                <LockIcon /> <span>Confirm & pay</span>
            </Button>
            <Typography variant="body4" color={colors.superGrey[500]} m={0}>
                Your payment is securely protected with AES 256-bit encryption
            </Typography>
            {isLoading && (
                <LoadingWithText
                    title="We are processing your payment"
                    subtitle={`Breathe, it won't be long.`}
                />
            )}
        </form>
    );
};
