import { ReactNode } from 'react';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { ArrowBackIcon } from '../../icons/common/CArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import {
    getWrapperStyles,
    iconWrapper,
    logoButton,
    paperWithFrame,
} from './styles';

type StepShellProps = {
    icon: ReactNode;
    title: string;
    subtitle: ReactNode;
    children: React.ReactNode;
    disableFrame?: boolean;
    hideLogo?: boolean;
    goBackward?: string;
};

export const StepShell = ({
    icon,
    title,
    subtitle,
    children,
    goBackward,
    disableFrame = false,
}: StepShellProps) => {
    return (
        <Stack
            py={12}
            px={10}
            pl={goBackward ? 34 : 10}
            height={1}
            alignItems="center"
            justifyContent="flex-start"
            sx={getWrapperStyles(disableFrame)}
        >
            <Box sx={logoButton}>
                {goBackward && (
                    <Button
                        component={RouterLink}
                        startIcon={<ArrowBackIcon />}
                        variant="outlined"
                        to={goBackward}
                        size="small"
                    >
                        Back
                    </Button>
                )}
            </Box>

            <Box
                component={disableFrame ? 'div' : Paper}
                sx={disableFrame ? undefined : paperWithFrame}
                elevation={disableFrame ? undefined : 1}
            >
                <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="center"
                    pt={14}
                    pb={12}
                    flex={1}
                    flexDirection="column"
                >
                    <Stack
                        spacing={12}
                        width="100%"
                        alignItems="center"
                        flex={1}
                        pb={10}
                    >
                        <Stack alignItems="center" spacing={4}>
                            <Box sx={iconWrapper}>{icon}</Box>
                            <Stack
                                spacing={1}
                                alignItems="center"
                                width={480}
                                textAlign="center"
                            >
                                <Typography variant="h2" color="primary.dark">
                                    {title}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    color="primary.dark"
                                >
                                    {subtitle}
                                </Typography>
                            </Stack>
                        </Stack>
                        {children}
                    </Stack>
                </Grid>
            </Box>
        </Stack>
    );
};
