import { Autocomplete, Chip, TextField } from '@mui/material';
import uniq from 'lodash/uniq';
import {
    Dispatch,
    SetStateAction,
    useState,
    JSX,
    ChangeEvent,
    FocusEvent,
    ClipboardEvent,
    KeyboardEvent,
} from 'react';

const stringToItems = function (
    value: string,
    validator: (str: string) => boolean,
) {
    const separator = /[\s\n,]+/g;
    const rawItems = value
        .trim()
        .split(separator)
        .map((val) => val.trim());

    return rawItems.reduce<string[]>((acc, item) => {
        if (!item || !validator(item) || acc.includes(item)) {
            return acc;
        }
        return acc.concat(item);
    }, []);
};

type Props = {
    items: string[];
    onChange: Dispatch<SetStateAction<string[]>>;
    onType?: Dispatch<SetStateAction<string>>;
    validator: (str: string) => boolean;
    placeholder: string;
    label: string;
    helperText?: string;
    error?: boolean;
    disabled?: boolean;
};

export default function MultiItemsTextField({
    items,
    onChange,
    validator,
    placeholder,
    label,
    helperText,
    onType = () => {},
    error = false,
    disabled = false,
}: Props) {
    const [value, setValue] = useState<string>('');

    const handleValueChange = (value: string) => {
        const nextItems = stringToItems(value, validator);
        if (nextItems.length) {
            onChange((prev: string[]) => uniq(prev.concat(nextItems)));
            setValue('');
            onType('');
        }
    };

    return (
        <Autocomplete
            disabled={disabled}
            multiple
            options={[]}
            value={items}
            freeSolo
            disableClearable
            onChange={(_, nextValue) => {
                onChange(uniq(nextValue));
            }}
            renderTags={(
                value: string[],
                getTagProps: (arg0: {
                    index: number;
                }) => JSX.IntrinsicAttributes,
            ) => {
                return value.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={`${value[index] ?? index}`}
                        variant="outlined"
                        size="small"
                        label={option}
                    />
                ));
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    value={value}
                    onChange={(e) => {
                        const { value } = e.target;
                        setValue(value);
                        onType(value);
                        params.inputProps.onChange?.(
                            e as ChangeEvent<HTMLInputElement>,
                        );
                    }}
                    onBlur={(e) => {
                        const { value } = e.target;
                        handleValueChange(value);
                        params.inputProps.onBlur?.(
                            e as FocusEvent<HTMLInputElement>,
                        );
                    }}
                    autoFocus
                    sx={(theme) => ({
                        '& .MuiInputBase-root, & .MuiOutlinedInput-root': {
                            alignItems: 'flex-start',
                            minHeight: 42,
                            padding: theme.spacing(1.5, 3),
                            '& > .MuiAutocomplete-input': {
                                minHeight: 'auto',
                                padding: theme.spacing(1),
                                whiteSpace: 'nowrap',
                            },
                        },
                    })}
                    inputProps={{
                        ...params.inputProps,
                        value,
                        onPaste: (event: ClipboardEvent) => {
                            const value = event.clipboardData?.getData('text');
                            if (value) {
                                event.preventDefault();
                                handleValueChange(value);
                            }
                        },
                        onKeyDown: (event: KeyboardEvent) => {
                            const { value } =
                                event.target as HTMLTextAreaElement;

                            switch (event.code) {
                                case 'Enter': {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    handleValueChange(value);
                                    break;
                                }
                                case 'Space': {
                                    const nextItems = stringToItems(
                                        value,
                                        validator,
                                    );
                                    if (nextItems.length) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        handleValueChange(value);
                                    }
                                    break;
                                }
                                case 'Backspace': {
                                    if (!value) {
                                        onChange((prev: string[]) =>
                                            prev.slice(0, -1),
                                        );
                                    }
                                    break;
                                }
                            }
                        },
                    }}
                    multiline
                    minRows={1}
                    label={label}
                    placeholder={placeholder}
                    helperText={helperText}
                />
            )}
        />
    );
}
