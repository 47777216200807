import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const WarningIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.24068 1.76485C7.72323 1.54518 8.27725 1.54518 8.75981 1.76485C9.09053 1.9154 9.33153 2.19585 9.5478 2.51588C9.76339 2.83489 10.001 3.26696 10.2953 3.80196L14.0451 10.6198C14.3209 11.1214 14.5447 11.5282 14.6907 11.8627C14.838 12.2003 14.9405 12.5374 14.8989 12.8848C14.8377 13.3954 14.5648 13.8569 14.1469 14.1566C13.8625 14.3605 13.5178 14.4331 13.151 14.4667C12.7876 14.5001 12.3232 14.5001 11.7507 14.5H4.24974C3.67727 14.5001 3.21292 14.5001 2.84951 14.4667C2.48268 14.4331 2.13795 14.3605 1.85359 14.1566C1.43565 13.8569 1.16281 13.3954 1.10162 12.8848C1.06 12.5374 1.16252 12.2003 1.30983 11.8627C1.45576 11.5282 1.67954 11.1214 1.95543 10.6198L5.70522 3.80195C5.99946 3.26696 6.2371 2.83489 6.45268 2.51588C6.66896 2.19585 6.90995 1.9154 7.24068 1.76485ZM8.3455 2.67498C8.12616 2.57514 7.87433 2.57514 7.65499 2.67498C7.58305 2.70773 7.47034 2.79596 7.28123 3.0758C7.09442 3.35222 6.8786 3.74359 6.56909 4.30633L2.8438 11.0796C2.55286 11.6086 2.35177 11.9752 2.22639 12.2626C2.10024 12.5517 2.08516 12.6877 2.09452 12.7659C2.12233 12.998 2.24635 13.2077 2.43632 13.3439C2.50031 13.3898 2.62668 13.4421 2.94083 13.4709C3.25306 13.4995 3.67124 13.5 4.27496 13.5H11.7255C12.3292 13.5 12.7474 13.4995 13.0597 13.4709C13.3738 13.4421 13.5002 13.3898 13.5642 13.3439C13.7541 13.2077 13.8782 12.998 13.906 12.7659C13.9153 12.6877 13.9002 12.5517 13.7741 12.2626C13.6487 11.9752 13.4476 11.6086 13.1567 11.0796L9.4314 4.30633C9.12189 3.74359 8.90606 3.35222 8.71926 3.0758C8.53014 2.79596 8.41744 2.70773 8.3455 2.67498ZM8.00024 6.16671C8.27639 6.16671 8.50024 6.39057 8.50024 6.66671V9.33338C8.50024 9.60952 8.27639 9.83338 8.00024 9.83338C7.7241 9.83338 7.50024 9.60952 7.50024 9.33338V6.66671C7.50024 6.39057 7.7241 6.16671 8.00024 6.16671ZM7.50024 11.3334C7.50024 11.0572 7.7241 10.8334 8.00024 10.8334H8.00691C8.28305 10.8334 8.50691 11.0572 8.50691 11.3334C8.50691 11.6095 8.28305 11.8334 8.00691 11.8334H8.00024C7.7241 11.8334 7.50024 11.6095 7.50024 11.3334Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
