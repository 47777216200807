import { useEffect } from 'react';
import { useGetWebexPhones } from '../../../queries/integrations';
import { StepShell } from '../../Onboarding/StepShell';
import Loading from '../../../components/Loading/Loading';
import { useNavigate } from 'react-router';
import { Button, Stack } from '@mui/material';
import { WebexIcon } from '../../../icons/logos/WebexIcon';
import { useTrack } from '../../../contexts/analytics';

export const WebexAuthStep = () => {
    /* Queries */
    const { error, isPending, refetch, isRefetching } = useGetWebexPhones();
    const navigate = useNavigate();
    const track = useTrack();

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error?.response?.status !== 401 && !isRefetching && !isPending) {
            navigate('/setup-inbox/webex/select');
        }
    }, [error, isRefetching, isPending, navigate]);

    return (
        <StepShell
            title="Your Webex Phones are Ready for SMS"
            icon={<WebexIcon />}
            subtitle="👋🏻 Welcome! Let's get you set up with Clerk Chat so you can start texting with your business phone number. We'll guide you through the process, step-by-step."
            goBackward="/setup-inbox/existing-number"
        >
            <Stack spacing={12} width={480} alignItems="center" flex={1}>
                {isPending || isRefetching ? (
                    <Loading />
                ) : (
                    <Stack
                        spacing={4}
                        alignItems="center"
                        justifyContent="space-between"
                        flex={1}
                    >
                        <Button
                            fullWidth
                            variant="outlined"
                            startIcon={<WebexIcon />}
                            color="primary"
                            size="large"
                            href={import.meta.env['VITE_WEBEX_OAUTH_URL']}
                            onClick={() => {
                                track('clicked_login_to_webex', {});
                            }}
                            sx={{ width: 320 }}
                        >
                            Login with Webex
                        </Button>
                    </Stack>
                )}
            </Stack>
        </StepShell>
    );
};
