import { Conversation } from '../../../api/types';
import { useMarkConversationAsRead } from '../../../queries/conversations';
import { useConversationSubscription } from '../../ConversationRow/use-conversation-subscription';
import { InboxListItem } from '../../ConversationsList/Item/InboxListItem';
import {
    Entity,
    usePreserveParams,
} from '../../../pages/NewInbox/query.helpers';
import { AdapterLink } from '../../ConversationsList/AdapterLink';
import { QueryParam } from '../../../pages/NewInbox/query.params';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { AssistantPopover } from '../../ConversationRow/Assistant/AssistantPopover';
import { Box } from '@mui/material';
import { useIsAssistantReply } from './use-is-assistant-reply';
import dayjs from 'dayjs';
import { ConversationStatus } from './Status';
import { useMatch } from 'react-router';
import { useByPhones } from '../../Contacts/use-contact-by-phone';
import {
    useConversationAvatar,
    useConversationName,
} from './use-conversation-props';
import { ContextMenu } from './ContextMenu';
import { Indicator } from '../../common/Indicator';
import throttle from 'lodash/throttle';
import { prefetchConversationPage } from '../../../queries/messages';
import { UUID } from '../../../types/uuid';

type Props = {
    conversation: Conversation;
    isFocused: boolean;
};

const prefetchThrottle = throttle(
    (conversationId: UUID) => {
        return prefetchConversationPage(conversationId);
    },
    200,
    { trailing: true, leading: false },
);

export const ConversationListItem = forwardRef<HTMLButtonElement, Props>(
    ({ conversation, isFocused }, ref) => {
        useConversationSubscription(conversation.id);
        const title = useConversationName(conversation.members);
        const avatar = useConversationAvatar(conversation.members);
        const memberQueries = useByPhones(conversation.members);
        const isLoading = useMemo(
            () => memberQueries.some(({ isLoading }) => isLoading),
            [memberQueries],
        );

        const isMatch = useMatch(
            `/inbox/${conversation.inboxId}/conversations/${conversation.id}/*`,
        );
        const preserveParams = usePreserveParams();
        const markAsRead = useMarkConversationAsRead();

        const [anchorEl, setAnchorEl] = useState<
            HTMLElement | HTMLButtonElement | null
        >(null);
        const open = Boolean(anchorEl);
        const isAssistantReply = useIsAssistantReply(conversation);

        const onMouseEnter = useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                prefetchThrottle(conversation.id);

                if (isAssistantReply && anchorEl !== e.currentTarget) {
                    setAnchorEl(e.currentTarget);
                }
            },
            [conversation.id, isAssistantReply, anchorEl],
        );

        const onClick = () => {
            setAnchorEl(null);
            if (conversation.unreadMessageCount) {
                markAsRead.mutate(conversation.id);
            }
        };

        const lastMessageDate =
            conversation.lastMessageSent ?? conversation.created;

        const date = useMemo(() => {
            if (dayjs(lastMessageDate).isToday()) {
                return dayjs(lastMessageDate).format('h:mm A');
            }
            return dayjs(lastMessageDate).format('D MMM');
        }, [lastMessageDate]);

        return (
            <InboxListItem
                aria-owns={
                    open
                        ? `inbox-message-popover-${conversation.id}`
                        : undefined
                }
                ref={ref}
                avatar={avatar}
                date={date}
                aria-haspopup={isAssistantReply ? 'true' : undefined}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
                onMouseLeave={() => {
                    prefetchThrottle.cancel();
                    if (isAssistantReply || anchorEl) {
                        setAnchorEl(null);
                    }
                }}
                href={preserveParams(
                    `/inbox/${conversation.inboxId}/${Entity.Conversations}/${conversation.id}/`,
                    [
                        QueryParam.ArchiveState,
                        QueryParam.ContactInfo,
                        QueryParam.CohortIds,
                        QueryParam.SpecialFilter,
                        QueryParam.ToCohortIds,
                    ],
                )}
                LinkComponent={AdapterLink}
                loading={isLoading}
                title={title}
                description={<ConversationStatus conversation={conversation} />}
                selected={!!isMatch}
                sx={{
                    position: 'relative',
                    border: '1px solid',
                    borderColor: isFocused ? 'info.main' : 'transparent',
                    borderRadius: isFocused ? 1.5 : 0,

                    '& .unread-indicator': {
                        display: 'block',
                    },

                    '&:hover': {
                        '& .item-context-menu': {
                            transition: 'opacity .2s',
                            opacity: 1,
                        },
                        '& .unread-indicator': {
                            display: 'none',
                        },
                    },
                }}
                extraDescription={
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ContextMenu conversation={conversation} />
                        {conversation.unreadMessageCount > 0 && (
                            <Indicator
                                className="unread-indicator"
                                sx={{ height: 16 }}
                                data-testid="conversation-unread-count"
                            >
                                {conversation.unreadMessageCount}
                            </Indicator>
                        )}
                    </Box>
                }
            >
                {isAssistantReply && (
                    <AssistantPopover
                        conversation={conversation}
                        open={isAssistantReply && open}
                        anchorEl={anchorEl}
                    />
                )}
            </InboxListItem>
        );
    },
);

ConversationListItem.displayName = 'ConversationListItem';
