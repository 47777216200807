import FaceGlassesIcon from '../../assets/icons/payment-flow/face-glasses.svg?react';
import FaceBabyIcon from '../../assets/icons/payment-flow/face-baby.svg?react';

export type PlanPeriod = 'monthly' | 'yearly';
export type PlanType = 'unlimited' | 'starter';

export type PlanItem = {
    priceId: string;
    title: string;
    titleIcon: React.ReactNode;
    subtitle: string;
    price: number;
    features: string[];
    period: PlanPeriod;
    type: PlanType;
    isPopular?: boolean;
};

export const planList: PlanItem[] = [
    {
        priceId: import.meta.env.VITE_STRIPE_UNLIMITED_MONTHLY_PRICE_ID,
        title: 'Ultimate',
        titleIcon: <FaceGlassesIcon />,
        subtitle: 'for enterprises & large organizations',
        features: [
            'Unlimited SMS',
            'Unlimited Contacts',
            'Bring your own numbers',
        ],
        price: 25,
        period: 'monthly',
        type: 'unlimited',
        isPopular: true,
    },
    {
        priceId: import.meta.env.VITE_STRIPE_STARTER_MONTHLY_PRICE_ID,
        title: 'Growth',
        titleIcon: <FaceBabyIcon />,
        subtitle: 'for individuals & small teams',
        features: ['Unlimited SMS', '5000 Contacts', 'Bring your own numbers'],
        price: 15,
        period: 'monthly',
        type: 'starter',
    },
    {
        priceId: import.meta.env.VITE_STRIPE_UNLIMITED_YEARLY_PRICE_ID,
        title: 'Ultimate',
        titleIcon: <FaceGlassesIcon />,
        subtitle: 'for enterprises & large organizations',
        features: [
            'Unlimited SMS',
            'Unlimited Contacts',
            'Bring your own numbers',
        ],
        price: 20,
        period: 'yearly',
        type: 'unlimited',
        isPopular: true,
    },
    {
        priceId: import.meta.env.VITE_STRIPE_STARTER_YEARLY_PRICE_ID,
        title: 'Growth',
        titleIcon: <FaceBabyIcon />,
        subtitle: 'for individuals & small teams',
        features: ['Unlimited SMS', '5000 Contacts', 'Bring your own numbers'],
        price: 10,
        period: 'yearly',
        type: 'starter',
    },
];

export const plansMap: Record<string, PlanItem> = planList.reduce(
    (acc, plan) => ({ ...acc, [plan.priceId]: plan }),
    {},
);

export const getPlansByPeriod = (
    period: PlanPeriod,
): Record<PlanType, PlanItem> =>
    planList
        .filter((p) => p.period === period)
        .reduce(
            (acc, plan) => ({ ...acc, [plan.type]: plan }),
            {} as Record<PlanType, PlanItem>,
        );

export const getPlansByType = (
    planType: PlanType,
): Record<PlanType, PlanItem> =>
    planList
        .filter((p) => p.type === planType)
        .reduce(
            (acc, plan) => ({ ...acc, [plan.period]: plan }),
            {} as Record<PlanType, PlanItem>,
        );
