import { ListItemText } from '@mui/material';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { WebexPhone } from '../../../api/types';

const getPhoneName = ({ user }: WebexPhone): string => {
    return !user?.firstName
        ? 'Unassigned'
        : !user.lastName
          ? user.firstName
          : `${user.firstName} ${user.lastName}`;
};
export function WebexPhoneItem({ phone }: { phone: WebexPhone }) {
    return (
        <ListItemText
            sx={{
                margin: 0,
            }}
            primary={formatPhoneNumber(phone.phone)}
            primaryTypographyProps={{
                variant: 'body3',
                color: 'primary.dark',
                fontWeight: 500,
                marginBottom: 1,
            }}
            secondary={`${getPhoneName(phone)} • ${phone.type === 'IVR' ? 'Resource Account' : 'User'}`}
            secondaryTypographyProps={{
                variant: 'body4',
                color: 'custom.gray.super',
            }}
        />
    );
}
