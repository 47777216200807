import { useEffect, useRef, useState } from 'react';
import { useSendOTP, useVerifyOTP } from '../../queries/auth';
import { get } from '../../utils/local-storage';
import {
    Box,
    Button,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { ButtonDotLoader } from './ButtonDotLoader';
import * as Sentry from '@sentry/react';
import { stepForm } from './styles';
import { LockIcon } from '../../icons/common/CLockIcon';

const COUNTDOWN_START = 59;

const getCountdownText = (countdown: number) =>
    countdown < 10 ? `0:0${countdown}` : `0:${countdown}`;

interface EmailStepProps {
    email: string;
    methodId: string;
    setMethodId: (methidId: string) => void;
    next: () => void;
    isSignUp: boolean;
}

const CodeStep = (props: EmailStepProps) => {
    const [code, setCode] = useState('');
    const [countdown, setCountdown] = useState(COUNTDOWN_START);
    const inputRef = useRef<HTMLInputElement>(null);

    const { mutateAsync: sendOTP } = useSendOTP();
    const {
        mutateAsync: verifyOTP,
        isPending,
        isError,
        reset,
    } = useVerifyOTP();

    // Effects
    useEffect(() => {
        const interval = window.setInterval(() => {
            setCountdown((oldValue) =>
                oldValue > 0 ? oldValue - 1 : oldValue,
            );
        }, 1000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const microsoftTenantId = get('MS_TENET_ID');

        verifyOTP({
            email: props.email.trim(),
            method_id: props.methodId,
            code,
            microsoftTenantId,
        })
            .then(() => props.next())
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={stepForm}>
            <Box mb={6}>
                <TextField
                    name="code"
                    error={isError}
                    value={code}
                    disabled={isPending}
                    onChange={(event) => {
                        setCode(event.target.value);
                        reset();
                    }}
                    InputProps={{
                        'aria-label': 'Verification code',
                        ref: inputRef,
                        placeholder: 'Verification code',
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                    helperText={
                        isError ? 'Check the verification code again' : ''
                    }
                    autoFocus
                    fullWidth
                />
            </Box>

            <Button disabled={!code || isPending} fullWidth type="submit">
                {isPending && <ButtonDotLoader />}
                {!isPending && props.isSignUp ? 'Sign Up' : 'Login'}
            </Button>

            <Box mt={4}>
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    display="inline-block"
                >
                    The code has been sent to your email. You can request the
                    code again in —{' '}
                    {countdown > 0 ? (
                        <Typography variant="body4" color="info.main">
                            {getCountdownText(countdown)}
                        </Typography>
                    ) : (
                        <Link
                            variant="body4"
                            onClick={() => {
                                sendOTP(props.email);
                                setCountdown(COUNTDOWN_START);
                            }}
                            sx={{ cursor: 'pointer' }}
                        >
                            Resend
                        </Link>
                    )}
                </Typography>
            </Box>
        </Box>
    );
};

export default CodeStep;
