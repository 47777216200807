import { ClerkPermission, Message, MessageStatus } from '../../../../api/types';
import {
    Box,
    CircularProgress,
    Divider,
    Fade,
    IconButton,
    Popper,
    Tooltip,
} from '@mui/material';
import { Attachments } from '../../../../main/campaign/v3/Attachment/Attachments';
import { getActionsList } from '../../../../components/ChatComponents/Message/styles';
import DiscussionBlock from '../../../../components/ChatComponents/Message/DiscussionBlock';
import { useState } from 'react';
import { PopperChildrenProps } from '@mui/base/Popper/Popper.types';
import { container } from './message.styles';
import { CopyIcon } from '../../../../icons/shared/CopyIcon';
import { SendFilledIcon } from '../../../../components/ConversationRow/SendFilledIcon';
import { WithPermission } from '../../../../containers/WithPermission/WithPermission';
import { DeleteIcon } from '../../../../icons/common/CDeleteIcon';
import { useTrack } from '../../../../contexts/analytics';
import { useMemo } from 'react';
import { checkIsUntilReview } from '../../../../components/ConversationRow/Assistant/check-is-until-review';
import { clearAssistant, useDeleteMessage } from '../../../../queries/messages';
import { useSendImmediately } from '../../../../components/ConversationRow/use-message.query';
import { useConfirmDelete } from './use-confirm-delete';
import { EditIcon } from '../../../../icons/common/CEditIcon';
import { useGoToQueryParam } from '../../query.helpers';
import { QueryParam } from '../../query.params';
import { useIsPlannedMessage } from './use-is-planned-message';
import { ScheduledIcon } from '../../../../icons/common/ScheduledIcon';
import { MessageDiscussionButton } from './MessageDiscussionButton';
import { MessageTimestampChangeDialog } from './TimestampChangeDialog';
import { addHyperLinksToTextLinks } from '../../../../components/NewConversation/utils';
import { useSearchParams } from 'react-router-dom';
import { FailedStatus } from './FailedStatus';

const divider = {
    mx: 2,
    height: 16,
    alignSelf: 'center',
};

type Props = {
    message: Message;
    onEdit: () => void;
};

export const MessageItem = ({ message, onEdit }: Props) => {
    const track = useTrack();
    const goToQueryParam = useGoToQueryParam();
    const isScheduled = useIsPlannedMessage(message);
    const sendNow = useSendImmediately();
    const remove = useDeleteMessage(message.id);
    const isUntilReview = checkIsUntilReview(message);
    const [open, setOpen] = useState<boolean>(false);
    const [confirm, Dialog] = useConfirmDelete();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [params, setParams] = useSearchParams();

    const showCommentButton = useMemo(
        () => !message.discussion || message.discussion.replies === 0,
        [message.discussion],
    );

    const send = () => {
        sendNow
            .mutateAsync({
                messageId: message.id,
                conversationId: message.conversationId,
            })
            .then(() => {
                track('assistant_message_sent_immediately', {
                    origin: 'scheduled_messages',
                });
                clearAssistant(message.conversationId);
            });
    };

    return (
        <>
            <Box
                data-testid="message-body"
                onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                sx={container(message)}
            >
                {addHyperLinksToTextLinks(message.body)}
                <Attachments
                    sx={{ mt: message.body ? 4 : undefined }}
                    attachments={message.attached}
                />
                <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement={message.inbound ? 'top-start' : 'top-end'}
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: message.inbound ? [12, 0] : [-12, 0],
                            },
                        },
                    ]}
                    transition
                    disablePortal
                >
                    {({ TransitionProps }: PopperChildrenProps) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Box sx={getActionsList(message.inbound)}>
                                <Tooltip arrow title="Copy message">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            track('message_copied');
                                            navigator.clipboard.writeText(
                                                message.body || '',
                                            );
                                        }}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>

                                {!isScheduled && showCommentButton && (
                                    <MessageDiscussionButton
                                        conversationId={message.conversationId}
                                        messageId={message.id}
                                        discussionId={message.discussion?.id}
                                    />
                                )}
                                {isScheduled && !isUntilReview && (
                                    <>
                                        <Tooltip
                                            data-testid="message-change-delivery-date"
                                            title="Change delivery date"
                                        >
                                            <IconButton
                                                color="primary"
                                                onClick={() => setOpen(true)}
                                            >
                                                <ScheduledIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {onEdit && (
                                            <Tooltip arrow title="Edit message">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="Edit message"
                                                    onClick={() => onEdit()}
                                                    data-testid="message-popover-edit-btn"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                                {isScheduled && isUntilReview && (
                                    <Tooltip title="Send now" arrow>
                                        <span>
                                            <IconButton
                                                color="primary"
                                                type="button"
                                                onClick={send}
                                                disabled={sendNow.isPending}
                                                data-testid="message-popover-send-now-btn"
                                            >
                                                {sendNow.isPending ? (
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                ) : (
                                                    <SendFilledIcon />
                                                )}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                                <WithPermission
                                    requiredPermissions={[
                                        ClerkPermission.DeleteMessage,
                                    ]}
                                >
                                    <Divider
                                        orientation="vertical"
                                        sx={divider}
                                        flexItem
                                    />
                                    <Tooltip arrow title="Delete">
                                        <span>
                                            <IconButton
                                                disabled={remove.isPending}
                                                color="error"
                                                onClick={async () => {
                                                    if (await confirm()) {
                                                        const discussionId =
                                                            params.get(
                                                                QueryParam.DiscussionId,
                                                            );
                                                        if (
                                                            discussionId &&
                                                            +discussionId ===
                                                                message
                                                                    .discussion
                                                                    ?.id
                                                        ) {
                                                            setParams(
                                                                (prev) => {
                                                                    prev.delete(
                                                                        QueryParam.DiscussionId,
                                                                    );
                                                                    return prev;
                                                                },
                                                            );
                                                        }

                                                        remove.mutate({
                                                            messageId:
                                                                message.id,
                                                            conversationId:
                                                                message.conversationId,
                                                        });
                                                    }
                                                }}
                                                data-testid="message-popover-delete-btn"
                                            >
                                                {remove.isPending ? (
                                                    <CircularProgress
                                                        size={27}
                                                        color="error"
                                                    />
                                                ) : (
                                                    <DeleteIcon />
                                                )}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </WithPermission>
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </Box>
            {message.status === MessageStatus.Failed && !message.inbound && (
                <FailedStatus />
            )}
            {message?.discussion?.id && message.discussion.replies > 0 && (
                <DiscussionBlock
                    isInbound={message.inbound}
                    discussion={message.discussion}
                    onClick={() => {
                        goToQueryParam(
                            {
                                [QueryParam.DiscussionId]: `${message?.discussion?.id}`,
                            },
                            [QueryParam.ContactInfo],
                        );
                    }}
                />
            )}

            {isScheduled && !isUntilReview && (
                <MessageTimestampChangeDialog
                    message={message}
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
            <WithPermission
                requiredPermissions={[ClerkPermission.DeleteMessage]}
            >
                <Dialog />
            </WithPermission>
        </>
    );
};
