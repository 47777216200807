import { Inbox } from '../../api/types';
import { UUID } from '../../types/uuid';

export enum WorkflowStepType {
    Conditional = 'conditional',
    Action = 'action',
}

export enum WorkflowConditionTypes {
    LastContacted = 'last_contacted',
    NewContact = 'new_contact',
    Property = 'property',
    Schedule = 'schedule',
    TimeFrame = 'timeframe',
    Filter = 'filter',
}

export enum WorkflowActionTypes {
    SendSMS = 'send_sms',
    Sleep = 'sleep',
    Webhook = 'webhook',
    AddToCohort = 'add_to_cohort',
    AssignTeamMember = 'assign_team_member',
}

// Settings

// TODO: Should be deprecated.
interface TimeFrameSettings {
    start: number;
    end: number;
    timezone: string;
}

export interface LastContactedSettings {
    duration: number;
    unit: 'hour' | 'month' | 'minute' | 'day';
}

export interface Time24Format {
    hours: number;
    minutes: number;
}

export interface ScheduleItem {
    enabled?: boolean;
    from: Time24Format;
    to: Time24Format;
}

export enum FilterOption {
    Body = 'body',
    Cohort = 'cohort',
}

export enum FilterOperator {
    Contain = 'contain',
    DoesNotContain = 'does_not_contain',
    IncludedInCohort = 'included_in_cohort',
    ExcludedFromCohort = 'excluded_from_cohort',
}

export type FilterCondition = {
    option: FilterOption;
    operator: FilterOperator;
    cohortId?: number;
    text?: string;
    condition: 'AND' | 'OR';
};
type FilterSettings = {
    filters: FilterCondition[];
};

export type ScheduleDay = '0' | '1' | '2' | '3' | '4' | '5' | '6'; // Sunday - Saturday

export type Schedule = Record<ScheduleDay, ScheduleItem>;

export interface ScheduleSettings {
    timezone?: string;
    schedule?: Schedule;
}

export type WorkflowSchedule = {
    id: number;
    dayOfWeek: ScheduleDay;
    time: string;
    enabled: boolean;
    workflowId: number;
    timezone: string;
};

// Steps

interface WorkflowStepBase {
    id: string;
    type: WorkflowStepType;
    name?: string;
    settings?: TimeFrameSettings | LastContactedSettings | ScheduleSettings;
}

export type WorkflowStep = WorkflowAction | WorkflowCondition;

export enum WorkflowRecipientType {
    Contact = 'contact',
    Phone = 'phone',
    Cohort = 'cohort',
    Sender = 'sender',
    Recipient = 'recipient',
}

export type WorkflowAction = WorkflowStepBase & {
    action: WorkflowActionTypes;
    webhookConfiguration?: { url: string; payload: string };
    smsConfiguration?: {
        message: string;
        recipients?: string[];
        recipientType?: WorkflowRecipientType;
        cohortId?: number;
    };
    cohortConfiguration?: { cohortId: number };
    assignToConfiguration?: UUID[];
    nextStep?: WorkflowStep;
};

export type WorkflowCondition = WorkflowStepBase & {
    truthyStep?: WorkflowStep;
    falsyStep?: WorkflowStep;
    kind: WorkflowConditionTypes;
    timeFrameSettings?: TimeFrameSettings;
    filterSettings?: FilterSettings;
};

export interface WorkflowSpec {
    steps?: WorkflowStep;
}

export enum WorkflowTrigger {
    IncomingMessage = 'incoming_message',
    Schedule = 'schedule',
    OutboundMessage = 'outbound_message',
    OutboundMessagePreSend = 'outbound_message_pre_send',
}

export interface Workflow {
    id: number;
    enabled: boolean;
    inboxes: Inbox[];
    name: string;
    spec: WorkflowSpec;
    trigger?: WorkflowTrigger;
    schedules?: WorkflowSchedule[];
}
