import { Box } from '@mui/material';

interface BannerProps {
    color: string;
    children: React.ReactNode;
}

export const BANNER_HEIGHT = 36;

export const Banner = (props: BannerProps) => {
    return (
        <Box
            bgcolor={props.color}
            height={BANNER_HEIGHT}
            width={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {props.children}
        </Box>
    );
};
