import {
    Backdrop,
    CircularProgress,
    Paper,
    Stack,
    SxProps,
    Typography,
} from '@mui/material';

export const LoadingWithText = ({
    title,
    subtitle,
    sx,
}: {
    title?: string;
    subtitle?: string;
    sx?: SxProps;
}) => {
    return (
        <Backdrop open={true} transitionDuration={0}>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: 6,
                    py: 12,
                    width: 420,
                    maxWidth: '90%',
                    ...sx,
                }}
            >
                <CircularProgress color="info" size={64} sx={{ my: 4 }} />
                <Stack gap={1} alignItems="center">
                    {title && <Typography variant="h3">{title}</Typography>}
                    {subtitle && (
                        <Typography variant="body3" color="text.secondary">
                            {subtitle}
                        </Typography>
                    )}
                </Stack>
            </Paper>
        </Backdrop>
    );
};
