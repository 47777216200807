type Options = {
    badge?: string;
    body?: string;
    data?: unknown;
    dir?: 'auto' | 'ltr' | 'rtl';
    icon?: string;
    lang?: string;
    requireInteraction?: boolean;
    silent?: boolean | null;
    tag?: string;
};

export const createNotification = (title: string, options: Options = {}) => {
    if (window.Notification && Notification.permission === 'granted') {
        return new Notification(title, {
            icon: 'https://clerk-static-assets.s3.amazonaws.com/clerk-logo-header.svg',
            ...options,
        });
    }
    return null;
};

const checkNotificationPromise = () => {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
};

export const askNotificationPermission = () => {
    if (!('Notification' in window)) {
        // eslint-disable-next-line no-console
        console.warn('This browser does not support notifications.');
    } else {
        if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
                // eslint-disable-next-line no-console
                console.log(permission);
            });
        } else {
            Notification.requestPermission(function (permission) {
                // eslint-disable-next-line no-console
                console.log(permission);
            });
        }
    }
};
