import { queryClient } from '../../queries/queryClient';
import { CURRENT_USER_KEY } from '../../queries/user';

export const SUBSCRIPTION_INTENT_PAYMENT_FAILED_EVENT =
    'subscription:intent-payment-failed';
type SubscriptionIntentPaymentFailedHandler = () => void;

export const subscriptionIntentPaymentFailedHandler: SubscriptionIntentPaymentFailedHandler =
    () => {
        queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
    };
