import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const MembersIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66667 3.95833C5.63113 3.95833 4.79167 4.7978 4.79167 5.83333C4.79167 6.86887 5.63113 7.70833 6.66667 7.70833C7.7022 7.70833 8.54167 6.86887 8.54167 5.83333C8.54167 4.7978 7.7022 3.95833 6.66667 3.95833ZM3.54167 5.83333C3.54167 4.10744 4.94078 2.70833 6.66667 2.70833C8.39256 2.70833 9.79167 4.10744 9.79167 5.83333C9.79167 7.55922 8.39256 8.95833 6.66667 8.95833C4.94078 8.95833 3.54167 7.55922 3.54167 5.83333ZM13.3333 6.45833C12.758 6.45833 12.2917 6.9247 12.2917 7.5C12.2917 8.07529 12.758 8.54167 13.3333 8.54167C13.9086 8.54167 14.375 8.07529 14.375 7.5C14.375 6.9247 13.9086 6.45833 13.3333 6.45833ZM11.0417 7.5C11.0417 6.23435 12.0677 5.20833 13.3333 5.20833C14.599 5.20833 15.625 6.23435 15.625 7.5C15.625 8.76565 14.599 9.79167 13.3333 9.79167C12.0677 9.79167 11.0417 8.76565 11.0417 7.5ZM6.66667 11.4583C4.71066 11.4583 3.125 13.044 3.125 15V16.0417H10.2083V15C10.2083 14.4111 10.0646 13.8557 9.81027 13.3671C9.79125 13.3379 9.77489 13.3075 9.76121 13.2761C9.1558 12.1917 7.99687 11.4583 6.66667 11.4583ZM10.4512 12.0607C9.57446 10.9335 8.20525 10.2083 6.66667 10.2083C4.0203 10.2083 1.875 12.3536 1.875 15V16.6667C1.875 17.0118 2.15482 17.2917 2.5 17.2917H17.5C17.8452 17.2917 18.125 17.0118 18.125 16.6667V15.8333C18.125 13.0185 15.9644 11.0417 13.3333 11.0417C12.2472 11.0417 11.2498 11.4239 10.4512 12.0607ZM11.0904 13.1555C11.3274 13.7232 11.4583 14.3463 11.4583 15V16.0417H16.875V15.8333C16.875 13.739 15.3046 12.2917 13.3333 12.2917C12.4872 12.2917 11.7053 12.6119 11.0904 13.1555Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
