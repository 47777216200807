import { DAYS_OF_WEEK } from '../../../components/workflows/WorkflowStepConfigurator/ScheduleStep/utils';
import {
    Schedule,
    ScheduleDay,
    ScheduleItem,
    ScheduleSettings,
    Workflow,
    WorkflowAction,
    WorkflowActionTypes,
    WorkflowCondition,
    WorkflowConditionTypes,
    WorkflowSpec,
    WorkflowTrigger,
} from '../../../components/workflows/types';
import {
    defaultFrom,
    defaultSchedule,
    defaultScheduleItem,
    defaultTo,
    generateWeek,
    normalizeTime,
    ScheduleRecordItem,
    ScheduleTypes,
    weekdays,
} from './constants';
import type { OutOfOfficeInputs } from './constants';
import { createNewStep } from '../../../components/workflows/utils';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

export const getScheduleType = (
    schedule: OutOfOfficeInputs['schedule'],
): ScheduleTypes => {
    if (
        DAYS_OF_WEEK.every(
            (day) =>
                schedule[day.id].enabled &&
                schedule[day.id].from === schedule[0].from &&
                schedule[day.id].to === schedule[0].to,
        )
    ) {
        return 'everyday';
    }

    if (
        weekdays.every(
            (day) =>
                schedule[day].enabled &&
                schedule[day].from === schedule[1].from &&
                schedule[day].to === schedule[1].to,
        )
    ) {
        return 'weekdays';
    }

    return 'custom';
};

export const getDefaultValues = (
    workflow?: Workflow | null,
): OutOfOfficeInputs => {
    const settings =
        ((workflow?.spec as WorkflowSpec)?.steps
            ?.settings as ScheduleSettings) ??
        ({
            timezone: '',
            schedule: {},
        } as ScheduleSettings);

    const schedule = Object.keys(settings.schedule ?? defaultSchedule).reduce(
        (acc, day) => {
            const scheduleItem =
                settings.schedule?.[day as ScheduleDay] ?? ({} as ScheduleItem);

            return {
                ...acc,
                [day]: {
                    enabled:
                        scheduleItem?.enabled ?? defaultScheduleItem.enabled,
                    from: scheduleItem?.from
                        ? `${scheduleItem?.from.hours}:${scheduleItem?.from.minutes}`
                        : defaultScheduleItem.from,
                    to: scheduleItem?.to
                        ? `${scheduleItem?.to.hours}:${scheduleItem?.to.minutes}`
                        : defaultScheduleItem.to,
                },
            };
        },
        defaultSchedule,
    );

    return {
        enabled: workflow?.enabled ?? false,
        timezone: settings.timezone ?? '',
        scheduleType: getScheduleType(schedule),
        schedule,
        message:
            (
                (
                    (workflow?.spec?.steps as WorkflowCondition)
                        ?.falsyStep as WorkflowCondition
                )?.falsyStep as WorkflowAction
            )?.smsConfiguration?.message ?? '',
    };
};

export function normalizeSchedule({
    scheduleType,
    schedule,
}: OutOfOfficeInputs) {
    const defaultNormalizedScheduleItem = {
        enabled: true,
        from: normalizeTime(defaultFrom),
        to: normalizeTime(defaultTo),
    };

    return Object.entries(schedule).reduce<Schedule>(
        (acc, [day, data]) => ({
            ...acc,
            [day]: {
                enabled:
                    scheduleType === 'weekdays'
                        ? weekdays.includes(day as (typeof weekdays)[number])
                        : data.enabled,
                to: normalizeTime(data.to),
                from: normalizeTime(data.from),
            },
        }),
        generateWeek(defaultNormalizedScheduleItem),
    );
}

export function getWorkflowPayload(
    workflow: Workflow,
    data: OutOfOfficeInputs,
) {
    const steps = workflow.spec?.steps;
    const newScheduleStep = createNewStep(WorkflowConditionTypes.Schedule);
    const newLastContactedStep = createNewStep(
        WorkflowConditionTypes.LastContacted,
    );
    const newSmsStep = createNewStep(WorkflowActionTypes.SendSMS);

    if (data.scheduleType !== 'custom') {
        data.schedule = updateEveryDay(
            data.schedule,
            data.scheduleType,
            data.schedule[1],
        );
    }

    return {
        name: workflow.name,
        system: true,
        trigger: WorkflowTrigger.IncomingMessage,
        enabled: data.enabled,
        spec: {
            steps: {
                ...newScheduleStep,
                id: get(steps, 'id', newScheduleStep?.id ?? uuidv4()),
                kind: WorkflowConditionTypes.Schedule,
                settings: {
                    timezone: data.timezone,
                    schedule: normalizeSchedule(data),
                },
                falsyStep: {
                    ...newLastContactedStep,
                    id: get(
                        steps,
                        'falsyStep.id',
                        newLastContactedStep?.id ?? uuidv4(),
                    ),
                    kind: WorkflowConditionTypes.LastContacted,
                    settings: { unit: 'hour', duration: 1 },
                    falsyStep: {
                        ...newSmsStep,
                        id: get(
                            steps,
                            'falsyStep.falsyStep.id',
                            newSmsStep?.id ?? uuidv4(),
                        ),
                        smsConfiguration: {
                            message: data.message,
                        },
                    } as WorkflowAction,
                } as WorkflowCondition,
            } as WorkflowCondition,
        },
    };
}

export const updateEveryDay = (
    schedule: OutOfOfficeInputs['schedule'],
    scheduleType: OutOfOfficeInputs['scheduleType'],
    data: Partial<ScheduleRecordItem>,
) => {
    return Object.keys(schedule).reduce(
        (acc, day) =>
            scheduleType === 'everyday' ||
            weekdays.includes(day as (typeof weekdays)[number])
                ? {
                      ...acc,
                      [day]: {
                          ...schedule[day as ScheduleDay],
                          ...data,
                          enabled: true,
                      },
                  }
                : acc,
        schedule,
    );
};
