import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function fuzzyDate(date: Date) {
    return dayjs(date).fromNow();
}

/**
 * Format raw number of bytes to  KB, MB, GB, etc.
 *
 * @param size number of bytes
 */
export function formatFileSize(size: number) {
    if (size === 0) {
        return 'N/A bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const k = 1024;

    const idx = Math.floor(Math.log(size) / Math.log(k));

    if (idx === 0) {
        return `${size} ${sizes[idx]}`;
    }

    const divResult = size / Math.pow(k, idx);
    const intPart = Math.floor(divResult);
    const mantissaPart = divResult - Math.floor(divResult);

    const formatted =
        Math.floor(mantissaPart * 100) === 0 ? intPart : divResult.toFixed(2);

    return `${formatted} ${sizes[idx]}`;
}
