import { useEffect } from 'react';
import { useTrack } from '../contexts/analytics';
import { useMeQueryData } from '../queries/user';

export const useVitePreloadErrorHandler = (maxReloads = 3) => {
    const me = useMeQueryData();
    const track = useTrack();
    useEffect(() => {
        const handlePreloadError = () => {
            const reloadCount = parseInt(
                sessionStorage.getItem('reloadCount') || '0',
                10,
            );
            if (reloadCount < maxReloads) {
                track('dynamic_import_reload', {
                    teamId: me?.activeTeam.id,
                    userId: me?.id,
                });
                sessionStorage.setItem(
                    'reloadCount',
                    (reloadCount + 1).toString(),
                );
                window.location.reload();
            } else {
                track('dynamic_import_reload_exceeded', {
                    teamId: me?.activeTeam.id,
                    userId: me?.id,
                });
                sessionStorage.removeItem('reloadCount');
                throw new Error('Exceeded maximum reload attempts');
            }
        };

        window.addEventListener('vite:preloadError', handlePreloadError);

        return () => {
            window.removeEventListener('vite:preloadError', handlePreloadError);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
