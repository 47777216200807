import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    createComment,
    CreateCommentDto,
    createDiscussion,
    CreateDiscussionDto,
    getDiscussion,
} from '../api/comments';
import { Discussion, Message, MessageComment, Profile } from '../api/types';
import { PagedData } from '../types/PagedData';
import { UUID } from '../types/uuid';
import { CONVERSATION_MESSAGES_KEY } from './messages';
import { queryClient } from './queryClient';
import { CURRENT_USER_KEY } from './user';
import { findPageIndexInPagedData } from './utils';
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';

export const DISCUSSION_KEY = 'discussion';
export const useDiscussion = (discussionId: number) => {
    const {
        data: discussion,
        isFetching,
        isFetched,
        isError,
    } = useQuery({
        queryKey: [DISCUSSION_KEY, discussionId],
        queryFn: () => getDiscussion(discussionId),
        select: (discussion) =>
            discussion.lastReplyDate
                ? {
                      ...discussion,
                      lastReplyDate: new Date(discussion.lastReplyDate),
                  }
                : discussion,
    });

    return { discussion, isFetching, isFetched, isError };
};

export const useCreateDiscussion = (conversationId: UUID) => {
    return useMutation<Discussion, AxiosError, CreateDiscussionDto>({
        mutationKey: ['discussion_create'],
        mutationFn: createDiscussion,
        onSuccess() {
            queryClient.refetchQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });
        },
    });
};

export const useCreateComment = () => {
    return useMutation<
        MessageComment,
        AxiosError,
        CreateCommentDto,
        MessageComment[]
    >({
        mutationKey: ['comment_create'],
        mutationFn: createComment,
        onMutate: (dto) => {
            const me = queryClient.getQueryData<Profile>([CURRENT_USER_KEY])!;
            const prevDiscussion = queryClient.getQueryData<Discussion>([
                DISCUSSION_KEY,
                dto.discussionId,
            ]);

            if (!prevDiscussion) {
                return;
            }

            const comment: MessageComment = {
                id: -1,
                discussionId: dto.discussionId,
                created: new Date(),
                userId: me.id,
                text: dto.text,
                mentionedUserIds: dto.mentionedUserIds,
            };

            const newDiscussion: Discussion = {
                ...prevDiscussion,
                comments: prevDiscussion.comments.concat(comment),
            };

            queryClient.setQueryData<Discussion>(
                [DISCUSSION_KEY, dto.discussionId],
                newDiscussion,
            );

            return prevDiscussion.comments;
        },
        onError: (_err, dto, context = []) => {
            const discussion = queryClient.getQueryData<Discussion>([
                DISCUSSION_KEY,
                dto.discussionId,
            ]);

            if (discussion) {
                const newDiscussion: Discussion = {
                    ...discussion,
                    comments: context,
                };

                queryClient.setQueryData<Discussion>(
                    [DISCUSSION_KEY, dto.discussionId],
                    newDiscussion,
                );
            }
        },
        onSuccess(data, dto) {
            const discussion = queryClient.getQueryData<Discussion>([
                DISCUSSION_KEY,
                dto.discussionId,
            ]);

            if (discussion) {
                const newDiscussion: Discussion = {
                    ...discussion,
                    replies: discussion.replies + 1,
                    comments: discussion.comments
                        .slice(0, discussion.comments.length - 1)
                        .concat(data),
                };

                queryClient.setQueryData<Discussion>(
                    [DISCUSSION_KEY, dto.discussionId],
                    newDiscussion,
                );

                updateReplies(discussion, newDiscussion);
            }
        },
    });
};

export function updateReplies(prev: Discussion, next: Discussion) {
    queryClient.setQueryData<PagedData<Message>>(
        [CONVERSATION_MESSAGES_KEY, prev.message.conversationId],
        (pagedMessages = { pages: [], pageParams: [] }) => {
            const [pageIndex, itemIndex] = findPageIndexInPagedData(
                pagedMessages,
                (message) => message.id === prev.messageId,
            );

            return setWith(
                clone(pagedMessages),
                ['pages', pageIndex, itemIndex, 'discussion', 'replies'],
                next.replies,
                clone,
            );
        },
    );
}
