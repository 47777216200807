import { SxProps } from '@mui/material';

export const toolbar: SxProps = {
    '& .MuiDateTimePickerToolbar-timeDigitsContainer': {
        alignItems: 'center',
    },
};

export const checkbox: SxProps = {
    padding: 0,
    marginRight: 4,
};

export const checkboxWrapper: SxProps = {
    margin: 0,
    width: 1,

    '& .MuiFormControlLabel-label': {
        display: 'flex',
        flex: 1,
    },
};

export const infoIcon: SxProps = { width: 16, height: 16 };
