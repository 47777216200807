import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const AppleMessenger = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                d="M18.1548 1H5.84524C3.16929 1 1 3.16929 1 5.84524V18.1548C1 20.8307 3.16929 23 5.84524 23H18.1548C20.8307 23 23 20.8307 23 18.1548V5.84524C23 3.16929 20.8307 1 18.1548 1Z"
                fill="url(#paint0_linear_1351_6698)"
            />
            <path
                d="M12 5C9.87827 5.00001 7.84345 5.68602 6.34316 6.90711C4.84287 8.12819 4.00001 9.78434 4 11.5112C4.00193 12.6345 4.36085 13.7382 5.04187 14.7152C5.72289 15.6921 6.70285 16.5091 7.88651 17.0867C7.57125 17.7763 7.09844 18.423 6.48764 19C7.67212 18.7968 8.78402 18.37 9.73679 17.7527C10.4714 17.9304 11.2336 18.0212 12 18.0225C14.1217 18.0224 16.1566 17.3364 17.6568 16.1153C19.1571 14.8943 20 13.2381 20 11.5112C20 9.78434 19.1571 8.12819 17.6568 6.90711C16.1566 5.68602 14.1217 5.00001 12 5Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1351_6698"
                    x1="12.1746"
                    y1="21.5396"
                    x2="12.1746"
                    y2="3.7303"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0CBD2A" />
                    <stop offset="1" stopColor="#5BF675" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
);
