import { Box, LinearProgress, Typography } from '@mui/material';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useMeQueryData } from '../../../../queries/user';
import { DotDivider } from '../../../../components/common/DotDivider';
import { useTeamBillingPeriods } from '../../../../queries/team';

export const PlanUsage = () => {
    const me = useMeQueryData();
    const { data: teamBillingPeriods = [] } = useTeamBillingPeriods(1);

    const daysLeftInMonth = useMemo(() => {
        const now = dayjs();
        const totalDaysInMonth = now.daysInMonth();
        const currentDayOfMonth = now.date();
        return totalDaysInMonth - currentDayOfMonth;
    }, []);

    const [used, limit]: [used: number, limit: number] = useMemo(() => {
        const bp = teamBillingPeriods[0];

        if (bp) {
            return [bp.segmentsUsed, bp.planSegments];
        }

        return [0, 0];
    }, [teamBillingPeriods]);

    const progress = useMemo(() => {
        if (teamBillingPeriods.length === 0) {
            return 0;
        }

        if (!me) {
            return 0;
        }
        return Math.round((used / limit) * 100 * 10) / 10;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [used, limit]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" fontWeight={500}>
                    Plan Usage
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography variant="body2" fontWeight={500}>
                        {used.toLocaleString('en-US')}
                    </Typography>

                    <Typography variant="body4" color="text.secondary">
                        messages
                    </Typography>
                </Box>
            </Box>

            <LinearProgress
                value={progress}
                variant="determinate"
                color={progress >= 100 ? 'error' : 'warning'}
                sx={{ mt: 6, mb: 4 }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography variant="body4" color="text.secondary" mr={1}>
                        Current usage:
                    </Typography>
                    <Typography variant="body4" fontWeight={500}>
                        {used.toLocaleString('en-US')} msg.
                        {used && `(${progress}%)`}
                    </Typography>
                </Box>
                <DotDivider />
                <Box>
                    <Typography variant="body4" color="text.secondary" mr={1}>
                        Days left in month:
                    </Typography>
                    <Typography variant="body4" fontWeight={500}>
                        {daysLeftInMonth}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
