import { PagedData } from '../types/PagedData';
import { queryClient } from '../queries/queryClient';
import {
    Conversation,
    ConversationStatus,
    Message,
    Profile,
} from '../api/types';
import { CURRENT_USER_KEY } from '../queries/user';

import {
    CONVERSATION_MESSAGES_KEY,
    UNREADS_COUNT_KEY,
} from '../queries/messages';
import { CONVERSATIONS_LIST_KEY } from '../queries/conversations';
import { getLatestQueryByKey } from '../utils/query-utils';
import throttle from 'lodash/throttle';
import uniq from 'lodash/uniq';
import { MessageEvent } from './types';
import uniqBy from 'lodash/uniqBy';
import { InfiniteData } from '@tanstack/react-query';
import { isAssistant } from '../components/ConversationRow/Assistant/check-is-until-review';
import { CAMPAIGN_MESSAGES } from '../main/campaign/v3/use-campaign-messages';

// HANDLE APP STATE

const addOrUpdateConversationsState = (conversation: Conversation) => {
    const activeConversations = queryClient.getQueryData<
        PagedData<Conversation>
    >(['conversations', conversation.inboxId, ConversationStatus.Active]);

    const archivedConversations = queryClient.getQueryData<
        PagedData<Conversation>
    >(['conversations', conversation.inboxId, ConversationStatus.Archived]);

    if (!activeConversations) {
        queryClient.setQueryData(
            ['conversations', conversation.inboxId, ConversationStatus.Active],
            {
                pages: [[conversation]],
                pageParams: [conversation.updated],
            },
        );
        return;
    }

    // Looking for conversation in store
    let foundAndUpdated = false;
    let updatedPages;
    updatedPages = activeConversations?.pages.map((page) => {
        return page.map((c) => {
            if (c.id === conversation.id) {
                foundAndUpdated = true;
                return conversation;
            }
            return c;
        });
    });
    if (!foundAndUpdated) {
        updatedPages = archivedConversations?.pages.map((page) => {
            return page.map((c) => {
                if (c.id === conversation.id) {
                    foundAndUpdated = true;
                    return conversation;
                }
                return c;
            });
        });
    }

    // If such a conversation already in store
    if (foundAndUpdated) {
        queryClient.setQueryData(
            ['conversations', conversation.inboxId, ConversationStatus.Active],
            {
                pages: updatedPages,
                pageParams: activeConversations?.pageParams,
            },
        );
        return;
    }

    // If such a conversation is no in store yet
    const [firstPagesBlock, ...others] = activeConversations.pages;
    const pagesWithNewConversation = [
        [conversation, ...firstPagesBlock],
        ...others,
    ];
    queryClient.setQueryData(
        ['conversations', conversation.inboxId, ConversationStatus.Active],
        {
            pages: pagesWithNewConversation,
            pageParams: activeConversations?.pageParams,
        },
    );
};

const addMessageToStore = (message: Message, conversation: Conversation) => {
    queryClient.setQueryData<InfiniteData<Message[]>>(
        [CONVERSATION_MESSAGES_KEY, conversation.id],
        (prev) => {
            if (!prev) {
                return {
                    pages: [[message]],
                    pageParams: [message.created],
                };
            }
            return {
                ...prev,
                pages: [
                    uniqBy((prev.pages[0] || []).concat(message), 'id'),
                    ...prev.pages.slice(1),
                ],
            };
        },
    );
    queryClient.invalidateQueries({ queryKey: [UNREADS_COUNT_KEY] });
};

type DataPayload = {
    message: Message;
    conversation: Conversation;
};

export const NEW_MESSAGE_EVENT = MessageEvent.Created;

export type MessageCreateHandler = ({
    conversation,
    message,
}: DataPayload) => void;

let pairs: Record<
    string,
    [Message & { campaignId: number | null }, Conversation]
> = {};

const removeOldAssists = (message: Message, conversation: Conversation) => {
    queryClient.setQueryData<InfiniteData<Message[]>>(
        [CONVERSATION_MESSAGES_KEY, conversation.id],
        (prev) => {
            if (!prev) {
                return prev;
            }

            return {
                ...prev,
                pages: prev.pages.map((messages) =>
                    (messages || []).filter((item) => {
                        return (
                            !isAssistant(item.senderType) ||
                            item.status !== 'scheduled' ||
                            item.id === message.id
                        );
                    }),
                ),
            };
        },
    );
};

const throttledUpdates = throttle(async () => {
    const me = queryClient.getQueryData<Profile>([CURRENT_USER_KEY])?.id;
    const values = Object.values(pairs);
    if (values.some(([{ inbound }]) => inbound)) {
        void queryClient.invalidateQueries({ queryKey: [UNREADS_COUNT_KEY] });
    }
    const campaigns: number[] = [];
    values.forEach(([message, conversation]) => {
        if (message.campaignId) {
            campaigns.push(message.campaignId);
        }
        if (me !== message.user?.id) {
            addOrUpdateConversationsState(conversation);
            /* note: possible improvements: chaining setQueryData on message history to omit double call */
            addMessageToStore(message, conversation);
            if (isAssistant(message.senderType)) {
                removeOldAssists(message, conversation);
            }
        }
    });
    uniq(campaigns).forEach((campaignId) => {
        queryClient.invalidateQueries({
            queryKey: [CAMPAIGN_MESSAGES, campaignId],
        });
    });

    // TODO: remove this hack when a better approach to real time conversation updates is implemented
    await new Promise((resolve) => setTimeout(resolve, 1000));

    uniq(values.map(([_, conversation]) => conversation.inboxId)).forEach(
        (inboxId) => {
            // When a new message arrives, we must refresh the conversation list so that the conversations appear in the correct order
            if (!window.location.pathname.match(/\/inbox\//)) {
                return;
            }
            const activeInboxId = window.location.pathname.split('/')[2];
            if (activeInboxId !== inboxId) {
                return;
            }
            const query = getLatestQueryByKey(queryClient, [
                CONVERSATIONS_LIST_KEY,
                inboxId,
            ]);

            /** todo: update the logic to avoid refreshing the conversations fully */
            queryClient.cancelQueries({ queryKey: query.queryKey.slice(0, 2) });
            queryClient.refetchQueries({
                queryKey: query.queryKey.slice(0, 2),
            });
        },
    );
    pairs = {};
}, 1_000);

export const handleNewMessageEvent = ({
    message,
    conversation,
}: DataPayload) => {
    pairs[`${message.id}:${conversation.id}`] = [message, conversation];
    throttledUpdates();
};
