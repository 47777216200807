import cc from 'classcat';
import styles from './DropdownItem.module.scss';
import { useCallback } from 'react';

type DropdownItemClickHandler = (
    e: React.MouseEvent<HTMLLIElement | HTMLButtonElement>,
) => void;

type DropdownItemProps = {
    variant?: 'button' | 'list-item';
    type?: 'rounded' | 'regular';
    className?: string;
    disabled?: boolean;
    children: React.ReactNode;
    onClick: DropdownItemClickHandler;
};

export const DropdownItem = ({
    children,
    variant = 'button',
    type = 'rounded',
    className,
    disabled = false,
    onClick,
}: DropdownItemProps) => {
    const classes = cc([
        styles['dropdown-item'],
        {
            [styles['dropdown-item_rounded']]: type === 'rounded',
            [styles['dropdown-item_simple']]: variant === 'list-item',
            [styles['dropdown-item_disabled']]: disabled,
        },
        className,
    ]);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
            if (!disabled) {
                onClick(e);
            } else {
                e.stopPropagation();
            }
        },
        [onClick, disabled],
    );

    if (variant === 'button') {
        return (
            <li className={classes}>
                <button
                    className={styles['dropdown-item__button']}
                    onClick={handleClick}
                >
                    {children}
                </button>
            </li>
        );
    }
    return (
        <li className={classes} onClick={handleClick}>
            {children}
        </li>
    );
};
