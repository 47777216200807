import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import { Inbox } from '../../api/types';
import { useInboxUpdateQuery } from '../../queries/inboxes';
import { Box } from '@mui/material';
import InboxItem from './InboxItem';
import { list, draggingItem } from './styles';

interface Props {
    index: number;
    inbox: Inbox;
    isMuted?: boolean;
    onClick?: () => void;
}

export const InboxDraggableItem = ({ index, inbox, onClick }: Props) => {
    const { isPending } = useInboxUpdateQuery();

    return (
        <Draggable key={inbox.id} draggableId={inbox.id} index={index}>
            {(providedDraggable: DraggableProvided, snapshot) => {
                return (
                    <Box
                        component="li"
                        sx={list}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                    >
                        <InboxItem
                            inbox={inbox}
                            onClick={onClick}
                            sx={snapshot.isDragging ? draggingItem : undefined}
                            isPending={isPending}
                        />
                    </Box>
                );
            }}
        </Draggable>
    );
};
