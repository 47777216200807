import { CircularProgress, IconButton, Popover } from '@mui/material';
import { emojiIcon, inboxItemPaper } from './styles';
import EmojiPicker from '../../components/EmojiPicker';
import { renderInboxIcon } from '../../utils/inboxes';
import { Inbox } from '../../api/types';
import { useMenu } from '../../hooks/use-menu';
import { useInboxUpdateQuery } from '../../queries/inboxes';
import { useTrack } from '../../contexts/analytics';

export function InboxIconSelector({ inbox }: { inbox: Inbox }) {
    const track = useTrack();
    const menu = useMenu();
    const { id, icon } = inbox;
    const { mutateAsync, isPending } = useInboxUpdateQuery();

    return (
        <>
            <Popover
                onClose={menu.handleClose}
                transformOrigin={{
                    horizontal: -35,
                    vertical: 'center',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={menu.open}
                anchorEl={menu.anchorEl}
                slotProps={{
                    paper: {
                        sx: inboxItemPaper,
                    },
                }}
            >
                <EmojiPicker
                    onClick={(val) => {
                        if (!val) {
                            return;
                        }
                        const hex = val.codePointAt(0);
                        if (hex) {
                            mutateAsync({
                                icon: hex.toString(16),
                                id,
                            }).then(() => {
                                track('inbox_icon_changed', {
                                    inboxId: id,
                                    origin: 'sidebar inbox list',
                                });
                            });
                        }
                        menu.handleClose();
                    }}
                    onClose={menu.handleClose}
                />
            </Popover>

            <IconButton
                color="primary"
                sx={emojiIcon}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    track('inbox_icon_picker_open', {
                        inboxId: id,
                        origin: 'sidebar inbox list',
                    });
                    menu.handleOpen(e);
                }}
            >
                {isPending ? (
                    <CircularProgress size={20} />
                ) : (
                    renderInboxIcon(icon)
                )}
            </IconButton>
        </>
    );
}
