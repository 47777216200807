import styles from './PhoneVerification.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Spacer from '../../components/Spacer';
import { useBrandQuery } from '../../queries/settings';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PhoneVerificationProps } from './PhoneVerification';
import {
    useCheckHostabilityQueryData,
    useHostInboxMutation,
    useInboxQueryData,
    usePortInboxMutation,
} from '../../queries/inboxes';
import RectangleFrame from '../../components/RectangleFrame';
import { Icon } from '../../icons/Icon';
import dayjs from 'dayjs';
import { HostDisclaimer, PortDisclaimer } from './helpers';
import { InboxProvider } from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

export interface SignatureVerificationStepFormData {
    hasTeamsLicense?: boolean;
    hasAgreedToTransferPolicy?: boolean;
    hasBandwidthOC?: boolean;
    hasRemovedTeamsCallingPlan?: boolean;
}

export const SignatureVerificationStep = () => {
    const { inboxId } = useParams<PhoneVerificationProps>();
    const navigate = useNavigate();

    const { control, handleSubmit } =
        useForm<SignatureVerificationStepFormData>();

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const brand = useBrandQuery();
    const inbox = useInboxQueryData(inboxId ?? '');
    const hostCheck = useCheckHostabilityQueryData(inbox?.phone ?? '');
    const track = useTrack();

    const { mutateAsync: hostNumber } = useHostInboxMutation(inboxId ?? '');
    const { mutateAsync: portNumber } = usePortInboxMutation(inboxId ?? '');

    const onSubmit: SubmitHandler<
        SignatureVerificationStepFormData
    > = async () => {
        try {
            setLoading(true);
            hostCheck?.hostable ? await hostNumber() : await portNumber();

            track('verification_phone_signature');

            setTimeout(() => {
                navigate(`/inbox/${inboxId}`);
                setLoading(false);
            }, 5000);
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <form
            className={styles['root__form']}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={styles['form-row']}>
                {hostCheck ? <HostDisclaimer /> : <PortDisclaimer />}
            </div>
            {inbox?.provider === InboxProvider.MSTEAMS &&
                !hostCheck?.hostable && (
                    <>
                        <div className={styles['form-row']}>
                            <Spacer height="auto" width="100%">
                                <RectangleFrame variant="light">
                                    <Controller
                                        name="hasBandwidthOC"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            required: true,
                                            validate: (v) => v,
                                        }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                sx={{ m: 0 }}
                                                control={
                                                    <Checkbox
                                                        color="info"
                                                        sx={{ p: 0, mr: 4 }}
                                                        checked={field.value}
                                                        size="small"
                                                        {...field}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        variant="body3"
                                                        color="primary.dark"
                                                    >
                                                        I&apos;ve activated
                                                        Bandwidth via{' '}
                                                        <a
                                                            href="https://admin.teams.microsoft.com/operators/6e0bc3a3-43f7-4374-80ee-b2ae0af3c0d5/edit/1591fe31-8acc-4ce1-aaff-09aca16aac95"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Microsoft Operator
                                                            Connect
                                                        </a>{' '}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </RectangleFrame>
                            </Spacer>
                        </div>
                        {inbox.locationType !== 'IVR' && (
                            <div className={styles['form-row']}>
                                <Spacer height="auto" width="100%">
                                    <RectangleFrame variant="light">
                                        <Controller
                                            name="hasTeamsLicense"
                                            control={control}
                                            defaultValue={false}
                                            rules={{
                                                required: true,
                                                validate: (v) => v,
                                            }}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    sx={{ m: 0 }}
                                                    control={
                                                        <Checkbox
                                                            sx={{ p: 0, mr: 4 }}
                                                            color="info"
                                                            checked={
                                                                field.value
                                                            }
                                                            size="small"
                                                            {...field}
                                                        />
                                                    }
                                                    label="I have a Teams Phone Standard license or E5 license"
                                                />
                                            )}
                                        />
                                    </RectangleFrame>
                                </Spacer>
                            </div>
                        )}
                        <div className={styles['form-row']}>
                            <Spacer height="auto" width="100%">
                                <RectangleFrame variant="light">
                                    <Controller
                                        name="hasRemovedTeamsCallingPlan"
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            required: true,
                                            validate: (v) => v,
                                        }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                sx={{ m: 0 }}
                                                control={
                                                    <Checkbox
                                                        sx={{ p: 0, mr: 4 }}
                                                        color="info"
                                                        checked={field.value}
                                                        size="small"
                                                        {...field}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        variant="body3"
                                                        color="primary.dark"
                                                    >
                                                        I have removed the Teams
                                                        calling plan. Questions?
                                                        Get help{' '}
                                                        <a
                                                            href="https://support.clerk.chat/microsoft-teams"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            here
                                                        </a>
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </RectangleFrame>
                            </Spacer>
                        </div>
                    </>
                )}
            <div className={styles['form-row']}>
                <Spacer height="auto" width="100%">
                    <RectangleFrame variant="light">
                        <Controller
                            name="hasAgreedToTransferPolicy"
                            control={control}
                            defaultValue={false}
                            rules={{ required: true, validate: (v) => v }}
                            render={({ field }) => (
                                <FormControlLabel
                                    sx={{ m: 0 }}
                                    control={
                                        <Checkbox
                                            sx={{ p: 0, mr: 4 }}
                                            color="info"
                                            checked={field.value}
                                            size="small"
                                            {...field}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body3"
                                            color="primary.dark"
                                        >
                                            I agree to the{' '}
                                            <a
                                                href="https://clerk.chat/legal/terms"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                terms and conditions ↗
                                            </a>
                                        </Typography>
                                    }
                                />
                            )}
                        />
                    </RectangleFrame>
                </Spacer>
            </div>
            <div className={styles['form-row']}>
                <Spacer height="auto" width="50%">
                    <RectangleFrame>
                        <div className={styles['authorization-sig']}>
                            <div className={styles['icon-row']}>
                                <Icon name="user-signature" size="16px" />{' '}
                                Authorized Person
                            </div>
                            <span>
                                {`${brand.data?.contactFirstName} ${brand.data?.contactLastName}`}
                            </span>
                        </div>
                    </RectangleFrame>
                </Spacer>
                <Spacer height="auto" width="50%">
                    <RectangleFrame>
                        <div className={styles['authorization-sig']}>
                            <div className={styles['icon-row']}>
                                <Icon name="calendar" size="16px" /> Date
                            </div>
                            <span>{dayjs().format('MMMM D, YYYY')}</span>
                        </div>
                    </RectangleFrame>
                </Spacer>
            </div>
            {isError && (
                <div className={styles['form-row']}>
                    Sorry, something went astray with your request. Please
                    contact support
                </div>
            )}
            <Spacer height={0} width="auto" />
            <div className={styles['form-row']}>
                <Spacer height="auto" width="50%">
                    <Spacer height="auto" width="96px">
                        <Button
                            variant="outlined"
                            isLoading={isLoading}
                            onClick={() => history.back()}
                            disabled={isLoading}
                        >
                            Back
                        </Button>
                    </Spacer>
                </Spacer>
                <Spacer height="auto" width="50%">
                    <Button
                        isLoading={isLoading}
                        disabled={isLoading}
                        fullWidth
                        type="submit"
                    >
                        Complete Verification
                    </Button>
                </Spacer>
            </div>
        </form>
    );
};
