import { Attachment } from '../../../../api/types';
import isEmpty from 'lodash/isEmpty';
import { Stack, StackProps } from '@mui/material';
import { MessageAttachmentItem } from './MessageAttachmentItem';

type Props = { attachments: Attachment[] } & StackProps;

export const Attachments = ({ attachments, ...props }: Props) => {
    if (isEmpty(attachments)) {
        return null;
    }

    const isNonImageExist = attachments.some(
        (attachment) => !attachment.type || !attachment.type.includes('image/'),
    );

    return (
        <Stack
            direction="row"
            spacing={2}
            flexWrap="wrap"
            useFlexGap
            {...props}
        >
            {attachments.map((attachment) => (
                <MessageAttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    isNonImageExist={isNonImageExist}
                />
            ))}
        </Stack>
    );
};
