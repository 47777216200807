import { useState, MouseEvent } from 'react';

type ElementType = HTMLButtonElement | HTMLLIElement;

export type MenuProps = {
    anchorEl: ElementType | null;
    open: boolean;
    handleClose: () => void;
    handleOpen: (event: MouseEvent<ElementType>) => void;
};

export const useMenu = (): MenuProps => {
    const [anchorEl, setAnchorEl] = useState<ElementType | null>(null);
    const open = Boolean(anchorEl);

    const handleOpen: MenuProps['handleOpen'] = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return { anchorEl, open, handleOpen, handleClose };
};
