import {
    Components,
    inputBaseClasses,
    selectClasses,
    svgIconClasses,
    Theme,
} from '@mui/material';
import { getSquare } from './style.helpers';
import { colors } from './palette';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
    styleOverrides: {
        root: {
            height: 40,
            lineHeight: '20px',
            fontSize: 14,

            [`&.${inputBaseClasses.adornedStart} .${selectClasses.select}`]: {
                paddingLeft: 0,
            },
        },
        multiline: {
            height: 'auto',
        },
        inputAdornedStart: {
            paddingLeft: 0,
        },
        inputAdornedEnd: {
            paddingRight: 0,
        },
        adornedStart: {
            paddingLeft: 12,
        },
        adornedEnd: {
            paddingRight: 12,
        },
        input: ({ theme }) => ({
            padding: [theme.spacing(2.5), theme.spacing(3)],

            '&::placeholder': {
                color: theme.palette.custom.gray.disabledText,
                opacity: 1,
            },
        }),
        sizeSmall: ({ theme }) => ({
            height: 32,
            [`.${svgIconClasses.root}`]: {
                ...getSquare(20),
                color: colors.superGrey[500],
            },
            [`&.${inputBaseClasses.adornedStart} .${svgIconClasses.root}`]: {
                marginRight: theme.spacing(2),
            },
        }),
    },
};
