import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { client } from '../../api/http';
import { Contact } from '../../api/types';
import { UUID } from '../../types/uuid';
import { Constraint } from './properties.types';
import { useEffect, useRef } from 'react';

export type Page<T> = {
    data: T;
    page: number;
    total: number;
    limit: number;
};

export type SlimFilterConstraint =
    | Constraint.GreaterThan
    | Constraint.Equal
    | Constraint.NotEqual
    | Constraint.Includes
    | Constraint.NotIncludes
    | Constraint.LessThan
    | Constraint.Between;

type SlimValueFilter = {
    propertyId: UUID;
    logic: 'AND' | 'OR';
    constraint: SlimFilterConstraint;
    value: string;
};
type SlimArrayFilter = {
    propertyId: UUID;
    logic: 'AND' | 'OR';
    constraint: Constraint.All | Constraint.Between;
    value: string[] | Date[];
};
type SlimNoValueFilter = {
    propertyId: UUID;
    logic: 'AND' | 'OR';
    constraint: Constraint.Isset;
};

export const NEXT_CONTACTS = 'next_contacts';

export type SlimFilter = SlimValueFilter | SlimNoValueFilter | SlimArrayFilter;

export const useContacts = (
    filters: {
        cohortId?: number;
        query?: string;
        filters?: SlimFilter[];
        limit?: number;
    } = {},
) => {
    const queryClient = useQueryClient();

    const previousFilters = useRef(filters);

    useEffect(() => {
        const filtersChanged =
            JSON.stringify(previousFilters.current) !== JSON.stringify(filters);

        if (filtersChanged) {
            queryClient.invalidateQueries({
                queryKey: [
                    NEXT_CONTACTS,
                    JSON.stringify(previousFilters.current),
                ],
            });
            previousFilters.current = filters;
        }
    }, [filters, queryClient]);

    /*todo: add mutators of new contacts for WS and http calls*/
    return useInfiniteQuery<Page<Contact[]>>({
        queryKey: [NEXT_CONTACTS, JSON.stringify(filters)],
        initialPageParam: 1,
        getNextPageParam: (last) =>
            last.data.length < last.limit ? null : (last.page || 0) + 1,
        queryFn: ({ pageParam: page, signal }) =>
            client
                .get<Page<Contact[]>>('/v2/contacts', {
                    params: {
                        page,
                        cohortId: filters.cohortId,
                        query: filters.query?.trim() || undefined,
                        filters: filters.filters,
                        limit: filters.limit,
                    },
                    signal,
                })
                .then(({ data }) => data),
    });
};
