import isEmpty from 'lodash/isEmpty';
import { BrandRegistrationStatus } from '../api/types';
import { useBrandQuery } from '../queries/settings';

export const useCampaignRegistrationStatus = ():
    | 'Registered'
    | 'Failed'
    | 'Pending'
    | 'Unverified' => {
    const { data: brand, isLoading } = useBrandQuery();

    if (isLoading) {
        return 'Unverified';
    }

    if (
        !brand?.id ||
        brand?.status === BrandRegistrationStatus.UNVERIFIED ||
        isEmpty(brand?.status)
    ) {
        return 'Unverified';
    }

    if (
        brand?.status === BrandRegistrationStatus.FAILED ||
        brand?.campaignStatus === 'failed'
    ) {
        return 'Failed';
    }

    if (
        brand?.status === BrandRegistrationStatus.VERIFIED &&
        brand?.campaignStatus === 'added'
    ) {
        return 'Registered';
    } else {
        return 'Pending';
    }
};
