import { Outlet, useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { useEnabledFeature } from '../../queries/user';
import { SetupInboxProvider } from './setup-inbox.context';
import { ClerkPermission, Feature } from '../../api/types';
import { usePermission } from '../WithPermission/WithPermission';
import { useTeams } from 'msteams-react-base-component';
import { useInboxes } from '../../queries/inboxes';

export const AddInbox = () => {
    const { step } = useParams<{ step: string }>();
    const navigate = useNavigate();
    const [{ inTeams }] = useTeams();
    const { data: inboxes = [], isLoading } = useInboxes();

    const canModify = usePermission(ClerkPermission.ModifyTeam);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (
            !step ||
            ![
                'number-type',
                'existing-number',
                'ms-teams',
                'carrier-connect',
                'carrier-number',
                'addons',
                'customization',
            ].includes(step)
        ) {
            navigate(
                inTeams && !inboxes.length
                    ? '/setup-inbox/ms-teams/auth'
                    : '/setup-inbox/existing-number',
                { replace: true },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inTeams, inboxes, isLoading]);

    const canCreateNewNumber = useEnabledFeature(Feature.CreateNewNumber);
    const canImportNewNumber = useEnabledFeature(Feature.ImportNumber);

    useEffect(() => {
        if (!canModify || (!canImportNewNumber && !canCreateNewNumber)) {
            navigate('/contacts/book');
        }
    }, [canImportNewNumber, canCreateNewNumber, canModify, navigate]);

    return (
        <SetupInboxProvider>
            <Outlet />
        </SetupInboxProvider>
    );
};
