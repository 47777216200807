import { Tooltip, Typography } from '@mui/material';
import { useCampaignRegistrationStatus } from '../../hooks/useCampaignRegistrationStatus';
import { useMemo } from 'react';

export const CompanyRegistrationTooltip = () => {
    const status = useCampaignRegistrationStatus();

    const [icon, message] = useMemo(() => {
        switch (status) {
            case 'Registered':
                return ['✅', 'Company Registered'];
            case 'Failed':
                return [
                    '❌',
                    'Company Registration Failed - Please contact support.',
                ];
            case 'Pending':
                return ['⏳', 'Company Registration Pending'];
            default:
                return ['🚨', 'Company Registration Required'];
        }
    }, [status]);

    return (
        <Tooltip title={message}>
            <Typography fontSize={16}>{icon}</Typography>
        </Tooltip>
    );
};
