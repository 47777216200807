const colors = [
    '#FFE0B5',
    '#ADB0FF',
    '#97BAFF',
    '#97F9FF',
    '#BDFFE3',
    '#CAFFBD',
    '#FFB1AC',
];
const gradients = [
    'linear-gradient(180deg, #FFE0B5 0%, #F2AC6A 100%)',
    'linear-gradient(180deg, #ADB0FF 0%, #985FF6 100%)',
    'linear-gradient(180deg, #97BAFF 0%, #305CCE 100%)',
    'linear-gradient(180deg, #97F9FF 0%, #309FCE 100%)',
    'linear-gradient(180deg, #BDFFE3 0%, #1FB79B 100%)',
    'linear-gradient(180deg, #CAFFBD 0%, #65B823 100%)',
    'linear-gradient(180deg, #FFB1AC 0%, #C65650 100%)',
];

/* todo: add Avatar wrapper with avatar based on alt */
export const getAvatarGradient = (code: string | number) => {
    const hashCode = Math.abs(
        [...`${code}`].reduce(
            (acc, char) => (acc << 5) - acc + char.charCodeAt(0),
            0,
        ),
    );

    return gradients[hashCode % colors.length];
};
