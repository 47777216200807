import { WarningIcon } from '../../../../icons/common/WarningIcon';
import { getSquare } from '../../../../theme/style.helpers';
import { Box, Stack } from '@mui/material';
import { status } from '../../../../components/ChatComponents/Message/styles';

export function FailedStatus() {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <WarningIcon sx={{ ...getSquare(16), color: 'error.main' }} />
            <Box typography="body4" sx={status}>
                Message Failed to Send
            </Box>
        </Stack>
    );
}
