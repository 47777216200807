import { NavigationItemProps } from '../../types/ElementsProps';
import { ListItemIcon, MenuItem, Stack, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { navItemIconForNarrowScreens, navItemStyle } from './styles';
import { useSidebar } from '../../contexts/sidebar';

const NavigationItem = ({
    icon,
    to,
    onClick,
    label,
    children,
}: NavigationItemProps) => {
    const { isCollapsed } = useSidebar();

    const menuItem = (
        <MenuItem
            sx={navItemStyle}
            to={to}
            component={NavLink}
            onClick={(e) => {
                onClick?.(e);
            }}
        >
            <ListItemIcon
                sx={isCollapsed ? navItemIconForNarrowScreens : undefined}
            >
                {icon}
            </ListItemIcon>

            {!isCollapsed && (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flex={1}
                >
                    <span>{label}</span>
                    {children}
                </Stack>
            )}
        </MenuItem>
    );

    if (isCollapsed) {
        return (
            <Tooltip disableFocusListener arrow title={label} placement="right">
                {menuItem}
            </Tooltip>
        );
    }

    return menuItem;
};

export default NavigationItem;
