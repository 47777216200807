import { Inbox, InboxProvider } from '../../../api/types';
import { useTrack } from '../../../contexts/analytics';
import { useNavigate } from 'react-router';
import { useSetupInboxContext } from '../setup-inbox.context';
import { useInboxesQueryData, useReservePhone } from '../../../queries/inboxes';
import { useCallback, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { getInboxData } from '../utils';

export function useCreateInboxes(): [
    (newInboxes: Partial<Inbox>[]) => Promise<void>,
    { isLoading: boolean; error: string | null; resetError: () => void },
] {
    const track = useTrack();
    const navigate = useNavigate();
    const inboxes = useInboxesQueryData();
    const { carrierCredentials, carrier } = useSetupInboxContext();
    const { mutateAsync: reservePhone } = useReservePhone();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const createInboxes = useCallback(
        async (newInboxes: Partial<Inbox>[]) => {
            setIsLoading(true);
            const startingIndex = (inboxes ?? []).length;

            try {
                const createInbox = async (
                    inboxObj: Partial<Inbox>,
                    index: number,
                ) => {
                    const provider = inboxObj?.provider ?? carrier!;

                    const inboxData = getInboxData({
                        provider,
                        inboxObj,
                        index,
                        carrierCredentials,
                        startingIndex,
                    });

                    if (inboxData === null) {
                        return Promise.resolve(null);
                    }

                    if (
                        [
                            InboxProvider.MSTEAMS,
                            InboxProvider.OTHER,
                            InboxProvider.WEBEX,
                        ].includes(provider)
                    ) {
                        track('onboarding_user_imported_number', {
                            phone: inboxObj?.phone,
                            provider: carrier,
                        });
                    }

                    return await reservePhone(inboxData);
                };
                const createdInboxes = await Promise.all(
                    newInboxes.map(createInbox),
                );

                if (createdInboxes.length) {
                    if (
                        carrier === InboxProvider.MSTEAMS ||
                        carrier === InboxProvider.OTHER
                    ) {
                        return navigate(
                            `/inbox/${createdInboxes[0]?.id}/verify/customer`,
                        );
                    }

                    return navigate(`/inbox/${createdInboxes[0]?.id}/finish`);
                }

                throw new Error(
                    'Unable to create an inbox. Please check the provider and credentials',
                );
            } catch (error) {
                if (error instanceof Error) {
                    Sentry.captureException(error);
                }

                setError('Something went wrong. Please try again.');
            }

            setIsLoading(false);
        },
        [carrier, reservePhone, carrierCredentials, track, navigate, inboxes],
    );

    return useMemo(
        () => [
            createInboxes,
            {
                isLoading,
                error,
                resetError: () => setError(null),
            },
        ],
        [createInboxes, isLoading, error],
    );
}
