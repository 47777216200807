import { Box, ButtonBase, Chip, IconButton, Typography } from '@mui/material';
import { Attachment } from '../../../../api/types';
import { useState } from 'react';
import { AttachmentBackdrop } from './AttachmentBackdrop';
import {
    MimeTypeIcon,
    mimeTypeNameMap,
} from '../../../../icons/common/files/MimeTypeIcon';
import { useTrack } from '../../../../contexts/analytics';
import { getSquare } from '../../../../theme/style.helpers';
import { CustomDownload } from '../../../../icons/common/CustomDownload';
import { useDownloadAttachment } from '../../../../components/MessageForm/use-attachment';
import { downloadButton, image } from './MessageAttachmentItem.styles';
import { colors } from '../../../../theme/palette';

type Props = { attachment: Attachment; isNonImageExist: boolean };

export const MessageAttachmentItem = ({
    attachment,
    isNonImageExist,
}: Props) => {
    const track = useTrack();
    const { mutate: download } = useDownloadAttachment(attachment);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        track('attachment_previewed');
    };

    const handleClose = () => {
        setOpen(false);
    };
    const isImage = attachment.type && attachment.type.includes('image/');

    if (
        attachment.type &&
        attachment.type.includes('image/') &&
        !isNonImageExist
    ) {
        return (
            <>
                <ButtonBase
                    onClick={handleClickOpen}
                    aria-label="Attachment preview"
                    sx={image}
                >
                    <Box
                        component="img"
                        sx={{
                            maxWidth: 1,
                            maxHeight: 1,
                            objectFit: 'cover',
                        }}
                        src={attachment.url}
                        alt={attachment.name}
                    />
                    <IconButton
                        sx={downloadButton}
                        size="medium"
                        color="white-contained"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            track('attachment_downloading', {
                                attachmentType: attachment.type,
                            });
                            download();
                        }}
                    >
                        <CustomDownload />
                    </IconButton>
                </ButtonBase>
                <AttachmentBackdrop
                    attachment={attachment}
                    open={open}
                    onClick={handleClose}
                />
            </>
        );
    }

    return (
        <Chip
            variant="outlined"
            icon={
                isImage ? (
                    <Box>
                        <Box
                            component="img"
                            src={attachment.url}
                            alt={attachment.name}
                        />
                    </Box>
                ) : (
                    <MimeTypeIcon
                        type={attachment.type}
                        sx={{
                            ...getSquare(32),
                            maxWidth: 1,
                            maxHeight: 1,
                        }}
                    />
                )
            }
            onClick={handleClickOpen}
            label={
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0.5,
                        }}
                    >
                        <Typography variant="body4">
                            {attachment.name}
                        </Typography>
                        <Typography variant="body5" color="text.secondary">
                            {isImage
                                ? 'Image'
                                : (mimeTypeNameMap[attachment.type] ?? 'File')}
                        </Typography>
                    </Box>
                    <IconButton
                        sx={downloadButton}
                        size="medium"
                        color="white-contained"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            track('attachment_downloading', {
                                attachmentType: attachment.type,
                            });
                            download();
                        }}
                    >
                        <CustomDownload />
                    </IconButton>
                </>
            }
            sx={{
                height: 48,
                width: { xs: '100%', sm: 188 },
                gap: '8px',
                justifyContent: 'flex-start',
                '.MuiChip-icon': {
                    width: 32,
                    height: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    ml: 0,
                    '& img': {
                        width: 'auto',
                        height: 'auto',
                        maxWidth: 32,
                        maxHeight: 32,
                    },
                },
                '&:hover': {
                    '&.MuiChip-clickable': {
                        backgroundColor: colors.primary.white,
                    },
                    '& button': {
                        display: 'block',
                    },
                },
            }}
        />
    );
};
