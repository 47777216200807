import { useIsDownMd } from '../../hooks/useIsDownMd';

export const HideDownMd = ({
    children,
}: {
    children: React.ReactNode;
}): null | React.ReactNode => {
    const isDownMd = useIsDownMd();
    return isDownMd ? null : children;
};
