import { Components, Theme } from '@mui/material';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
    defaultProps: {
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.button,
            flex: '1 1 auto',
            textTransform: 'none',
            padding: theme.spacing(1.5, 3),
            borderColor: theme.palette.custom.gray.disabledBackground,
            color: theme.palette.primary.dark,
            border: 'none',
            boxSizing: 'border-box',

            '&.MuiToggleButton-toggle_gray:hover, &.MuiToggleButton-toggle_gray.Mui-selected:hover':
                {
                    backgroundColor: theme.palette.custom.gray.tableDivider,
                },

            '&.MuiToggleButton-toggle_gray.Mui-selected': {
                backgroundColor: theme.palette.custom.action.selected,
            },

            '&.Mui-selected': {
                boxShadow: theme.shadows[1],

                border: `1px solid`,
                borderColor: theme.palette.custom.gray.outlinedBorder,
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.dark,
            },
        }),
        sizeMedium: {
            height: 32,
        },
        sizeSmall: {
            padding: 0,
            svg: {
                width: 20,
                height: 20,
            },
        },
    },
};
