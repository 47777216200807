import isEmpty from 'lodash/isEmpty';
import { Box } from '@mui/material';
import { Attachment } from '../../../../../api/types';
import { AttachmentItem } from './AttachmentItem';
import { attachmentsHolder } from './styles';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    attachments: Attachment[];
    removeAttachment: (a: Attachment) => void;
}>;

export const AttachmentList = ({
    attachments,
    removeAttachment,
    children,
}: Props) => {
    return (
        (!isEmpty(attachments) || !!children) && (
            <Box sx={attachmentsHolder}>
                {attachments.map((attachment, i) => (
                    <AttachmentItem
                        key={`${attachment.name}/${i}`}
                        attachment={attachment}
                        onRemove={removeAttachment}
                    />
                ))}
                {children}
            </Box>
        )
    );
};
