import { Message, MessageStatus, SenderType } from '../../../../api/types';
import { alpha, SxProps, Theme } from '@mui/material';
import { colors } from '../../../../theme/palette';

export const container = ({
    inbound,
    senderType,
    status,
}: Pick<Message, 'inbound' | 'senderType' | 'status'>): SxProps<Theme> => ({
    maxWidth: [320, 600],
    width: 'fit-content',
    borderRadius: 1,
    px: 2.5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    py: 3,
    color: (theme) =>
        alpha(
            theme.palette.primary.dark,
            status === MessageStatus.Failed && !inbound ? 0.48 : 1,
        ),
    bgcolor:
        ([SenderType.CAMPAIGN, SenderType.CAMPAIGN_MESSAGE].includes(
            senderType,
        ) &&
            colors.orange[50]) ||
        (senderType === SenderType.WORKFLOW && colors.green[50]) ||
        (inbound && colors.blue[50]) ||
        colors.lightGrey[100],
});
