import SideBarInboxes from './SideBarInboxes';
import SideBarMutedInboxes from './SideBarMutedInboxes';
import TierUpgradeOverlay from '../../components/TierUpgradeOverlay';
import SideBarInvite from './SideBarInvite';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { WithPermission } from '../WithPermission/WithPermission';
import { ClerkPermission, Feature } from '../../api/types';
import { useMatch } from 'react-router';
import { SideBarNavigation } from './SideBarNavigation/SideBarNavigation';
import { TeamSelector } from '../../components/TeamSelector/TeamSelector';
import { useMeQueryData } from '../../queries/user';
import { MenuIcon } from '../SettingsLayout/MenuIcon';
import { colors } from '../../theme/palette';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { HideUpMd } from '../../components/MediaHelpers/HideUpMd';
import { useTrack } from '../../contexts/analytics';
import { aside, content, section, topRow } from './styles';
import { WithFeature } from '../Feature/WithFeature';
import { InboxesPopover } from './InboxesPopover';
import { HideDownMd } from '../../components/MediaHelpers/HideDownMd';
import { SidebarDrawer } from './SidebarDrawer';
import { useSidebar } from '../../contexts/sidebar';
import { ExpandIcon } from '../../icons/common/ExpandIcon';
import { CollapseIcon } from '../../icons/common/CollapseIcon';

const SideBar = () => {
    const track = useTrack();
    const isFree = useMeQueryData()?.activeTeam?.tier === 'free';
    const { isOpened, toggleOpen, isCollapsed, toggleCollapse } = useSidebar();

    return (
        <>
            <HideUpMd>
                <Stack gap={3} direction="row" sx={topRow}>
                    <TeamSelector />
                    <Box sx={{ bgcolor: colors.primary.gray.active }}>
                        <IconButton
                            sx={{
                                width: 60,
                                height: 1,
                            }}
                            onClick={() => {
                                track('sidebar_menu_toggled', {
                                    open: !open,
                                });
                                toggleOpen();
                            }}
                        >
                            {isOpened ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </Box>
                </Stack>
            </HideUpMd>
            <SidebarDrawer>
                <Box component="aside" sx={aside}>
                    <HideDownMd>
                        <Stack
                            gap={3}
                            direction="row"
                            sx={topRow}
                            pr={!isCollapsed ? 4 : 0}
                        >
                            {!isCollapsed && <TeamSelector />}
                            <Tooltip
                                title={isCollapsed ? 'Expand' : 'Collapse'}
                                placement="right"
                            >
                                <IconButton
                                    sx={{ flex: isCollapsed ? 1 : 0 }}
                                    onClick={toggleCollapse}
                                >
                                    {isCollapsed ? (
                                        <ExpandIcon />
                                    ) : (
                                        <CollapseIcon />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </HideDownMd>
                    <Box
                        sx={content}
                        className={isOpened ? 'isMenuOpen' : undefined}
                    >
                        <SideBarNavigation setMenuOpen={toggleOpen} />

                        <Box sx={section}>
                            <WithPermission
                                requiredPermissions={[
                                    ClerkPermission.AddTeamMembers,
                                ]}
                            >
                                <WithFeature feature={Feature.InviteTeammate}>
                                    <HideDownMd>
                                        <SideBarInvite />
                                    </HideDownMd>
                                </WithFeature>
                            </WithPermission>
                            {isCollapsed ? (
                                <InboxesPopover />
                            ) : (
                                <>
                                    <SideBarInboxes setMenuOpen={toggleOpen} />
                                    <SideBarMutedInboxes
                                        setMenuOpen={toggleOpen}
                                    />
                                </>
                            )}

                            <Box mt="auto">
                                {isFree && !isCollapsed && <UpgradeComponent />}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SidebarDrawer>
        </>
    );
};

const UpgradeComponent = () => {
    const isMatch = useMatch('/payment/*');
    return (
        !isMatch && (
            <Box pt={4}>
                <TierUpgradeOverlay />
            </Box>
        )
    );
};

export default SideBar;
